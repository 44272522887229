import { Component } from 'react';

const styles = {
  display: 'flex',
  '& .item + .item': {
    marginLeft: '2%',
  },
  '& .item-middle': {
    flexGrow: 1,
  },
  '& .item': {},
};

type Hstack3CellExtremityProps = {
  onRenderLeftCell?: (...args: any[]) => React.ReactNode;
  onRenderRightCell?: (...args: any[]) => React.ReactNode;
  onRenderMiddleCell?: (...args: any[]) => React.ReactNode;
  className?: string;
};

class Hstack3CellExtremity extends Component<Hstack3CellExtremityProps> {
  static defaultProps = {
    onRenderLeftCell: () => null,
    onRenderRightCell: () => null,
    onRenderMiddleCell: () => null,
    className: undefined,
  };

  render() {
    const {
      onRenderLeftCell,
      onRenderRightCell,
      onRenderMiddleCell,
      className,
    } = this.props;
    return (
      <div
        css={styles}
        className={`hstack-3-cell-extremety${className ? ` ${className}` : ''}`}
      >
        <div className="item item-left">{onRenderLeftCell()}</div>
        <div className="item item-middle">{onRenderMiddleCell()}</div>
        <div className="item item-right">{onRenderRightCell()}</div>
      </div>
    );
  }
}

export default Hstack3CellExtremity;
