import { Component, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import withLoadingContext, {
  WithLoadingContextProps,
} from '../../utils/hocs/withLoadingContext';
import withAppConfig, {
  WithAppConfigProps,
} from '../../utils/hocs/withAppConfig';
import { iffTernary } from '../../utils/utilFunctions';

type NwLinkProps = PropsWithChildren & {
  to?: string;
  pageSlug?: string;
  openInNewWindowOrTab?: boolean;
  className?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onClick?: (...args: any[]) => any;
  scrollToTop?: boolean;
};

type NwLinkInnerProps = WithLoadingContextProps &
  WithAppConfigProps &
  NwLinkProps;

class NwLink extends Component<NwLinkInnerProps> {
  static defaultProps = {
    to: null,
    pageSlug: null,
    openInNewWindowOrTab: false,
    className: null,
    onClick: () => {},
    scrollToTop: false,
  };

  constructor(props) {
    super(props);
    this._onInternalLinkClick = this._onInternalLinkClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  _onInternalLinkClick() {
    if (this.props.scrollToTop) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }
  }

  render() {
    const {
      to,
      openInNewWindowOrTab,
      onClick,
      children,
      loadingManager,
      pageSlug,
      className,
      appConfig,
      scrollToTop,
      ...other
    } = this.props;
    const baseUrl = new URL(loadingManager.getCurrentUrl());
    const gotoUrl = to || pageSlug;
    let isInternal = true;
    try {
      const toUrl = new URL(gotoUrl, baseUrl.origin);
      isInternal =
        baseUrl?.hostname === toUrl.hostname &&
        baseUrl?.protocol === toUrl.protocol;
    } catch (e) {
      if (e instanceof TypeError) {
        // to url is malformed. just ignore
      } else {
        throw e;
      }
    }

    const newWindowOrTabLink = (
      <a
        target={openInNewWindowOrTab ? '_blank' : undefined}
        href={gotoUrl}
        {...other}
        rel="noopener noreferrer"
        className={className ?? ''}
      >
        {children}
      </a>
    );

    const internalLink = (
      <Link
        className={className ?? ''}
        onClick={this._onInternalLinkClick}
        to={gotoUrl ?? ''}
        {...other}
      >
        {children}
      </Link>
    );

    const externalLinkSameTab = (
      <a className={className ?? ''} href={gotoUrl} {...other}>
        {children}
      </a>
    );

    return openInNewWindowOrTab
      ? newWindowOrTabLink
      : iffTernary(isInternal, internalLink, externalLinkSameTab);
  }
}

export default compose<NwLinkInnerProps, NwLinkProps>(
  withAppConfig,
  withLoadingContext,
)(NwLink);
