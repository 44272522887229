import * as CookieConstants from './Cookie';

export default abstract class CookieService {
  protected year: number;

  constructor() {
    this.year = 365 * 24 * 60 * 60 * 1000;
  }

  protected abstract getCookie(cookieName: string): string;

  protected abstract setCookie(
    cookieName: string,
    value: string | boolean,
    samesite?: string,
    secure?: boolean,
  ): void;

  getLanguage(): string {
    return this.getCookie(CookieConstants.LANGUAGE);
  }

  getConsentCookie(): string {
    let consentCookie = this.getCookie(CookieConstants.COOKIE_CONSENT);
    if (!consentCookie) {
      consentCookie = this.getCookie(CookieConstants.COOKIE_CONSENT_LEGACY);
    }
    return consentCookie;
  }

  getEnvironment(): string {
    return this.getCookie(CookieConstants.ENVIRONMENT);
  }

  getPreview(): boolean {
    const isPreview = this.getCookie(CookieConstants.PREVIEW);
    return typeof isPreview === 'string'
      ? (isPreview || '').toLowerCase() === 'true'
      : isPreview;
  }

  setLanguage(language: string): void {
    this.setCookie(CookieConstants.LANGUAGE, language, 'Lax', true);
  }

  setEnvironment(environment: string): void {
    this.setCookie(CookieConstants.ENVIRONMENT, environment, 'Lax');
  }

  setPreview(isPreview: string | boolean) {
    this.setCookie(CookieConstants.PREVIEW, isPreview, 'Lax');
  }

  setConsentCookie(consentString: string): void {
    this.setCookie(CookieConstants.COOKIE_CONSENT, consentString, 'Lax', true);
  }
}
