import { Component } from 'react';
import { css } from '@emotion/react';

import ContentSpacer from './common/atoms/ContentSpacer';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ProductCardGridMolecule from './common/molecules/ProductCardGrid';

import * as InternalPropTypes from '../constants/internal-types';

const styles = css({
  '& .image': {
    margin: 'auto',
    objectFit: 'cover',
  },
  '& .product-card-grid-component': {
    margin: 'auto',
  },
});

type ProductCardGridProps = {
  items?: InternalPropTypes.MediaItem[];
};

class ProductCardGrid extends Component<ProductCardGridProps> {
  static defaultProps = {
    items: [],
  };

  render() {
    const { items } = this.props;
    return (
      <div className="product-card-grid-component" css={styles}>
        <ContentOuterBound>
          <ContentSpacer>
            <ProductCardGridMolecule items={items} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ProductCardGrid;
