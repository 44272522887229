import { Component } from 'react';
import { css } from '@emotion/react';

import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import BodyText from '../atoms/BodyText';
import Vstack3CellExtremity from '../atoms/Vstack3CellExtremity';
import Image from '../atoms/Image';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const style = css({
  '& .image': {
    width: '100%',
    objectFit: 'cover',
  },
  '& .item + .item.item-middle': {
    marginLeft: 0,
  },
  [Breakpoints.TabletDown.mq]: {
    '& .hstack-3-cell-extremety': {
      flexDirection: 'column',
      '& .item + .item': {
        marginLeft: 0,
      },
    },
    '& .vstack-3-cell-extremety': {
      flexDirection: 'row',
      '& .item + .item': {
        marginTop: '4%',
      },
    },
  },
  '& .item-center .body-text': {
    fontSize: '28px',
    lineHeight: '28px',
    [Breakpoints.TabletDown.mq]: {
      fontSize: '24px',
      lineHeight: '24px',
    },
  },
  '& .vstack-3-cell-extremety .item:last-of-type': {
    fontSize: '16px',
  },
});

type FindUsMoleculeProps = {
  image?: InternalPropTypes.Image;
  locationText?: InternalPropTypes.RichText;
  addressText?: InternalPropTypes.RichText;
};

class FindUsMolecule extends Component<FindUsMoleculeProps> {
  static defaultProps = {
    image: null,
    locationText: '',
    addressText: '',
  };

  render() {
    const { image, locationText, addressText } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743' },
      [ScreenSize.Tablet]: { w: '903' },
      [ScreenSize.Desktop]: { w: '816' },
    };

    return (
      <div css={style} className="FindUs">
        <Hstack3CellExtremity
          onRenderMiddleCell={() =>
            image && <Image image={image} sizes={sizes} />
          }
          onRenderRightCell={() => (
            <Vstack3CellExtremity
              onRenderMiddleCell={() => (
                <BodyText as="h3" type={BodyText.types.BOLD16}>
                  {locationText}
                </BodyText>
              )}
              onRenderBottomCell={() => (
                <BodyText type={BodyText.types.LIGHT12}>{addressText}</BodyText>
              )}
            />
          )}
        />
      </div>
    );
  }
}

export default FindUsMolecule;
