import { Component } from 'react';
import { colorEnum } from '../../../constants/colors';

const style = {
  backgroundColor: colorEnum.lightBlue,
  color: colorEnum.white,
  padding: '15px 25px',
  textDecoration: 'none',
  borderRadius: '45px',
  border: 'none',
  boxShadow: `0px 8px 15px ${colorEnum.shadow10}`,
  transition: 'all 0.3s ease 0s',
  cursor: 'pointer',
  outline: 'none',
  '&:hover': {
    textDecoration: 'none',
    color: colorEnum.white,
    backgroundColor: colorEnum.blue,
    boxShadow: `0px 12px 16px ${colorEnum.blueShadow}`,
  },
};

type LinkButtonProps = {
  openInNewWindowOrTab?: boolean;
  url?: string;
  className?: string;
  children?: React.ReactNode;
};

class LinkButton extends Component<LinkButtonProps> {
  static defaultProps = {
    openInNewWindowOrTab: false,
    url: '',
    className: null,
    children: null,
  };

  render() {
    const { openInNewWindowOrTab, url, children, className } = this.props;

    return (
      <a
        className={`link-button${className ? ` ${className}` : ''}`}
        css={style}
        target={openInNewWindowOrTab ? '_blank' : undefined}
        href={url}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
}

export default LinkButton;
