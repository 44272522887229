import { Component } from 'react';
import { css } from '@emotion/react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Header from '../atoms/Header';
import MediaQuery from '../atoms/MediaQuery';

import TwoPassRender from '../../../utils/hocs/twoPassRender';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { defaultColors, colorEnum } from '../../../constants/colors';
import font from '../../../constants/fonts';

const useStyles = css({
  '& .vertical-timeline': {
    height: '235px',
    padding: '0px',
    marginLeft: '0px',
  },
  '& .vertical-timeline::before': {
    width: '2px',
  },
  '& .timeline-progress': {
    width: '2px',
    transition: 'height 0.3s ease',
    position: 'absolute',
    left: '18px',
    backgroundColor: defaultColors.lineBreak,
    background: colorEnum.black,
  },
  '& .vertical-timeline-element': {
    marginTop: '0',
    '& .step-content': {
      '& .toggle': {
        '&.inactive': {
          left: '-45px',
          top: '0',
          opacity: '1',
        },
        '&.active': {
          left: '-53px',
          top: '-8px',
        },
      },
    },
    '& .text': {
      position: 'absolute',
      width: 'max-content',
      marginLeft: '-35px',
      top: '-12px',
      opacity: '0.24',
      '&.active': {
        ...font.GNMedium,
        opacity: '1',
        transition: 'all 0.5s ease',
      },
    },
    '& .btn': {
      padding: '0',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& p': {
      margin: '0',
    },
    '.vertical-timeline-element-icon': {
      visibility: 'hidden',
    },
    '.vertical-timeline-element-content.bounce': {
      backgroundColor: 'inherit',
    },
    '.vertical-timeline-element-content-arrow': {
      visibility: 'hidden',
    },
    '.vertical-timeline-element-content': {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      height: '0px',
      paddingTop: '5px',
    },
  },
  '& .tablet-up-progress': {
    position: 'relative',
    ':before, :after': {
      content: '""',
      width: '0',
      height: '2px',
      position: 'absolute',
      top: '0',
      backgroundColor: defaultColors.lineBreak,
    },
    ':before': {
      [Breakpoints.Tablet.mq]: {
        width: '60px',
      },
      [Breakpoints.DesktopUp.mq]: {
        width: '100px',
      },
    },
    '& .RSPBprogressBar': {
      height: '2px',
      backgroundColor: defaultColors.lineBreak,
      '& .RSPBstep': {
        width: '0',
      },
    },
    '& .RSPBprogressBar .RSPBprogression': {
      transition: 'width 0.3s ease',
    },
    '&.finished': {
      ':after': {
        transition: 'width 6s linear',
        [Breakpoints.Tablet.mq]: {
          width: '60px',
        },
        [Breakpoints.DesktopUp.mq]: {
          width: '100px',
        },
        backgroundColor: colorEnum.black,
      },
    },
  },
  '& .step-content': {
    '&:focus': {
      boxShadow: 'none',
    },
    '&.first': {
      ':before': {
        content: '""',
        height: '2px',
        position: 'absolute',
        top: '6px',
        backgroundColor: colorEnum.black,
      },
    },
    '& .toggle': {
      height: '24px',
      position: 'absolute',
      textAlign: 'center',
      lineHeight: '1.9',
      color: colorEnum.white,
      borderRadius: '100%',
      cursor: 'pointer',
      transition: '0.5s ease',
      '&:focus': {
        outline: 'none',
      },
      '&.active': {
        top: '-6px',
        left: '-12px',
        width: '24px',
        fontSize: '12px',
        backgroundColor: colorEnum.black,
      },
      '&.inactive': {
        top: '3px',
        left: '-4px',
        width: '8px',
        height: '8px',
        backgroundColor: colorEnum.gray,
        '&.passed': {
          backgroundColor: colorEnum.black,
        },
      },
    },
    '& .text': {
      top: '24px',
      left: '-4px',
      position: 'absolute',
      width: 'max-content',
      opacity: '0.24',
      '&.active': {
        ...font.GNMedium,
        opacity: '1',
        transition: 'all 0.5s ease',
      },
    },
  },
});

type ProgressProps = {
  items?: {
    header: string;
    headerText?: InternalPropTypes.RichText;
  }[];
  percent?: number;
  play?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changePlayState?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changePercent?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeFocus?: (...args: any[]) => any;
};

class Progress extends Component<ProgressProps> {
  static defaultProps = {
    items: [],
    percent: 0,
    play: true,
    changePlayState: () => {},
    changePercent: () => {},
    changeFocus: () => {},
  };

  componentDidUpdate() {
    const positions = this.getStepPositions();
    const focusChange = this.checkFocusChange(positions);
    if (focusChange) {
      this.props.changeFocus(focusChange);
    }
  }

  getStepPositions() {
    const { items } = this.props;
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    ctx.font = '16px Gotham Narrow';
    let spaceUsed = 0;
    const spaces = [];
    const positions = [];
    const dynamicPositions = [];

    items.forEach((item) => {
      const space = Math.ceil(ctx.measureText(item.header).width);
      spaces.push(space);
      spaceUsed += space;
    });

    const width =
      window.innerWidth < Breakpoints.Desktop.narrowestWidthInPx
        ? Breakpoints.Tablet.narrowestWidthInPx - 120
        : Breakpoints.Desktop.narrowestWidthInPx - 200;
    const spaceBetween = (width - spaceUsed) / items.length;
    const space = 100 / (items.length - 1);
    let position = 0;
    let dynamicPosition = 0;
    let i = 0;
    while (i < items.length) {
      positions.push(position);
      position += space;
      dynamicPositions.push(dynamicPosition);
      dynamicPosition += Math.ceil(((spaces[i] + spaceBetween) / width) * 100);
      i += 1;
    }
    positions[positions.length - 1] = 100;
    return [positions, dynamicPositions];
  }

  checkFocusChange(positions) {
    const { percent } = this.props;
    let value = null;
    const isMobile = window.innerWidth <= Breakpoints.Mobile.widestWidthInPx;
    if (isMobile) {
      value = positions[0].includes(percent)
        ? positions[0].indexOf(percent)
        : value;
    } else {
      value = positions[1].includes(percent)
        ? positions[1].indexOf(percent)
        : value;
    }
    return value;
  }

  isActivePosition(positions, index) {
    const { percent } = this.props;
    return !!(
      (positions[index] <= percent && percent < positions[index + 1]) ||
      (index === positions.length - 1 &&
        percent >= positions[positions.length - 1])
    );
  }

  render() {
    const { items, play, percent, changePlayState, changePercent } = this.props;
    const positions = this.getStepPositions();
    const stepPositions = positions[1];
    const mobileStepPositions = positions[0];
    const playSymbol = play ? <p>&#10073;&#10073;</p> : <p>&#10148;</p>;
    return (
      <div css={useStyles}>
        <MediaQuery visible={Breakpoints.Mobile}>
          <VerticalTimeline animate={false} lineColor="lightgrey">
            <div
              className="timeline-progress"
              style={{ height: `${percent}%` }}
            />
            {items.map((item, index) => (
              <VerticalTimelineElement
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                progress={
                  index === items.length - 1
                    ? 100
                    : (index / (items.length - 1)) * 100
                }
              >
                <button
                  type="button"
                  onClick={
                    this.isActivePosition(mobileStepPositions, index)
                      ? changePlayState
                      : () => changePercent(mobileStepPositions[index])
                  }
                  className="step-content btn"
                >
                  {this.isActivePosition(mobileStepPositions, index) ? (
                    <div className="toggle active">{playSymbol}</div>
                  ) : (
                    <div
                      className={`toggle inactive ${
                        mobileStepPositions[index] < percent ? 'passed' : ''
                      }`}
                    />
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => changePercent(mobileStepPositions[index])}
                  className="btn"
                >
                  <Header
                    type={Header.types.TEXTBOXH4}
                    className={`text ${
                      this.isActivePosition(mobileStepPositions, index)
                        ? 'active'
                        : ''
                    }`}
                  >
                    {item.header}
                  </Header>
                </button>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </MediaQuery>
        <MediaQuery
          visible={Breakpoints.TabletUp}
          className={`tablet-up-progress ${percent === 100 ? 'finished' : ''}`}
        >
          <ProgressBar
            percent={percent}
            filledBackground={colorEnum.black}
            stepPositions={stepPositions}
          >
            {items.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Step className="step" key={idx}>
                {({ index }) => (
                  <button
                    type="button"
                    onClick={
                      this.isActivePosition(stepPositions, index)
                        ? changePlayState
                        : () => changePercent(stepPositions[index])
                    }
                    className={`step-content ${index === 0 ? 'first' : ''} btn`}
                  >
                    {this.isActivePosition(stepPositions, index) ? (
                      <div className="toggle active">{playSymbol}</div>
                    ) : (
                      <div
                        className={`toggle inactive ${
                          stepPositions[index] < percent ? 'passed' : ''
                        }`}
                      />
                    )}
                    <Header
                      type={Header.types.TEXTBOXH4}
                      className={`text ${
                        this.isActivePosition(stepPositions, index)
                          ? 'active'
                          : ''
                      }`}
                    >
                      {item.header}
                    </Header>
                  </button>
                )}
              </Step>
            ))}
          </ProgressBar>
        </MediaQuery>
      </div>
    );
  }
}

export default TwoPassRender(Progress);
