import { Component } from 'react';

import * as Breakpoints from '../../../constants/breakpoints';

const buildStyleObj = (breakpoints, style) =>
  breakpoints.reduce((acc, bp) => {
    acc[bp.mq] = style;
    return acc;
  }, {});

const baseStyles = buildStyleObj(Breakpoints.ExclusiveBreakpoints, {
  display: 'none',
});

type MediaQueryProps = {
  visible: Breakpoints.BreakpointType | Breakpoints.BreakpointType[];
  className?: string;
  children?: React.ReactNode;
};

class MediaQuery extends Component<MediaQueryProps> {
  static defaultProps = {
    className: null,
    children: null,
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { children, visible, className, ...otherProps } = this.props;
    const visibleBreakpoints = Array.isArray(visible)
      ? visible
      : [visible].filter((bp) => !!bp);
    const styles = buildStyleObj(visibleBreakpoints, { display: 'block' });

    return (
      <div
        {...otherProps}
        css={[baseStyles, styles]}
        className={`media-query ${className || ''}`}
      >
        {children}
      </div>
    );
  }
}
export default MediaQuery;
