import React from 'react';

import { withContext } from './withContext';
import AppConfigContext from '../../contexts/AppConfigContext';
import { AppConfigType } from '../../client-server-utils/appConfig';

export default withContext(AppConfigContext, 'appConfig');

export const useAppConfigContext = () => React.useContext(AppConfigContext);

export type WithAppConfigProps<P = unknown> = P & {
  appConfig: AppConfigType;
};
