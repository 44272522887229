import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Well from './common/molecules/Well';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  PositionEnum,
  TypeWellTypes,
  TypePositionTypes,
  TypeBackgroundColorTypes,
} from '../constants/cms-constants';

const styles = {
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
};

type WellFullProps = {
  image?: InternalPropTypes.Image;
  backgroundColor?: TypeBackgroundColorTypes;
  link?: InternalPropTypes.Link;
  type?: TypeWellTypes;
  position?: TypePositionTypes;
};

class WellFull extends Component<WellFullProps> {
  static types = Well.types;

  static defaultProps = {
    image: null,
    backgroundColor: null,
    link: null,
    type: this.types.BIG_SCALE_DOWN,
    position: PositionEnum.CENTER,
  };

  render() {
    const { image, backgroundColor, type, link, position } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="well-full-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Well image={image} type={type} link={link} position={position} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default WellFull;
