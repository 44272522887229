export default function getRows(items, cols = 2) {
  const array = [[]];
  items.map((item) =>
    array[array.length - 1].length !== cols
      ? array[array.length - 1].push(item)
      : array.push([item]),
  );
  return array;
}

// Function to avoid nested ternaries, by evaluating a ternary expression
export const iffTernary = (condition, then, otherwise) =>
  condition ? then : otherwise;
