const contentTypes = require('./content-types');

const BlockContentTypes = {
  SNIPPET: contentTypes.SNIPPET,
  IMAGE: contentTypes.IMAGE,
  VIDEO: contentTypes.VIDEO,
  PERSON: contentTypes.PERSON,
  MEDIAITEM: contentTypes.MEDIAITEM,
  LINK: contentTypes.LINK,
  EVENT: contentTypes.EVENT,
  MAILCONTENT: contentTypes.MAILCONTENT,
};

module.exports = BlockContentTypes;
