import { BlockInstanceProps } from '../Block';
import CookieAccordionComponent from '../../components/CookieAccordionComponent';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

function filterNodeType(bodyText, targetNodeType) {
  const textData = JSON.parse(JSON.stringify(bodyText));
  textData.content = bodyText.content.filter(
    (elm) => elm.nodeType === targetNodeType,
  );
  return textData;
}

function createTableFromBody(bodyText): object[] {
  const table = filterNodeType(bodyText, 'table')?.content?.[0];

  if (!table) {
    return [];
  }

  const headers = table.content[0].content.map(
    (headerCell) => headerCell.content[0].content[0].value,
  );

  return table.content.slice(1).map((row) => {
    const values = row.content.map((cell) => cell.content[0].content[0].value);
    return headers.reduce((obj, header, idx) => {
      // eslint-disable-next-line no-param-reassign
      obj[header] = values[idx];
      return obj;
    }, {});
  });
}

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CookieAccordion extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;

    const header = block?.header?.main;
    const body = filterNodeType(block?.bodyText, 'paragraph');
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const table = createTableFromBody(block?.bodyText);

    return (
      <CookieAccordionComponent
        header={header}
        body={body}
        backgroundColor={backgroundColor}
        tableContents={table}
      />
    );
  }
}

export default CookieAccordion;
