/* 

Breakpoints for the solution are arranged as follows, in relation to the styleguide

0 <= size < 320px 
	- Unsupported with undefined behavior
320px <= size < 413px
	- Mobile
414px <= size < 767px
	- Mobile
768px <= size < 1023px
	- Tablet
1024px <= size < 1920
	- Desktop with scaling relative to screen size
1920 <= size < infinite
	- Desktop but limited to 1920 scaling

Breakpoints Mobile, Tablet and Desktop are exclusive (no overlap). 
The remaining breakpoints are compositions or negations of these and only exist for brevity

*/

export type BreakpointType = {
  narrowestWidthInPx: number;
  widestWidthInPx: number;
  mq: string;
  rawMq?: string;
};

type ScreenRange = {
  narrowestWidthInPx?: number;
  widestWidthInPx?: number;
};

const mobileNarrowestWidthInPx = 0;
const tabletNarrowestWidthInPx = 768;
const desktopNarrowestWidthInPx = 1024;
const desktopBoundedNarrowestWidthInPx = 1200;
const mobileWidestWidthInPx = tabletNarrowestWidthInPx - 1;
const tabletWidestWidthInPx = desktopNarrowestWidthInPx - 1;
const desktopWidestWidthInPx = desktopBoundedNarrowestWidthInPx - 1;
const desktopBoundedWidestWidthInPx = Infinity;

const createBreakPoint = ({
  narrowestWidthInPx,
  widestWidthInPx,
}: ScreenRange): BreakpointType => {
  const mqIndicator = '@media';
  const screen = 'only screen';

  const mqMinWidth = `(min-width : ${narrowestWidthInPx ?? 0}px)`;

  const mqMaxWidth =
    widestWidthInPx && widestWidthInPx !== Infinity
      ? `(max-width : ${widestWidthInPx}px)`
      : undefined;

  const rawMq = [screen, mqMinWidth, mqMaxWidth].filter(Boolean).join(' and ');

  return {
    narrowestWidthInPx: narrowestWidthInPx ?? 0,
    widestWidthInPx: widestWidthInPx ?? Infinity,
    rawMq,
    mq: [mqIndicator, rawMq].join(' '),
  };
};

export const Mobile: BreakpointType = createBreakPoint({
  narrowestWidthInPx: mobileNarrowestWidthInPx,
  widestWidthInPx: mobileWidestWidthInPx,
});

export const Tablet: BreakpointType = createBreakPoint({
  narrowestWidthInPx: tabletNarrowestWidthInPx,
  widestWidthInPx: tabletWidestWidthInPx,
});

export const Desktop: BreakpointType = createBreakPoint({
  narrowestWidthInPx: desktopNarrowestWidthInPx,
  widestWidthInPx: desktopWidestWidthInPx,
});

export const DesktopBounded: BreakpointType = createBreakPoint({
  narrowestWidthInPx: desktopBoundedNarrowestWidthInPx,
  widestWidthInPx: desktopBoundedWidestWidthInPx,
});

export const ExclusiveBreakpoints = [Mobile, Tablet, Desktop, DesktopBounded];

export const MobileDown: BreakpointType = createBreakPoint({
  widestWidthInPx: mobileWidestWidthInPx,
});

export const MobileUp: BreakpointType = createBreakPoint({
  narrowestWidthInPx: mobileNarrowestWidthInPx,
});

export const TabletDown: BreakpointType = createBreakPoint({
  widestWidthInPx: tabletWidestWidthInPx,
});

export const TabletUp: BreakpointType = createBreakPoint({
  narrowestWidthInPx: tabletNarrowestWidthInPx,
});

export const DesktopUp: BreakpointType = createBreakPoint({
  narrowestWidthInPx: desktopNarrowestWidthInPx,
});

export const NotTablet = `@media ${MobileDown.rawMq}, ${DesktopUp.rawMq}`;
