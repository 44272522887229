import { Component } from 'react';
import * as InternalPropTypes from '../constants/internal-types';
import PageFooter from '../components/PageFooter';

type FooterProps = {
  root?: InternalPropTypes.Root;
  onClick?: () => void;
};

class Footer extends Component<FooterProps> {
  static defaultProps = {
    root: null,
    onClick: null,
  };

  render() {
    const { root, onClick } = this.props;

    return (
      <PageFooter
        footer={root.footer}
        onClick={onClick}
        companyNameWhiteImage={root.images.name.white}
      />
    );
  }
}

export default Footer;
