import { LANGUAGES } from '../constants/language';

export default {
  name: 'DomainLanguageDetector',

  lookup(req) {
    const hostName =
      typeof window === 'undefined'
        ? req.header('host')
        : window.location.hostname;
    const matches = hostName.match(/^.*\.([a-z]*)$/);
    if (matches?.length > 1) {
      const lang = LANGUAGES.find((l) => l.defaultDomain === `.${matches[1]}`);
      return lang ? lang.iso : null;
    }
    return null;
  },
};
