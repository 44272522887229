import { Heuristics, HeuristicsResponseType } from './Heuristics';
import {
  STATUS_ERROR,
  STATUS_OKAY,
  STATUS_WARNING,
} from '../../constants/seoStatus';
import 'core-js/es/promise';
import { Root, Page } from '../../constants/internal-types';

export default class PageDescriptionHeuristic extends Heuristics {
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let result: Array<HeuristicsResponseType> = [];
      if (page && root) {
        const description = page.seo?.description;
        const response: HeuristicsResponseType = {
          name: 'Page SEO description',
          generalText: this.getGeneralText(),
          title: this.getDescriptionOfPage(description),
          path: this.getFixPathText(page),
        } as HeuristicsResponseType;

        if (!description) {
          response.status = STATUS_ERROR;
          response.error = 'This page does not have a SEO description';
        } else if (!this.isUpperCase(description[0])) {
          response.status = STATUS_WARNING;
          response.error =
            'This page has a SEO description that is not capitalized or starts with a number';
        } else if (description.length > 160) {
          response.status = STATUS_WARNING;
          response.error =
            'This page has a longer SEO description than recommended';
        } else {
          response.status = STATUS_OKAY;
        }
        result = [response];
      }
      resolve(result);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        The SEO description is the description of the page displayed alongside
        the title and URL when seen from a search engine. For anyone trying to
        increase clicks and polish the SEO score of their brand this is vital
        part of a website, as this occupies the by far largest part of the
        search snippet and invites the searchers to click and explore the
        domain. Because of this the description must be realistic, inviting and
        distinctly reflect the content of exactly that page. If the description
        contains the keywords used by a searcher these will appear in bold and
        help the page stand out. Best practises for a seo description are:
        <ul>
          <li>
            {' '}
            Give each page a unique description that clearly reflects what value
            the page carries.
          </li>
          <li>
            {' '}
            Google snippets usually max out at between 150 and 160 characters,
            aim for a similar length. Note that we use this text for twitter
            cards, where it is truncated at 200 characters.
          </li>
          <li>
            {' '}
            Include the most significant keywords so they can get highlighted on
            the search, but be careful to avoid keyword stuffing.
          </li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getDescriptionOfPage(description: string | null | undefined) {
    if (description) {
      return `This page has the SEO description "${description}".`;
    }
    return 'This page has no SEO description.';
  }

  // eslint-disable-next-line class-methods-use-this
  getFixPathText(mappedData) {
    return `To fix this in Contentful go to "${this.getFixPath(
      mappedData,
    )}" and edit this field.`;
  }

  // eslint-disable-next-line class-methods-use-this
  getFixPath(mappedData) {
    return `Pages/${mappedData.name}/PageDescription(seo)`;
  }
}
