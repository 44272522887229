import { Component } from 'react';
import parse from 'html-react-parser';

type HtmlParserProps = {
  content: string;
};

class HtmlParser extends Component<HtmlParserProps> {
  render() {
    const { content } = this.props;

    return parse(content);
  }
}

export default HtmlParser;
