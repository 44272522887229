import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import CaseHeaderMolecule from './common/molecules/CaseHeader';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '128px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '104px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '80px',
  },
};

type CaseHeaderProps = {
  headerText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
  backgroundColor?: TypeBackgroundColorTypes;
};

class CaseHeader extends Component<CaseHeaderProps> {
  static defaultProps = {
    headerText: null,
    lightHeaderText: null,
    backgroundColor: null,
  };

  render() {
    const { headerText, lightHeaderText, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="case-header-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <CaseHeaderMolecule
              headerText={headerText}
              lightHeaderText={lightHeaderText}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default CaseHeader;
