import { Component } from 'react';

import { defaultColors } from '../../../constants/colors';

const styles = {
  height: '1px',
  color: defaultColors.lineBreak,
};

class HorizontalLine extends Component {
  render() {
    return <hr css={styles} />;
  }
}

export default HorizontalLine;
