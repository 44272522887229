import React, { Component, Fragment } from 'react';
import { css } from '@emotion/react';
import BodyText from '../atoms/BodyText';
import Image from '../atoms/Image';
import LinkButton from '../atoms/LinkButton';
import Title from '../atoms/Title';
import ContentOuterBound from '../atoms/ContentOuterBound';

import { colorEnum } from '../../../constants/colors';
import font from '../../../constants/fonts';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';
import { ImgixParams } from '../../../types/ts/imgixQuery';

const styles = css({
  height: '100%',
  position: 'relative',
  '& .content-outer-bound': {
    background: colorEnum.lightGray,
    borderRadius: '10px',
    height: '100%',
    padding: '15px',
    paddingBottom: '60px',
    textAlign: 'center',
  },
  '& .lead': {
    ...font.GNBold,
    marginBottom: '25px',
  },
  '& .image': {
    height: '100px',
    marginBottom: '25px',
    maxWidth: '200px',
    width: '100%',
  },
  '& .link-button': {
    backgroundColor: colorEnum.white,
    borderColor: colorEnum.darkGrey,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colorEnum.black,
    padding: '7px 30px',
    position: 'absolute',
    bottom: '15px',
    transform: 'translateX(-50%)',
    minWidth: '175px',
    '& span': {
      fontWeight: '500',
    },
  },
});

type ProductCardProps = {
  image?: InternalPropTypes.Image;
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
};

class ProductCard extends Component<ProductCardProps> {
  static defaultProps = {
    image: null,
    header: null,
    lightHeader: null,
    link: null,
  };

  render() {
    const { image, header, lightHeader, link } = this.props;

    const sizes: ImgixParams = { w: '200', h: '100' };

    return (
      <div css={styles}>
        <ContentOuterBound>
          <>
            <Image image={image} sizes={sizes} />
            <Title type={TitleTypes.LEAD}>{header}</Title>
            {lightHeader ? (
              <>
                <hr />
                <BodyText className="text">{lightHeader}</BodyText>
              </>
            ) : (
              Fragment
            )}
            {link?.text ? (
              <>
                <hr />
                <LinkButton
                  url={link.linkTo}
                  openInNewWindowOrTab={link.openInNewWindowOrTab}
                >
                  <BodyText as="span" type={BodyText.types.BOLD16}>
                    {link.text}
                  </BodyText>
                </LinkButton>
              </>
            ) : (
              Fragment
            )}
          </>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ProductCard;
