import { Component } from 'react';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { css } from '@emotion/react';

import Header from '../atoms/Header';
import chevronarrow from '../../../assets/chevron-arrow.png';
import * as InternalPropTypes from '../../../constants/internal-types';

const useStyles = css({
  '.accordion-toggle': {
    display: 'flex',
  },
  '.img-container': {
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  '& .accordion-indicator': {
    transform: 'rotate(90deg)',
    float: 'right',
    willChange: 'transform',
    transition: 'transform 200ms linear',
  },
  '& .Down': {
    transform: 'rotate(-90deg)',
  },
  '& .Up': {
    transform: 'rotate(90deg)',
  },
  '& .indicator': {
    WebkitTransform: 'rotate(180deg)',
    transform: 'rotate(180deg)',
    WebkitBackfaceVisibility: 'hidden',
  },
  '.accordion-bodytext': {
    paddingBottom: '20px',
  },
});

type AccordionProps = {
  header?: InternalPropTypes.RichText;
  children?: React.ReactNode;
};

interface IAccordionState {
  isOpen: boolean;
}

class Accordion extends Component<AccordionProps, IAccordionState> {
  static defaultProps = {
    header: 'Header',
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { header, children } = this.props;
    const { isOpen } = this.state;
    return (
      <BootstrapAccordion css={useStyles}>
        <Card key="eventKey">
          <BootstrapAccordion.Toggle
            as={Card.Header}
            eventKey="eventKey"
            className="accordion-toggle"
            onClick={() => this.handleToggle()}
          >
            <Header type={Header.types.TEXTBOXH2}>{header}</Header>
            <div className="img-container">
              <img
                src={chevronarrow}
                alt="Arrow indicating whether accordion is open or not"
                width="18"
                height="18"
                className={`accordion-indicator${isOpen ? ' Down' : ' Up'}`}
              />
            </div>
          </BootstrapAccordion.Toggle>
          <BootstrapAccordion.Collapse eventKey="eventKey">
            <Card.Body>{children}</Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </BootstrapAccordion>
    );
  }
}

export default Accordion;
