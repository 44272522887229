import React, { Component } from 'react';
import { css } from '@emotion/react';

import Title from '../atoms/Title';
import Caption from '../atoms/Caption';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import BodyText from '../atoms/BodyText';
import CallToAction from './CallToAction';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import {
  TypeSEOWeightTypes,
  BodyTextWidthEnum,
  TypeBodyTextWidthTypes,
} from '../../../constants/cms-constants';
import { defaultColors } from '../../../constants/colors';
import TitleTypes from '../../../constants/title-types';
import getSEOWeight from '../../../utils/SEOWeight';

const styles = css({
  // light header
  '& .caption.section': {
    color: defaultColors.lightHeader,
    marginBottom: '15px',
  },
  // header
  '& .short-header': {
    width: '214px',
  },
  // default grid control
  '& .inner-grid': {
    '& .body-text': {
      [Breakpoints.Mobile.mq]: {
        marginTop: '20px',
        marginRight: '19.47%',
      },
      [Breakpoints.Tablet.mq]: {
        paddingTop: '20px',
      },
    },
    '& .link-container': {
      marginTop: '30px',
    },
    '& .right-cell': {
      [Breakpoints.Tablet.mq]: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
      },
    },
  },
  // long variant
  '& .long': {
    [Breakpoints.Tablet.mq]: {
      paddingRight: '20%',
      '& .body-text': {
        marginTop: '20px',
      },
    },
    '& .long-body-text': {
      [Breakpoints.Mobile.mq]: {
        marginTop: '16px',
      },
    },
  },
});

type SectionTopProps = {
  lightHeaderText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;

  bodyTextWidth?: TypeBodyTextWidthTypes;
  seoWeight?: TypeSEOWeightTypes;
};

class SectionTop extends Component<SectionTopProps> {
  static defaultProps = {
    lightHeaderText: null,
    headerText: null,
    bodyText: null,
    link: null,
    bodyTextWidth: null,
    seoWeight: 'h2',
  };

  render() {
    const {
      lightHeaderText,
      headerText,
      bodyText,
      link,
      bodyTextWidth,
      seoWeight,
    } = this.props;
    const alwaysBreakInner = true;
    const alwaysBreakOuter = false;

    return (
      <div className="sectiontop" css={styles}>
        {lightHeaderText && (
          <Caption type={Caption.types.SECTION}>{lightHeaderText}</Caption>
        )}
        {!bodyText && (
          <>
            <Title
              seoWeight={getSEOWeight(seoWeight, 'h2')}
              type={TitleTypes.SECTION}
            >
              {headerText}
            </Title>
            {link && (
              <div className="link-container">
                <CallToAction link={link} />
              </div>
            )}
          </>
        )}
        {bodyText && (
          <>
            {bodyTextWidth === BodyTextWidthEnum.SHORT && (
              <Grid2CellUnevenSplit
                leftCellCount={5}
                rightCellCount={5}
                alwaysBreak={alwaysBreakOuter}
                className="outer-grid"
                onRenderCell1={() => (
                  <Title
                    seoWeight={getSEOWeight(seoWeight, 'h2')}
                    type={TitleTypes.SECTION}
                    className={`${!link && !bodyText && 'short-header'}`}
                  >
                    {headerText}
                  </Title>
                )}
                onRenderCell2={() => (
                  <Grid2CellUnevenSplit
                    leftCellCount={5}
                    rightCellCount={5}
                    alwaysBreak={alwaysBreakInner}
                    className="inner-grid"
                    onRenderCell1={() =>
                      bodyText && (
                        <BodyText
                          className="body-text"
                          type={BodyText.types.STATIC16}
                        >
                          {bodyText}
                        </BodyText>
                      )
                    }
                    onRenderCell2={() =>
                      link && (
                        <div className="link-container">
                          <CallToAction link={link} />
                        </div>
                      )
                    }
                  />
                )}
              />
            )}
            {bodyTextWidth === BodyTextWidthEnum.LONG && (
              <Grid2CellUnevenSplit
                leftCellCount={4}
                rightCellCount={7}
                alwaysBreak={alwaysBreakInner}
                snapAtBreakpoint={Breakpoints.TabletDown}
                className="long"
                onRenderCell1={() => (
                  <Title
                    seoWeight={getSEOWeight(seoWeight, 'h2')}
                    type={TitleTypes.SECTION}
                  >
                    {headerText}
                  </Title>
                )}
                onRenderCell2={() => (
                  <>
                    {bodyText && (
                      <BodyText
                        className="long-body-text"
                        type={BodyText.types.STATIC16}
                      >
                        {bodyText}
                      </BodyText>
                    )}
                    {link && (
                      <div className="long-link-container">
                        <CallToAction link={link} />
                      </div>
                    )}
                  </>
                )}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default SectionTop;
