import { BlockInstanceProps } from '../Block';
import ImageGrid3Cells from '../../components/ImageGrid3Cells';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ImageGrid3CellsBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    let images;
    blockContent.doQuery((content) => {
      images = content.getFixedAmountOfType(ContentTypeEnum.IMAGE, 3);
    });

    return (
      <ImageGrid3Cells images={images} backgroundColor={backgroundColor} />
    );
  }
}

export default ImageGrid3CellsBlock;
