import Driver from './Driver';
import {
  IDepdendencyInput,
  IEventInput,
  IErrorInput,
  IRequestInput,
  IPageViewInput,
} from '../../constants/reporterServiceInputs';
import { ConfigBase, ConfigVariable } from '../StaticConfig';

export default class ConsoleLoggerDriver extends Driver {
  private isEnabled: boolean;

  constructor(staticConfig: ConfigBase) {
    super();
    this.isEnabled = staticConfig.get(ConfigVariable.EditorToolsEnabled);
  }

  // eslint-disable-next-line class-methods-use-this
  activate(): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeaderString(nonce: string): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getBodyString(): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  logError(error: IErrorInput): void {
    if (this.isEnabled) console.log(error);
  }

  trackEvent(event: IEventInput): void {
    if (this.isEnabled) console.log(event);
  }

  trackRequest(request: IRequestInput): void {
    if (this.isEnabled) console.log(request);
  }

  trackDependency(dependencyInput: IDepdendencyInput): void {
    if (this.isEnabled) console.log(dependencyInput);
  }

  trackPageView(pageView: IPageViewInput): void {
    if (this.isEnabled) console.log(pageView);
  }
}
