export const filterEmail = (email) => {
  const emailRegex = /(\w+@\w+\.(?:com|dk))/g;

  return email?.length ? email.match(emailRegex)[0] : '';
};

export const filterPhone = (phone) => {
  const phoneRegex = /(\+45.?\d{2,8}.?\d{2}.?\d{2}.?\d{2})/g;

  return phone?.length ? phone.match(phoneRegex)[0].replace(/ /g, '') : '';
};

export const filterPersonalInformation = (properties) => {
  const infoToRemove = ['name', 'email'];

  const filtered = Object.keys(properties)
    .filter((key) => !infoToRemove.includes(key))
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = properties[key];
      return obj;
    }, {});

  return filtered;
};
