import { RichTextInputWarning } from '../../../client-server-utils/errors/ContentErrors';

export default class RichTextErrorReporter {
  errorContainer: { error: Error };

  policyName: string;

  constructor(errorContainer) {
    this.errorContainer = errorContainer;
  }

  trigger(error) {
    return () => {
      this.errorContainer.error = new RichTextInputWarning(
        this?.policyName ?? '',
        error ?? '',
      );
    };
  }
}
