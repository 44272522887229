const HeaderTextGroupTypes = {
  NARROW: 'narrow',
  WIDE: 'wide',
  LARGE_WIDE: 'large-wide',
  LARGE_NARROW: 'large-narrow',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

module.exports = HeaderTextGroupTypes;
