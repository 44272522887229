import { Component } from 'react';
import { css } from '@emotion/react';

import SlideShowContent from './SlideShowContent';
import Progress from './ProgressBar';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const useStyles = css({
  '& .content': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '60px',
    },
  },
});

type SlideShowProps = {
  items?: InternalPropTypes.MediaItem[];
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
};

interface ISlideShowState {
  percent: number;
  play: boolean;
  focusChanged: boolean;
  active: number;
}

class SlideShow extends Component<SlideShowProps, ISlideShowState> {
  private interval: ReturnType<typeof setInterval>;

  static defaultProps = {
    items: [],
    header: null,
    lightHeader: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      play: true,
      focusChanged: false,
      active: 0,
    };

    this.changePlayState = this.changePlayState.bind(this);
    this.changePercent = this.changePercent.bind(this);
    this.changeFocus = this.changeFocus.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(({ percent, play }) => ({
        percent:
          percent < 100 && play ? Number((percent + 0.2).toFixed(2)) : percent,
        play: percent >= 100 ? false : play,
        focusChanged: false,
      }));
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changePlayState() {
    this.setState((prevState) => ({
      play: !prevState.play,
    }));
  }

  changePercent(percent) {
    const { active } = this.state;
    this.setState(() => ({
      percent,
      active: percent === 0 ? 0 : active,
    }));
  }

  changeFocus(index) {
    const { focusChanged } = this.state;
    if (!focusChanged) {
      this.setState(() => ({
        active: index,
        focusChanged: true,
      }));
    }
  }

  render() {
    const { active, percent, play } = this.state;
    const { items, header, lightHeader } = this.props;

    return (
      <div css={[useStyles]}>
        <SlideShowContent
          key={active}
          {...items[active]}
          lightHeader={lightHeader}
          header={header}
        />
        <Progress
          items={items}
          play={play}
          percent={percent}
          changePlayState={this.changePlayState}
          changePercent={this.changePercent}
          changeFocus={this.changeFocus}
        />
      </div>
    );
  }
}

export default SlideShow;
