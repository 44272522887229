enum TitleTypes {
  SECTION = 'section',
  CONTACT = 'contact',
  CASES = 'cases',
  TEASER = 'teaser',
  BUZZWORD = 'buzzword',
  NAVIGABLE = 'navigable',
  NEXT = 'next',
  LEAD = 'lead',
}

export default TitleTypes;
