import { Component } from 'react';
import { keyframes, css } from '@emotion/react';
import { BackgroundColorEnum } from '../../../constants/cms-constants';
import ModalMenu from '../../ModalMenu';

const animation = {
  slideIn: keyframes`
    from {
      transform: translateY(100%)
    }
    to {
      transform: translateY(0)
    }
  `,
  textSlideIn: keyframes`
    from {
      transform: translateY(250%)
    }
    to {
      transform: translateY(0)
    }
  `,
  slideOut: keyframes`
    from {
      transform: translateY(0)
    }
    to {
      transform: translateY(-100%)
    }
  `,
};

const animationTime = 700;

const useStyles = css({
  '& ': {
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: '1001',
    top: 0,
    left: 0,
    backgroundColor: BackgroundColorEnum.BLACK,
    overflowY: 'auto',
  },
  '&.modal-overlay-closing': {
    animation: `${animation.slideOut} ${animationTime}ms ease-out`,
    '& .text-animation-container': {
      overflow: 'hidden',
    },
  },
  '&.modal-overlay-opening': {
    animation: `${animation.slideIn} ${animationTime}ms forwards ease-in`,
    '& .text-animation-container': {
      overflow: 'hidden',
    },
    ' .text-animation': {
      animation: `${animation.textSlideIn} ${
        animationTime * 1.3
      }ms cubic-bezier(1,-0.09,.55,.54)`,
    },
    '& .header-text p': {
      marginBottom: '0',
    },
  },
});

interface IFullscreenOverlayState {
  closing?: boolean;
}

type FullscreenOverlayProps = {
  children?: React.ReactNode;
};

export default class FullscreenOverlay extends Component<
  FullscreenOverlayProps,
  IFullscreenOverlayState
> {
  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      closing: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(history) {
    this.setState({ closing: true });
    setTimeout(() => {
      history.push(`${history.location.pathname}`);
    }, animationTime);
  }

  render() {
    const { children } = this.props;
    const { state } = this;

    return (
      <div
        className={`modal-overlay-${state.closing ? 'closing' : 'opening'}`}
        css={useStyles}
      >
        <ModalMenu handleClose={this.handleClose} />
        {children}
      </div>
    );
  }
}
