import { Component } from 'react';
import { css } from '@emotion/react';

import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Image from '../atoms/Image';
import MediaQuery from '../atoms/MediaQuery';
import TitleTextGroup from './TitleTextGroup';
import SectionTop from './SectionTop';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .section': {
    [Breakpoints.TabletUp.mq]: {},
    [Breakpoints.Mobile.mq]: {
      marginBottom: '40px',
    },
  },

  '& .title-text-group': {
    [Breakpoints.TabletUp.mq]: {
      position: 'absolute',
      bottom: '0',
    },
    [Breakpoints.Mobile.mq]: {},
  },
  '& .image': {
    display: 'flex',
    flexDirection: 'row-reverse',
    objectFit: 'cover',
    [Breakpoints.DesktopUp.mq]: {
      width: '100%',
      height: '589px',
      paddingLeft: '75px',
    },
    [Breakpoints.Tablet.mq]: {
      width: '100%',
      height: '462px',
    },
    [Breakpoints.Mobile.mq]: {
      width: '100%',
      height: '426px',
      marginBottom: '18px',
    },
  },
});

type ImageTextsProps = {
  bodyText?: InternalPropTypes.RichText;
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  titleText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
};

class ImageTexts extends Component<ImageTextsProps> {
  static defaultProps = {
    bodyText: null,
    header: null,
    lightHeader: null,
    titleText: null,
    image: null,
  };

  render() {
    const { header, lightHeader, titleText, bodyText, image } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { h: '426' },
      [ScreenSize.Tablet]: { h: '462' },
      [ScreenSize.Desktop]: { h: '589' },
    };

    return (
      <div css={styles} className="image-texts">
        <MediaQuery visible={Breakpoints.Mobile}>
          <SectionTop lightHeaderText={lightHeader} headerText={header} />
          <Image image={image} sizes={sizes} />
          <TitleTextGroup bodyText={bodyText} titleText={titleText} />
        </MediaQuery>

        <MediaQuery visible={Breakpoints.TabletUp}>
          <Grid2CellUnevenSplit
            leftCellCount={4}
            rightCellCount={7}
            onRenderCell1={() => (
              <div>
                <SectionTop lightHeaderText={lightHeader} headerText={header} />
                <TitleTextGroup bodyText={bodyText} titleText={titleText} />
              </div>
            )}
            onRenderCell2={() => <Image image={image} sizes={sizes} />}
            snapAtBreakpoint={Breakpoints.MobileDown}
          />
        </MediaQuery>
      </div>
    );
  }
}

export default ImageTexts;
