import { Component } from 'react';
import { css, keyframes } from '@emotion/react';

import Header from '../atoms/Header';
import MediaQuery from '../atoms/MediaQuery';
import GridEvenSplit from '../atoms/GridEvenSplit';
import Image from '../atoms/Image';

import getRows from '../../../utils/utilFunctions';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import { colorEnum } from '../../../constants/colors';
import ScreenSize from '../../../constants/screenSize';

const styleHeight = 480;

const styles = css({
  position: 'relative',
  overflow: 'hidden',
  '& .overlay': {
    background: colorEnum.lightGray,
    opacity: '0.7',
    zIndex: '1',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    width: '100%',
  },
  '& .cascade': {
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: `${styleHeight}px`,
    overflow: 'hidden',
    '& .logo-grid': {
      position: 'absolute',
      textAlign: 'center',
      opacity: '0',
      [Breakpoints.Mobile.mq]: {
        bottom: '-40px',
        marginBottom: '30px',
      },
      [Breakpoints.TabletUp.mq]: {
        bottom: '-50px',
        '& .cell-1': {
          display: 'flex',
          justifyContent: 'flex-start',
        },
        '& .cell-2, .cell-3': {
          display: 'flex',
          justifyContent: 'center',
        },
        '& .cell-4': {
          display: 'flex',
          justifyContent: 'flex-end',
        },
        '& .cell-2': {
          paddingRight: '15px',
        },
        '& .cell-3': {
          paddingLeft: '15px',
        },
      },
    },
    '& .header': {
      position: 'absolute',
      top: '50%',
      zIndex: '1',
      textAlign: 'center',
      left: '0',
      right: '0',
      [Breakpoints.Mobile.mq]: {
        width: '251px',
        margin: 'auto',
      },
    },
    '& .logoImg': {
      filter: 'grayscale(100%)',
      maxHeight: '80px',
      maxWidth: '100%',
    },
  },
});

function dynamicAnimations(rowCount) {
  let totalHeight = (rowCount * 2 - 1) * 80;
  const cascadeHeight = styleHeight;
  if (totalHeight < cascadeHeight) {
    totalHeight = cascadeHeight;
  }

  return keyframes`
    0% {
      transform:translateY(0);
      opacity: 0;
      }
      ${(cascadeHeight / totalHeight) * 30}% {
      opacity: 1;
      }
      ${(cascadeHeight / totalHeight) * 70}% {
      opacity: 1;
      }
      ${(cascadeHeight / totalHeight) * 100}% {
      opacity: 0;
      }
      100% {
      transform:translateY(-${totalHeight}px);
  }`;
}

type LogoScrollerProps = {
  content?: InternalPropTypes.Image[];
  header?: InternalPropTypes.RichText;
};

class LogoScroller extends Component<LogoScrollerProps> {
  static defaultProps = {
    content: null,
    header: null,
  };

  render() {
    const { header, content } = this.props;

    const size = {
      w: '120',
      h: '80',
    };

    const items = content
      ? content.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="logoSet">
            <Image className="logoImg" image={image} sizes={size} />
          </div>
        ))
      : [];

    const delaySpan = 2;

    let keepSpeedDownMobile = 1;
    let keepSpeedDownTablet = 1;

    const itemsWithTwoColumns = getRows(items, 2);
    const itemsWithFourColumns = getRows(items, 4);

    // 3.5 rows fit in the visible div
    if (itemsWithTwoColumns.length < 4) {
      keepSpeedDownMobile = 4.5 - itemsWithTwoColumns.length;
    }
    if (itemsWithFourColumns.length < 4) {
      keepSpeedDownTablet = 4.5 - itemsWithFourColumns.length;
    }

    return (
      <div css={styles}>
        <div className="overlay" />
        <div className="cascade">
          <Header
            seoWeight="h2"
            className="header"
            type={Header.types.KEYFIGURE}
          >
            {header}
          </Header>

          <MediaQuery visible={Breakpoints.Mobile}>
            <div>
              {itemsWithTwoColumns.map((outer, idx) => (
                <GridEvenSplit
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  className="logo-grid"
                  css={{
                    animation: `${dynamicAnimations(
                      itemsWithTwoColumns.length,
                    )} ${
                      keepSpeedDownMobile * 2 * itemsWithTwoColumns.length
                    }s infinite linear`,
                    animationDelay: `${delaySpan * idx}s`,
                  }}
                  onRenderCells={outer.map((element) => () => element)}
                />
              ))}
            </div>
          </MediaQuery>

          <MediaQuery visible={Breakpoints.TabletUp}>
            {itemsWithFourColumns.map((outer, idx) => (
              <GridEvenSplit
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                className="logo-grid"
                // time = (rows * size * 2)/speed
                // speed = size, is a good speed
                css={{
                  animation: `${dynamicAnimations(
                    itemsWithFourColumns.length,
                  )} ${
                    keepSpeedDownTablet * 2 * itemsWithFourColumns.length
                  }s infinite linear`,
                  animationDelay: `${delaySpan * idx}s`,
                }}
                onRenderCells={outer.map((element) => () => element)}
              />
            ))}
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default LogoScroller;
