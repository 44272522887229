import { Fragment, Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import BannerItem from './common/molecules/BannerItem';

import * as InternalPropTypes from '../constants/internal-types';
import { colorEnum, defaultColors } from '../constants/colors';
import HstackSpacing, { HstackSpacingEnum } from './common/atoms/HstackSpacing';
import {
  TypeBackgroundColorTypes,
  TypeFontColorTypes,
} from '../constants/cms-constants';

const styles = {
  marginTop: '30px',
};

type BannerComponentProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  spacing?: HstackSpacingEnum;
  fontColor?: TypeFontColorTypes;
};

class BannerComponent extends Component<BannerComponentProps> {
  static defaultProps = {
    mediaItems: [],
    backgroundColor: colorEnum.blue,
    spacing: HstackSpacing.CENTER,
    fontColor: defaultColors.bodyText,
  };

  render() {
    const { mediaItems, backgroundColor, spacing, fontColor } = this.props;
    const Wrapper = ContentOuterBound;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <Wrapper>
        <section css={[styles, addStyle]}>
          <HstackSpacing spacing={spacing}>
            {mediaItems.map((i, idx) => (
              <BannerItem
                // eslint-disable-next-line react/no-array-index-key
                key={`item-${idx}`}
                image={i.image}
                text={i.bodyText}
                linkTo={i.link?.linkTo}
                fontColor={fontColor}
                openInNewWindowOrTab={i.link?.openInNewWindowOrTab}
              />
            ))}
          </HstackSpacing>
        </section>
      </Wrapper>
    );
  }
}

export default BannerComponent;
