import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Header2TextGroupMolecule from './common/molecules/Header2TextGroup';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '43px',
    paddingBottom: '43px',
  },
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '56px',
    paddingBottom: '56px',
  },
};

type Header2TextGroupProps = {
  headerText?: InternalPropTypes.RichText;
  leftSnippet?: InternalPropTypes.NamedSnippet;
  rightSnippet?: InternalPropTypes.NamedSnippet;
  backgroundColor?: TypeBackgroundColorTypes;
};

class Header2TextGroup extends Component<Header2TextGroupProps> {
  static defaultProps = {
    headerText: null,
    leftSnippet: null,
    rightSnippet: null,
    backgroundColor: null,
  };

  render() {
    const { headerText, leftSnippet, rightSnippet, backgroundColor } =
      this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div className="header-2-text-group-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Header2TextGroupMolecule
              headerText={headerText}
              leftText={leftSnippet.text}
              rightText={rightSnippet.text}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default Header2TextGroup;
