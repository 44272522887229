import { Component } from 'react';
import { css } from '@emotion/react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import WellGridMolecule from './common/molecules/WellGrid';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { colorEnum } from '../constants/colors';
import {
  TypeBackgroundColorTypes,
  TypeWellTypes,
} from '../constants/cms-constants';

const styles = css({
  '& .image': {
    maxWidth: '100%',
  },
  '&.client-well-grid-component': {
    '& .client': {
      backgroundColor: colorEnum.lightGray,
      marginBottom: '32px',
    },
    '& .image': {
      margin: 'auto',
      objectFit: 'scale-down',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '56px',
      paddingBottom: '56px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '64px',
      paddingBottom: '64px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  '&.partner-well-grid-component': {
    '& .partner': {
      backgroundColor: colorEnum.lightGray,
      marginBottom: '32px',
    },
    '& .image': {
      margin: 'auto',
      objectFit: 'scale-down',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '48px',
      paddingBottom: '128px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '40px',
      paddingBottom: '104px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '24px',
      paddingBottom: '104px',
    },
  },
});

type WellGridProps = {
  items?: InternalPropTypes.MediaItem[];
  type?: TypeWellTypes;
  backgroundColor?: TypeBackgroundColorTypes;
};

class WellGrid extends Component<WellGridProps> {
  static types = WellGridMolecule.types;

  static defaultProps = {
    items: [],
    type: null,
    backgroundColor: null,
  };

  render() {
    const { items, backgroundColor, type } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className={`${type}-well-grid-component`} css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <WellGridMolecule items={items} type={type} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default WellGrid;
