export type LanguageType = {
  key: string;
  iso: string;
  urlLanguage: string;
  defaultDomain: string;
  // eslint-disable-next-line no-restricted-globals
  name: string;
};

export const DK = {
  key: 'Danish',
  iso: 'da-DK',
  urlLanguage: 'dk',
  defaultDomain: '.dk',
  name: 'Dansk',
} as const;

export const EN = {
  key: 'English',
  iso: 'en-US',
  urlLanguage: 'en',
  defaultDomain: '.com',
  name: 'English',
} as const;

export const LANGUAGES = [EN, DK] as const;

const languageNames = LANGUAGES.map((l) => l.key);
export type LanguageNameTypes = typeof languageNames[number];
