import { Component, ReactNode } from 'react';
import { compose } from 'recompose';
import withAppConfig, {
  WithAppConfigProps,
} from '../../utils/hocs/withAppConfig';
import EventInput from '../../utils/reporterInput/EventInput';

type TrackerComponentProps = {
  logText: string;
  trackOncePrSession?: boolean;
  children?: React.ReactNode;
};

type TrackerComponentInnerProps = WithAppConfigProps & TrackerComponentProps;

class TrackerComponent extends Component<TrackerComponentInnerProps> {
  static defaultProps = {
    trackOncePrSession: false,
    children: null,
  };

  componentDidMount(): void {
    const { logText, trackOncePrSession, appConfig } = this.props;

    if (trackOncePrSession) {
      const wasLogSent = sessionStorage.getItem(logText);
      if (!wasLogSent) {
        appConfig.services.reporterService.trackEvent(new EventInput(logText));
        sessionStorage.setItem(logText, 'true');
      }
    } else {
      appConfig.services.reporterService.trackEvent(new EventInput(logText));
    }
  }

  render(): ReactNode {
    return this.props.children;
  }
}

export default compose<TrackerComponentInnerProps, TrackerComponentProps>(
  withAppConfig,
)(TrackerComponent);
