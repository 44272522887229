import Contentful from 'contentful';
import * as ContentState from '../constants/contentstate';
import * as InternalPropTypes from '../constants/internal-types';

export type EntryStatePairType = {
  entryId: string;
  state: InternalPropTypes.ContentStateType;
};

export default class ContentfulStateCalculator {
  private entryState: Record<string, InternalPropTypes.ContentStateType>;

  private aggregatedState: Record<string, InternalPropTypes.ContentStateType>;

  private entryMap: Record<string, Contentful.Entry<any>>;

  constructor(private contentApiResponse: Contentful.Entry<any>) {
    this.entryMap = {};
    if (contentApiResponse !== null) {
      this._visitEntries(contentApiResponse, (node) => {
        this.entryMap[node.sys.id] = node;
      });
    }
    this.aggregatedState = {};
    this.entryState = {};
  }

  /**
   * Visit every node, starting from the provided entry, breadth-first
   * @param entry Starting node
   * @param visitor Visitor function
   */
  _visitEntries(
    entry: Contentful.Entry<any>,
    visitor: (node: Contentful.Entry<any>) => void,
  ) {
    const visited = {};
    let worklist: Array<Contentful.Entry<any>> = [entry];
    while (worklist.length > 0) {
      const node = worklist.pop() as Contentful.Entry<any>;
      if (node.sys.id in visited) {
        // eslint-disable-next-line no-continue
        continue;
      }
      visited[node.sys.id] = true;
      visitor.call(this, node);
      if (node.fields) {
        const entities = Object.values(node.fields)
          .flat()
          .filter(InternalPropTypes.isCmsEntity) as Array<
          Contentful.Entry<any>
        >;
        worklist = worklist.concat(entities);
      }
    }
  }

  /**
   * Get the content state for this specific entry
   * @param previewEntry The entry from the preview response
   * @returns The content state as ContentStateType
   * @type ContentStateType
   */
  getEntryState(
    previewEntry: Contentful.Entry<any>,
  ): InternalPropTypes.ContentStateType {
    let result = this.entryState[previewEntry.sys.id];
    if (!result) {
      result = this._calcEntryState(previewEntry);
      this.entryState[previewEntry.sys.id] = result;
    }
    return result;
  }

  /**
   * Returns the most "drafty" content state below the node. I.e. is anything under this node not published
   * @param previewEntry Starting node
   * @returns Content state from the most "drafty" entry below the node
   * @type ContentStateType
   */
  getAggregatedEntryState(
    previewEntry: Contentful.Entry<any>,
  ): InternalPropTypes.ContentStateType {
    let result = this.aggregatedState[previewEntry.sys.id];
    if (!result) {
      result = this._calcAggregatedEntryState(previewEntry);
      this.aggregatedState[previewEntry.sys.id] = result;
    }
    return result;
  }

  /**
   * Deduce the state of a content entry by having access to both preview and content data.
   * @param previewEntry The entry in the response from the contentful preview api
   * @returns The deduced state of the entry as ContentStateType
   * @type ContentStateType
   */
  _calcEntryState(
    previewEntry: Contentful.Entry<any>,
  ): InternalPropTypes.ContentStateType {
    let result: InternalPropTypes.ContentStateType = ContentState.PUBLISHED;
    if (this.contentApiResponse !== null) {
      const contentEntry = this.entryMap[previewEntry.sys.id];
      // assume this has never been published
      result = ContentState.DRAFT;
      if (contentEntry) {
        if (contentEntry.sys.updatedAt === previewEntry.sys.updatedAt) {
          // last time of update is similar, so preview has not been changed since publishing
          result = ContentState.PUBLISHED;
        } else {
          // A content entry exists and preview is updated later than content, i.e. is it changed
          result = ContentState.CHANGED;
        }
      }
    }
    return result;
  }

  /**
   * Gather the "lower most" or most recently changed content state among all content state below the entry.
   * @param previewEntry Starting entry
   * @returns The content state from the most "drafty" entry below the entry. E.g. "is anything under this node changed"?
   */
  _calcAggregatedEntryState(
    previewEntry: Contentful.Entry<any>,
  ): InternalPropTypes.ContentStateType {
    const valueMap = {
      [ContentState.DRAFT]: 1,
      [ContentState.CHANGED]: 2,
      [ContentState.ARCHIVED]: 3,
      [ContentState.PUBLISHED]: 4,
    };

    let result: InternalPropTypes.ContentStateType =
      this.getEntryState(previewEntry);
    this._visitEntries(previewEntry, (node) => {
      const state = this.getEntryState(node);
      if (!result || (state && valueMap[state] < valueMap[result])) {
        result = state;
      }
    });
    return result;
  }

  /**
   * Returns the list of non-published entrys under the starting entry
   * @param previewEntry Starting entry
   * @returns A list of non-published nodes in EntryStatePairType
   * @type EntryStatePairType
   */
  getNotPublishedEntries(
    previewEntry: Contentful.Entry<any>,
  ): Array<EntryStatePairType> {
    const result: Array<EntryStatePairType> = [];
    this._visitEntries(previewEntry, (node) => {
      const state = this.getEntryState(node);
      if (state !== ContentState.PUBLISHED) {
        result.push({
          entryId: node.sys.id,
          state,
        });
      }
    });
    return result;
  }
}
