import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const frameStyle = {
  border: 'none',
  height: '100%',
  width: '100%',
};

const spinnerContainerStyle = {
  padding: '25%',
  textAlign: 'center' as const,
};

type OEmbedProps = {
  OEmbedURL: string;
  type: string;
  loadingAltText?: string;
};

type OEmbedData = {
  title: string;
  html: string;
};

interface IOEmbedState {
  oEmbedData?: OEmbedData;
}

class OEmbed extends Component<OEmbedProps, IOEmbedState> {
  static defaultProps = {
    loadingAltText: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      oEmbedData: null,
    };
  }

  componentDidMount() {
    this.fetchOEmbedData();
  }

  fetchOEmbedData() {
    const { OEmbedURL } = this.props;
    fetch(OEmbedURL)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ oEmbedData: data });
      });
  }

  loadingFrame() {
    const { loadingAltText } = this.props;
    return (
      <div style={spinnerContainerStyle}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{loadingAltText}</span>
        </Spinner>
      </div>
    );
  }

  formattedOEmbedFrame() {
    const { oEmbedData } = this.state;
    if (oEmbedData) {
      const frameSrc = oEmbedData.html.match(/src="([^"]+)"/)[1];
      return (
        <iframe
          src={frameSrc}
          style={frameStyle}
          title={oEmbedData.title}
          allow="autoplay; fullscreen; picture-in-picture"
        />
      );
    }
    return this.loadingFrame();
  }

  render() {
    const { type } = this.props;
    return (
      <div className={`${type}-container`}>{this.formattedOEmbedFrame()}</div>
    );
  }
}

export default OEmbed;
