import { colorEnum } from './colors';

export const fontColors = {
  BLACK: colorEnum.black,
  WHITE: colorEnum.white,
  BLUE: colorEnum.blue,
  GRAY: colorEnum.gray,
  BRIGHTGRAY: colorEnum.brightGray,
  PALEGREEN: colorEnum.paleGreen,
} as const;

export default fontColors;
