import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import ProductCardGrid from '../../components/ProductCardGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';

class ProductCardGridBlock extends Component<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;

    let mediaItems;

    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return <ProductCardGrid items={mediaItems} />;
  }
}

export default ProductCardGridBlock;
