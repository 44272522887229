import { Component } from 'react';

import * as Breakpoints from '../../../constants/breakpoints';

const useStyles = {
  [Breakpoints.Mobile.mq]: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingLeft: '60px',
    paddingRight: '60px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingLeft: '100px',
    paddingRight: '100px',
  },
};

type ContentSpacerProps = {
  children?: React.ReactNode;
};

class ContentSpacer extends Component<ContentSpacerProps> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;
    return (
      <div css={useStyles} className="content-spacer">
        {children}
      </div>
    );
  }
}

export default ContentSpacer;
