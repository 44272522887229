import { Component } from 'react';

import Quote from './common/molecules/Quote';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import {
  TypeBackgroundColorTypes,
  TypeQuoteTypes,
} from '../constants/cms-constants';

const styles = {
  '&.quote-component ': {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
};

type QuoteComponentProps = {
  quote?: InternalPropTypes.RichText;
  sender?: string | InternalPropTypes.RichText;
  type?: TypeQuoteTypes;
  backgroundColor?: TypeBackgroundColorTypes;
};

class QuoteComponent extends Component<QuoteComponentProps> {
  static types = Quote.types;

  static defaultProps = {
    quote: null,
    sender: null,
    type: this.types.BLOCK,
    backgroundColor: null,
  };

  render() {
    const { quote, sender, type, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]} className={`quote-component ${type}`}>
        <ContentOuterBound>
          <ContentSpacer>
            {quote && <Quote type={type} quote={quote} sender={sender} />}
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default QuoteComponent;
