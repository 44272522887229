import { BreakpointType } from '../constants/breakpoints';

interface Columns {
  xs: number | { span: number; offset: number };
  sm: number | { span: number; offset: number };
  md: number | { span: number; offset: number };
  lg: number | { span: number; offset: number };
  xl: number | { span: number; offset: number };
}

const maxColumnSize = 12;

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
};

export const calculateEvenGridColumnsSizes = (
  snapAtBreakpoint: BreakpointType,
  itemCount: number,
  twoColumnLayout = false,
): Columns => {
  let evenlyDividedColumnSize = maxColumnSize / itemCount;
  if (twoColumnLayout && itemCount === 4) {
    evenlyDividedColumnSize = (maxColumnSize * 2) / itemCount;
  }
  let columns = { xs: null, sm: null, md: null, lg: null, xl: null };

  if (snapAtBreakpoint != null) {
    const { widestWidthInPx } = snapAtBreakpoint;

    columns = {
      xs: evenlyDividedColumnSize,
      sm: evenlyDividedColumnSize,
      md: evenlyDividedColumnSize,
      lg: evenlyDividedColumnSize,
      xl: evenlyDividedColumnSize,
    };

    if (breakpointValues.xs < widestWidthInPx) {
      columns.xs = maxColumnSize;
    }
    if (breakpointValues.sm < widestWidthInPx) {
      columns.sm = maxColumnSize;
    }
    if (breakpointValues.md < widestWidthInPx) {
      columns.md = maxColumnSize;
    }
    if (breakpointValues.lg < widestWidthInPx) {
      columns.lg = maxColumnSize;
    }
    if (breakpointValues.xl < widestWidthInPx) {
      columns.xl = maxColumnSize;
    }
  }

  return columns;
};

function calculateFourColClassNames(colCount: number) {
  const fourColClasses = [];
  if (colCount === 4) {
    for (let i = 1; i <= colCount; i += 1) {
      if (i === 1) fourColClasses.push(`cell cell-${i}`);
      else fourColClasses.push(` cell cell-${i}`);
    }
  }
  return fourColClasses;
}

export const calculateEvenGridColumnClassNames = (
  colCount: number,
): Array<string> => {
  const left = 'cell left-cell';
  const right = 'cell right-cell';
  const center = 'cell center-cell';

  switch (colCount) {
    case 1:
      return ['cell'];
    case 2:
      return [left, right];
    case 3:
      return [left, center, right];
    case 4:
      return calculateFourColClassNames(colCount);
    default:
      return null;
  }
};

const canBothCellsFitOnOneRow = (
  leftCellCount: number,
  rightCellCount: number,
): boolean => leftCellCount + rightCellCount <= 12;

export const assignCellSizes = (
  leftCellCount: number,
  rightCellCount: number,
  snapAtBreakpoint: BreakpointType,
  alwaysBreak: boolean,
): { left: Columns; right: Columns } => {
  if (!alwaysBreak) {
    if (canBothCellsFitOnOneRow(leftCellCount, rightCellCount)) {
      const offset = maxColumnSize - (leftCellCount + rightCellCount);
      const left = {
        xs: leftCellCount,
        sm: leftCellCount,
        md: leftCellCount,
        lg: leftCellCount,
        xl: leftCellCount,
      };

      const right = {
        xs: { span: rightCellCount, offset },
        sm: { span: rightCellCount, offset },
        md: { span: rightCellCount, offset },
        lg: { span: rightCellCount, offset },
        xl: { span: rightCellCount, offset },
      };

      const { widestWidthInPx } = snapAtBreakpoint;

      if (breakpointValues.xs < widestWidthInPx) {
        left.xs = maxColumnSize;
        right.xs = { span: maxColumnSize, offset: 0 };
      }

      if (breakpointValues.sm < widestWidthInPx) {
        left.sm = maxColumnSize;
        right.sm = { span: maxColumnSize, offset: 0 };
      }

      if (breakpointValues.md < widestWidthInPx) {
        left.md = maxColumnSize;
        right.md = { span: maxColumnSize, offset: 0 };
      }

      if (breakpointValues.lg < widestWidthInPx) {
        left.lg = maxColumnSize;
        right.lg = { span: maxColumnSize, offset: 0 };
      }

      if (breakpointValues.xl < widestWidthInPx) {
        left.xl = maxColumnSize;
        right.xl = { span: maxColumnSize, offset: 0 };
      }

      return { left, right };
    }
  }

  const left = {
    xs: maxColumnSize,
    sm: maxColumnSize,
    md: maxColumnSize,
    lg: maxColumnSize,
    xl: maxColumnSize,
  };

  const right = {
    xs: maxColumnSize,
    sm: maxColumnSize,
    md: maxColumnSize,
    lg: maxColumnSize,
    xl: maxColumnSize,
  };
  return { left, right };
};
