/* eslint-disable max-classes-per-file */
class CacheEntry {
  static thaw({ key, value }: { key: string; value: object }) {
    return new CacheEntry(key, value);
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public key: string,
    public payload: object,
    public expiresAt: number | null = null,
  ) {}

  freeze(): { key: string; value: object } {
    return { key: this.key, value: this.payload };
  }

  isExpired(): boolean {
    return !this.expiresAt || this.expiresAt < new Date().valueOf();
  }
}

export function protectedStringify(payload: object): string {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          console.warn(
            `Cache found circular dependencies while serializing under key ${key}. Skipping in output!`,
          );
          return;
        }
        seen.add(value);
      }
      // eslint-disable-next-line consistent-return
      return value;
    };
  };

  return JSON.stringify(payload, getCircularReplacer());
}

export function isCyclic(obj: object) {
  const keys: Array<string> = [];
  const stack: Array<object> = [];
  const stackSet = new Set();
  let detected = false;

  function detect(obj: object, key: string) {
    if (obj && typeof obj !== 'object') {
      return;
    }

    if (stackSet.has(obj)) {
      // it's cyclic! Print the object and its locations.
      const oldindex = stack.indexOf(obj);
      const l1 = `${keys.join('.')}.${key}`;
      const l2 = keys.slice(0, oldindex + 1).join('.');
      console.log(`CIRCULAR: ${l1} = ${l2} = ${obj}`);
      console.log(obj);
      detected = true;
      return;
    }

    keys.push(key);
    stack.push(obj);
    stackSet.add(obj);
    // eslint-disable-next-line no-restricted-syntax
    for (const k in obj) {
      // dive on the object's children
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        detect(obj[k], k);
      }
    }

    keys.pop();
    stack.pop();
    stackSet.delete(obj);
  }

  detect(obj, 'obj');
  return detected;
}

export type SerializedCache = string | Array<{ key: string; value: object }>;

export default class LoadingCache {
  private store: Map<string, CacheEntry>;

  constructor(private timeToLiveInSecs: number) {
    this.store = new Map();
  }

  /**
   * Prepare the from the current cache a serialized version for transfer
   * @param keys The keys to be serialized
   * @returns Serialized cache represented as a string
   */
  serializeForClient(keys: Array<string>): string {
    // eslint-disable-next-line no-param-reassign
    keys = keys || [];
    const entriesToSerialize = keys
      .filter((key) => this.hasKey(key), this)
      .map((key) => this.store.get(key)?.freeze(), this);
    if (isCyclic(entriesToSerialize)) {
      console.error(
        'Cyclic references detected in cache. This makes it impossible to serialize correctly!',
      );
      return protectedStringify(entriesToSerialize);
    }
    return JSON.stringify(entriesToSerialize);
  }

  /**
   * Cold starts a cache from a serialized version
   * @param serializedCache The output from the serialization
   * @see serializeForClient
   * @returns A promise that, once resolved, has initialized the cache
   */
  initializeFromSerialized(serializedCache: SerializedCache) {
    return new Promise<void>((resolve, reject) => {
      try {
        let deserializedCache: Array<{ key: string; value: object }>;
        if (typeof serializedCache === 'string') {
          deserializedCache = JSON.parse(serializedCache) as Array<{
            key: string;
            value: object;
          }>;
        } else {
          deserializedCache = serializedCache;
        }

        deserializedCache
          .map((serializedEntry) => CacheEntry.thaw(serializedEntry))
          .forEach((entry) => this.add(entry.key, entry), this);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Check if a key is in the cache
   * @param key Key in question
   * @returns true if key is present in cache
   */
  hasKey(key: string) {
    return this.store.has(key);
  }

  /**
   * Clears everything in the cache
   */
  evictAll() {
    this.store.clear();
  }

  /**
   * Retrieve an object saved in the cache
   * @param key Get given key
   * @returns The value stored under the key
   */
  get(key: string): object | undefined {
    return this.hasKey(key) ? this.store.get(key)?.payload : undefined;
  }

  /**
   * Evicts outdated components based on timestamp
   */
  evictExpired() {
    this.store.forEach((entry, key) => {
      if (entry.isExpired()) {
        this.store.delete(key);
      }
    });
  }

  /**
   * Remove an entry from the cache
   * @param key The given key
   */
  remove(key) {
    this.store.delete(key);
  }

  /**
   * Add an object or CacheEntry to the cache
   * @param key The given cache key to add it under
   * @param payload The value to add
   */
  add(key: string, payload: object | CacheEntry): void {
    let entry: CacheEntry;
    const expiresAt = new Date().valueOf() + this.timeToLiveInSecs * 1000;
    if (payload instanceof CacheEntry) {
      if (payload.key !== key) {
        throw new Error(
          'Tried to add a cache entry with a different key than the cache entry already had',
        );
      }
      // eslint-disable-next-line no-param-reassign
      payload.expiresAt = expiresAt;
      entry = payload;
    } else {
      entry = new CacheEntry(key, payload, expiresAt);
    }
    this.store.set(key, entry);
  }

  /**
   * Check if the cache has a valid entry for the given key
   * @param key The given key
   * @returns true if the entry is valid
   */
  hasValid(key: string) {
    this.evictExpired();
    return this.hasKey(key);
  }
}
