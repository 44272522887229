import { Component } from 'react';

import * as Breakpoints from '../../../constants/breakpoints';

const styles = {
  '& .hstack-space-between': {
    display: 'flex',
    justifyContent: 'space-between',
    '&.hstack-space-between-case': {
      [Breakpoints.Mobile.mq]: {
        justifyContent: 'space-between',
      },
      [Breakpoints.Tablet.mq]: {
        justifyContent: 'space-around',
      },
      [Breakpoints.DesktopUp.mq]: {
        justifyContent: 'space-evenly',
      },
    },
  },
  '& .hstack-center': {
    [Breakpoints.TabletUp.mq]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '& .hstack-space-around': {
    [Breakpoints.TabletUp.mq]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
};

export enum HstackSpacingEnum {
  SPACEBETWEEN = 'space-between',
  CENTER = 'center',
  SPACEAROUND = 'around',
}

type HstackSpacingProps = {
  spacing?: `${HstackSpacingEnum}`;
  className?: string;
  children: React.ReactNode;
};

class HstackSpacing extends Component<HstackSpacingProps> {
  static SPACEBETWEEN = HstackSpacingEnum.SPACEBETWEEN;

  static CENTER = HstackSpacingEnum.CENTER;

  static SPACEAROUND = HstackSpacingEnum.SPACEAROUND;

  static defaultProps = {
    className: null,
    spacing: HstackSpacing.SPACEBETWEEN,
  };

  render() {
    const { children, spacing, className } = this.props;
    return (
      <div css={styles}>
        <div
          className={`${
            // eslint-disable-next-line no-nested-ternary
            spacing === 'center'
              ? 'hstack-center '
              : spacing === 'around'
              ? 'hstack-space-around'
              : 'hstack-space-between'
          } ${className ? `-${className}` : ''}`}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default HstackSpacing;
