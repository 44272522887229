import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ImageLeftAndTextRightMolecule from './common/molecules/ImageLeftAndTextRightMolecule';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.TabletDown.mq]: {
    paddingTop: '52px',
    paddingBottom: '52px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
};

type ImageLeftAndTextRightComponentProps = {
  image?: InternalPropTypes.Image;
  bodyText?: InternalPropTypes.RichText;
  mainHeaderText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
  backgroundColor?: TypeBackgroundColorTypes;
};

class ImageLeftAndTextRightComponent extends Component<ImageLeftAndTextRightComponentProps> {
  static defaultProps = {
    image: null,
    bodyText: null,
    mainHeaderText: null,
    lightHeaderText: null,
    backgroundColor: null,
  };

  render() {
    const {
      image,
      mainHeaderText,
      lightHeaderText,
      bodyText,
      backgroundColor,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div
        css={[styles, addStyle]}
        className="image-left-and-text-right-component"
      >
        <ContentOuterBound>
          <ContentSpacer>
            <ImageLeftAndTextRightMolecule
              image={image}
              mainHeaderText={mainHeaderText}
              lightHeaderText={lightHeaderText}
              bodyText={bodyText}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageLeftAndTextRightComponent;
