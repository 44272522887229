import { IDepdendencyInput } from '../../constants/reporterServiceInputs';

export default class DependencyInput implements IDepdendencyInput {
  dependencyTypeName: string;

  target?: string;

  name: string;

  data: string;

  startTime: Date;

  duration: number;

  resultCode: string | number;

  success: boolean;

  properties?: { [key: string]: any };

  constructor(
    dependencyTypeName: string,
    name: string,
    data: string,
    startTime: Date,
    duration: number,
    success: boolean,
    resultcode: string | number,
    target?: string,
    properties?: object,
  ) {
    this.dependencyTypeName = dependencyTypeName;
    this.name = name;
    this.data = data;
    this.startTime = startTime;
    this.duration = duration;
    this.success = success;
    this.resultCode = resultcode;
    if (target) this.target = target;
    if (properties) this.properties = properties;
  }
}
