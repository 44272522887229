import CaseHeaderComponent from '../../components/CaseHeader';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CaseHeader extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    return (
      <CaseHeaderComponent
        headerText={block?.header?.main}
        lightHeaderText={block?.header?.light}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CaseHeader;
