import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ImageTextMolecule from './common/molecules/ImageTexts';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '57px',
    paddingBottom: '57px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '77px',
    paddingBottom: '77px',
  },
};
type ImageTextsProps = {
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  mainHeaderText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  backgroundColor?: TypeBackgroundColorTypes;
};

class ImageTexts extends Component<ImageTextsProps> {
  static defaultProps = {
    titleText: null,
    bodyText: null,
    mainHeaderText: null,
    lightHeaderText: null,
    image: null,
    backgroundColor: null,
  };

  render() {
    const {
      mainHeaderText,
      lightHeaderText,
      titleText,
      bodyText,
      image,
      backgroundColor,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]} className="image-text-component">
        <ContentOuterBound>
          <ContentSpacer>
            <ImageTextMolecule
              header={mainHeaderText}
              lightHeader={lightHeaderText}
              titleText={titleText}
              bodyText={bodyText}
              image={image}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageTexts;
