import { Component } from 'react';

import Teaser from './Teaser';
import GridEvenSplit from '../atoms/GridEvenSplit';
import getRows from '../../../utils/RowCasesAndEvents';

import { getRtOneParagraph } from '../../../utils/content/RichTextConverter';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .cell': {
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
      width: '100%',
      flex: '0 0 100%',
      '& .case-teaser': {
        marginRight: 0,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  '& .image': {
    width: '100%',
  },
};

type TeaserItem = {
  image?: InternalPropTypes.Image;
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
};

type TeaserGridProps = {
  items?: TeaserItem[];
  showNumbering?: boolean;
};

function formatEventAsTeaserItem(event: InternalPropTypes.Event) {
  return {
    image: event.image,
    header: event.title ? getRtOneParagraph(event.title) : null,
    bodyText: event.teaser,
    link: event.onlineLocation,
  };
}

export function formatEventArrayAsTeaserItemArray(
  events: InternalPropTypes.Event[],
) {
  return events.map((event) => formatEventAsTeaserItem(event));
}

function formatMediaItemAsTeaserItem(mediaItem: InternalPropTypes.MediaItem) {
  return {
    image: mediaItem.image,
    header: mediaItem.header,
    lightHeader: mediaItem.lightHeader,
    bodyText: mediaItem.bodyText,
    link: mediaItem.link,
  };
}

export function formatMediaItemArrayAsTeaserItemArray(
  mediaItems: InternalPropTypes.MediaItem[],
) {
  return mediaItems.map((mediaItem) => formatMediaItemAsTeaserItem(mediaItem));
}

class TeaserGrid extends Component<TeaserGridProps> {
  static defaultProps = {
    items: [],
    showNumbering: false,
  };

  render() {
    const { items, showNumbering } = this.props;
    const rows = getRows(items, 2);
    return (
      <div css={styles}>
        {rows.map((row, index) => (
          <GridEvenSplit
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onRenderCells={[
              () => (
                <Teaser
                  image={row[0].image}
                  title={row[0].header}
                  text={row[0].bodyText}
                  link={row[0].link}
                  lightHeader={row[0].lightHeader}
                  number={2 * index + 1}
                  showNumbering={showNumbering}
                  styling={{
                    paddingRight: row[0].padding,
                    marginRight: row[0].margin,
                    height: row[0].tabletHeight,
                  }}
                />
              ),
              () =>
                row[1] ? (
                  <Teaser
                    image={row[1].image}
                    title={row[1].header}
                    text={row[1].bodyText}
                    lightHeader={row[1].lightHeader}
                    link={row[1].link}
                    number={2 * index + 2}
                    showNumbering={showNumbering}
                    styling={{
                      paddingLeft: row[1].padding,
                      marginLeft: row[1].margin,
                      height: row[1].tabletHeight,
                    }}
                  />
                ) : null,
            ]}
          />
        ))}
      </div>
    );
  }
}

export default TeaserGrid;
