const Positions = {
  UPPERLEFT: 'Upper left',
  UP: 'Up',
  UPPERRIGHT: 'Upper right',
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
  LOWERLEFT: 'Lower left',
  DOWN: 'Down',
  LOWERRIGHT: 'Lower right',
};

module.exports = Positions;
