import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from './BodyText';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';
import RichTextWrapper from '../RichTextWrapper';

import * as InternalPropTypes from '../../../constants/internal-types';

const style = css({
  '& .sub-header-field': {
    opacity: 0.3,
  },
  '& .body-text-field': {
    marginTop: '10px',
    opacity: 0.5,
    whiteSpace: 'break-spaces',
  },
});

type FooterSnippetProps = {
  finePrint?: InternalPropTypes.MediaItem;
  className?: string;
};

class FooterSnippet extends Component<FooterSnippetProps> {
  static defaultProps = {
    finePrint: null,
    className: null,
  };

  render() {
    const { finePrint, className } = this.props;

    return (
      <div css={style} className={className}>
        <BodyText type={BodyText.types.LIGHT14} className="sub-header-field">
          {finePrint && (
            <RichTextWrapper
              richText={finePrint.header}
              renderFunction={RichTextRenderer.regularTextOnly}
            />
          )}
        </BodyText>
        <BodyText type={BodyText.types.LIGHT12} className="body-text-field">
          {finePrint && (
            <RichTextWrapper
              richText={finePrint.bodyText}
              renderFunction={(richText, errorReporter) =>
                RichTextRenderer.regularTextWithLinks(
                  richText,
                  errorReporter,
                  finePrint.openInNewWindowOrTab,
                )
              }
            />
          )}
        </BodyText>
      </div>
    );
  }
}

export default FooterSnippet;
