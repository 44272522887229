const EmbeddedEntries = [
  'CueCardGridBlock',
  'FactBoxBlock',
  'QuoteBlock',
  'VimeoEmbedding',
  'WellFullSizeBlock',
  'WellGridBlock',
];

export default EmbeddedEntries;
