import { Component } from 'react';
import { css } from '@emotion/react';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Image from '../atoms/Image';
import SectionTop from './SectionTop';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';
import { BodyTextWidthEnum } from '../../../constants/cms-constants';

const styles = css({
  '& .inner-grid': {
    '& .cell': {
      maxWidth: '100%',
    },
    '& .caption': {
      [Breakpoints.Mobile.mq]: {
        marginTop: '70px',
      },
    },
  },
  '& .right-cell': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '& .image': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

type ImageLeftAndTextRightMoleculeProps = {
  bodyText?: InternalPropTypes.RichText;
  mainHeaderText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
};

class ImageLeftAndTextRightMolecule extends Component<ImageLeftAndTextRightMoleculeProps> {
  static defaultProps = {
    bodyText: null,
    mainHeaderText: null,
    lightHeaderText: null,
    image: null,
  };

  render() {
    const { image, mainHeaderText, lightHeaderText, bodyText } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743' },
      [ScreenSize.Tablet]: { w: '376' },
      [ScreenSize.Desktop]: { w: '417' },
    };

    return (
      <div css={styles}>
        <Grid2CellUnevenSplit
          leftCellCount={5}
          rightCellCount={5}
          snapAtBreakpoint={Breakpoints.MobileDown}
          onRenderCell1={() => (
            <Image className="image" image={image} sizes={sizes} />
          )}
          onRenderCell2={() => (
            <div className="inner-grid">
              <SectionTop
                lightHeaderText={lightHeaderText}
                headerText={mainHeaderText}
                bodyText={bodyText}
                bodyTextWidth={BodyTextWidthEnum.LONG}
              />
            </div>
          )}
        />
      </div>
    );
  }
}

export default ImageLeftAndTextRightMolecule;
