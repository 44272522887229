// eslint-disable-next-line max-classes-per-file
import ErrorCollector from './ErrorCollector';
import * as ContentErrors from '../../client-server-utils/errors/ContentErrors';
import * as InternalProptypes from '../../constants/internal-types';

type BlockPair = {
  type: string;
  content: InternalProptypes.BlockContentEntry['content'];
};

export class BlockContentSet extends ErrorCollector {
  content: Array<BlockPair> = [];

  usedContent: Array<InternalProptypes.BlockContentEntry['content']> = [];

  locale: string;

  constructor(content: Array<InternalProptypes.BlockContentEntry>) {
    super();
    content.forEach(
      (c) => !!c?.cmsType && this.registerContent(c?.cmsType, c?.content),
      this,
    );
  }

  registerContent(
    contentType: string,
    mappedContent: InternalProptypes.BlockContentEntry['content'],
  ) {
    this.content.push({
      type: contentType,
      content: mappedContent,
    });
  }

  registerLocale(locale: string) {
    this.locale = locale;
  }

  doQuery(func) {
    this.usedContent = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    this._resetAndRun(() => func(me));
    this.warnIfExcessiveContent();
  }

  _filterContent(filter) {
    return this.content.filter((c) => filter(c.type, c.content));
  }

  getFirstOfType(contentType, required = true) {
    const result = this._filterContent(
      // eslint-disable-next-line no-unused-vars
      (type, content) => type === contentType,
    ).map((c) => c.content);
    const first = result[0];
    if (required && typeof first === 'undefined') {
      this._reportError(
        new ContentErrors.ContentQueryError(contentType, 'getFirstOfType'),
      );
    }
    this.usedContent.push(first);
    return first;
  }

  getAllOfType(contentType, required = true) {
    const result = this._filterContent(
      // eslint-disable-next-line no-unused-vars
      (type, content) => type === contentType,
    ).map((c) => c.content);
    if (required && result.length === 0) {
      this._reportError(
        new ContentErrors.ContentQueryError(contentType, 'getAllOfType'),
      );
    }
    result.map((c) => this.usedContent.push(c));
    return result;
  }

  getFixedAmountOfType(contentType, numberOf, required = true) {
    let result: Array<InternalProptypes.BlockContentEntry['content']> = [];
    if (numberOf > 0) {
      result = this.getAllOfType(contentType, false);
    }
    if (required && result.length < numberOf) {
      this._reportError(
        new ContentErrors.ContentQueryError(
          contentType,
          'getFixedAmountOfType',
        ),
      );
    }
    if (result.length > numberOf) {
      this._reportError(
        new ContentErrors.ExcessiveContentWarning(
          contentType,
          numberOf,
          result.length,
          'getFixedAmountOfType',
        ),
      );
    }
    return result;
  }

  warnIfExcessiveContent() {
    const allContent = this.content.map((c) => c.content);
    const hasUnusedContent =
      allContent.filter((c) => !this.usedContent.includes(c)).length > 0;
    if (hasUnusedContent) {
      this._reportError(new ContentErrors.ExcessiveContentWarning());
    }
  }
}

export default BlockContentSet;
