import { Component } from 'react';

import * as Breakpoints from '../../../constants/breakpoints';

const useStyles = {
  maxWidth: `${Breakpoints.DesktopBounded.narrowestWidthInPx}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
};

type ContentOuterBoundProps = {
  children?: React.ReactNode;
};

class ContentOuterBound extends Component<ContentOuterBoundProps> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;
    return (
      <div css={useStyles} className="content-outer-bound">
        {children}
      </div>
    );
  }
}

export default ContentOuterBound;
