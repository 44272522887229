import React, { Component } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';

import RichTextWrapper from '../RichTextWrapper';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
// eslint-disable-next-line import/no-cycle

import { colorEnum, defaultColors } from '../../../constants/colors';
import font from '../../../constants/fonts';

const useStyles = css({
  padding: '0',
  margin: '0',
  color: defaultColors.header,
  '&.values': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '60px',
      letterSpacing: '-1.19px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '100px',
      letterSpacing: '-1.98px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '120px',
      letterSpacing: '-2.37px',
    },
  },
  '&.key-figure': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '36px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '44px',
      lineHeight: '38px',
    },
  },
  '&.page': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '48px',
      letterSpacing: '-0.92px',
      lineHeight: '48px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '70px',
      letterSpacing: '-1.35px',
      lineHeight: '72px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '80px',
      letterSpacing: '-1.54px',
      lineHeight: '80px',
    },
  },
  '&.hero': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '40px',
      letterSpacing: '-0.92px',
      lineHeight: '40px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '64px',
      letterSpacing: '-1.2px',
      lineHeight: '64px',
    },
  },
  '&.partner': {
    ...font.GNBook,
    fontSize: '32px',
    letterSpacing: '-0.27px',
    lightHeight: '32px',
  },
  '&.name-base': {
    ...font.GNBook,
    lightHeight: '32px',
    letterSpacing: '-0.15px',
    [Breakpoints.Mobile.mq]: {
      fontSize: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '24px',
    },
  },
  '&.article': {
    ...font.GNBold,
    fontSize: '32px',
    lineHeight: '34px',
  },
  '&.big-text-box-h2': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '28px',
      lineHeight: '30px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '36px',
      lineHeight: '34px',
    },
  },
  '&.text-box-h2': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '24px',
      lineHeight: '48px',
    },
  },
  '&.text-box-h3': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '21px',
      lineHeight: '25px',
    },
  },
  '&.text-box-h4': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
});

export enum HeaderEnum {
  HERO = 'hero',
  PAGE = 'page',
  PARTNER = 'partner',
  VALUES = 'values',
  KEYFIGURE = 'key-figure',
  NAMEBASE = 'name-base',
  ARTICLE = 'article',
  BIGTEXTBOXH2 = 'big-text-box-h2',
  TEXTBOXH2 = 'text-box-h2',
  TEXTBOXH3 = 'text-box-h3',
  TEXTBOXH4 = 'text-box-h4',
}

const typeToTag = (type: string) => {
  switch (type) {
    case HeaderEnum.KEYFIGURE:
      return 'p';
    case HeaderEnum.BIGTEXTBOXH2:
      return 'h2';
    case HeaderEnum.TEXTBOXH2:
      return 'h2';
    case HeaderEnum.TEXTBOXH3:
      return 'h3';
    case HeaderEnum.TEXTBOXH4:
      return 'h4';
    case HeaderEnum.VALUES:
      return 'h2';
    default:
      return 'h1';
  }
};

const getSEOTag = (type: string, seoWeight?: React.ElementType) => {
  if (!seoWeight) {
    return typeToTag(type);
  }
  return seoWeight;
};

type HeaderProps = {
  type?: `${HeaderEnum}`;
  className?: string;
  css?: Interpolation<Theme>;
  children?: InternalPropTypes.StringOrRichTextOrElementOrNestedArrays;
  seoWeight?: React.ElementType;
};

class Header extends Component<HeaderProps> {
  static types = HeaderEnum;

  static defaultProps = {
    type: HeaderEnum.PAGE,
    className: undefined,
    css: null,
    children: null,
    seoWeight: null,
  };

  render() {
    const { children, type, css, className, seoWeight } = this.props;

    let output: React.ReactNode;
    if (InternalPropTypes.isRichText(children)) {
      output = (
        <RichTextWrapper
          richText={children as InternalPropTypes.RichText}
          renderFunction={RichTextRenderer.formattedTextOnly}
        />
      );
    } else {
      output = children as InternalPropTypes.NestedArraysWithElementOrString;
    }
    const Tag = getSEOTag(type, seoWeight);
    const addStyles = css || {};
    return (
      <Tag
        css={[useStyles, addStyles]}
        className={`header-text ${type}${className ? ` ${className}` : ''}`}
      >
        {output}
      </Tag>
    );
  }
}

export default Header;
