import { Component } from 'react';

import * as InternalPropTypes from '../constants/internal-types';
import { getStringFromRichText } from '../utils/content/RichTextConverter';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import HtmlParserAtom from './common/atoms/HtmlParser';

type HtmlParserProps = {
  content: InternalPropTypes.RichText;
  backgroundColor?: string;
};

class HtmlParser extends Component<HtmlParserProps> {
  static defaultProps = {
    backgroundColor: null,
  };

  render() {
    const { content, backgroundColor } = this.props;
    const contentString = getStringFromRichText(content);
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="interpreted-html-component" css={addStyle}>
        <ContentOuterBound>
          <ContentSpacer>
            <HtmlParserAtom content={contentString} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default HtmlParser;
