import { getLanguageIsoFromUrl } from '../utils/url';

export default {
  name: 'PathLanguageDetector',

  lookup(req) {
    const pathname =
      typeof window === 'undefined' ? req.url : window.location.pathname;
    return getLanguageIsoFromUrl(pathname);
  },
};
