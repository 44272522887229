import { Component, ReactNode } from 'react';
import { css } from '@emotion/react';

import * as Breakpoints from '../../../constants/breakpoints';

const styles = css({
  display: 'flex',
  flexDirection: 'row',
  '& .media-cell': {
    width: '64px',
    height: '64px',
  },
  '& .content-cell': {
    flexGrow: 1,
  },
  [Breakpoints.Mobile.mq]: {
    '&.mobile-snap': {
      flexDirection: 'column',
    },
  },
  [Breakpoints.TabletDown.mq]: {
    '&.tablet-snap': {
      flexDirection: 'column',
    },
  },
});

type Grid2CellMediaProps = {
  onRenderMediaCell?: (...args: any[]) => ReactNode;
  onRenderContentCell?: (...args: any[]) => ReactNode;
  snapAtBreakpoint?: Breakpoints.BreakpointType;
};

class Grid2CellMedia extends Component<Grid2CellMediaProps> {
  static defaultProps = {
    onRenderMediaCell: () => null,
    onRenderContentCell: () => null,
    snapAtBreakpoint: Breakpoints.Tablet,
  };

  render() {
    const { onRenderMediaCell, onRenderContentCell, snapAtBreakpoint } =
      this.props;

    let snapClass = '';
    if (snapAtBreakpoint === Breakpoints.Tablet) {
      snapClass = ' tablet-snap';
    } else if (snapAtBreakpoint === Breakpoints.Mobile) {
      snapClass = ' mobile-snap';
    }

    return (
      <div css={styles} className={`grid-2-cell-media${snapClass}`}>
        <div className="media-cell">{onRenderMediaCell()}</div>
        <div className="content-cell">{onRenderContentCell()}</div>
      </div>
    );
  }
}

export default Grid2CellMedia;
