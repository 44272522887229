import { RichText } from '../../constants/internal-types';

export const getStringFromRichText = (richText: RichText) => {
  let plain = '';
  if (richText != null) {
    // eslint-disable-next-line no-return-assign
    richText.content.map((paragraph) => (plain += paragraph.content[0].value));
  }
  return plain;
};

export const getRtOneParagraph: (text) => RichText = (text) => ({
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: text,
          nodeType: 'text',
        },
      ],
      nodeType: 'paragraph',
    },
  ],
  nodeType: 'document',
});

export function getRtMultipleParagraphs(texts) {
  const paragraphs = texts.map((text) => ({
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value: `${text}\n`,
        nodeType: 'text',
      },
    ],
    nodeType: 'paragraph',
  }));

  return {
    data: {},
    content: paragraphs,
    nodeType: 'document',
  };
}
