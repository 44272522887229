import { Component } from 'react';
import 'core-js/es/promise';
import { Subscription } from 'rxjs';
import { LoadResult } from '../client-server-utils/loading/LoadingManager';
import { WithLoadingContextProps } from '../utils/hocs/withLoadingContext';

/**
 A purely abstract object for listening to the "load content from contentful as first thing" condition.

 Components that inherit from this should implement both the onDataLoaded method which decides if the extending class
 wishes to map, which method it wants to use and what it wants to store of the data, and the onInitialCacheLoad method
 which returns the cache on initial load.
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export type LoadingListenerProps = WithLoadingContextProps & {};

export type LoadingListenerState = {
  subscription: Subscription | null;
};

class LoadingListener<
  P extends LoadingListenerProps,
  S extends LoadingListenerState,
> extends Component<P, S> {
  constructor(props: LoadingListenerProps) {
    super(props as P);
    this.onLoadResult = this.onLoadResult.bind(this);
  }

  componentDidMount() {
    const { loadingManager } = this.props;
    if (!this.state.subscription) {
      this.setState({
        subscription: loadingManager.subscribeToLoadsOnCache(this.onLoadResult),
      });
    }
  }

  componentWillUnmount() {
    const { loadingManager } = this.props;
    if (this.state.subscription) {
      loadingManager.unsubscribeToLoadsOnCache(this.state.subscription);
      this.setState({ subscription: null });
    }
  }

  // An abstract method that triggers whenever the cache is called and receives the new cache
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  onLoadResult(data: LoadResult): void {}
}

export default LoadingListener;
