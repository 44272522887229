import LoadingComponent, {
  LoadingComponentProps,
  LoadingComponentState,
} from './LoadingComponent';
import { AppConfigType } from '../client-server-utils/appConfig';
import AppSettings from '../client-server-utils/AppSettings';

const appSettingsToUniqueString = (appSettings: AppSettings) => {
  const {
    contentful: { spaceId, environment, isPreview },
    language: { currentIso },
  } = appSettings;
  return `${spaceId}-${environment}-${isPreview ?? ''}-${currentIso}`;
};

export type ContentfulLoadingComponentProps = LoadingComponentProps & {
  appConfig: AppConfigType;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ContentfulLoadingComponentState = LoadingComponentState & {};

export default class ContentfulLoadingComponent<
  P extends ContentfulLoadingComponentProps,
  S extends ContentfulLoadingComponentState,
> extends LoadingComponent<P, S> {
  getComponentCacheKey() {
    const { appConfig } = this.props;
    return (
      this.getTypeAndEntryId() + appSettingsToUniqueString(appConfig.settings)
    );
  }

  getTypeAndEntryId() {
    throw new Error(
      `Component ${this.constructor.name} inherits from ContentfulLoadingComponent and must override method getTypeAndEntryId`,
    );
  }
}
