type OEmbedEntry = {
  urlPrefix: string;
  type: string;
};

enum OEmbedTypes {
  Vimeo = 'vimeo',
  Soundcloud = 'soundcloud',
  Youtube = 'youtube',
}

const validOEmbedEntries: { [key: string]: OEmbedEntry } = {
  'vimeo.com': {
    urlPrefix: 'https://vimeo.com/api/oembed.json?url=',
    type: OEmbedTypes.Vimeo,
  },
  'soundcloud.com': {
    urlPrefix: 'https://soundcloud.com/oembed?url=',
    type: OEmbedTypes.Soundcloud,
  },
  'youtube.com': {
    urlPrefix: 'https://youtube.com/oembed?url=',
    type: OEmbedTypes.Youtube,
  },
};

const getValidatedOEmbedEntry = (contentURL: string) => {
  const domain: string = new URL(contentURL).hostname.replace('www.', '');
  return validOEmbedEntries[domain];
};

export default getValidatedOEmbedEntry;
