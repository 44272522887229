import { Component } from 'react';

import RichTextWrapper from '../RichTextWrapper';

import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as InternalPropTypes from '../../../constants/internal-types';

type InlinedRichTextProps = {
  richText?: InternalPropTypes.RichText;
  className?: string;
  as?: React.ElementType;
  openInNewWindowOrTab?: boolean;
};

class InlinedRichText extends Component<InlinedRichTextProps> {
  static defaultProps = {
    richText: null,
    className: null,
    as: 'span',
    openInNewWindowOrTab: false,
  };

  // eslint-disable-next-line consistent-return
  render() {
    const { richText, className, as, openInNewWindowOrTab } = this.props;

    const Tag = as;

    return (
      <Tag className={className}>
        <RichTextWrapper
          richText={richText}
          renderFunction={(richText, errorReporter) =>
            RichTextRenderer.allowEverything(
              richText,
              errorReporter,
              openInNewWindowOrTab,
            )
          }
        />
      </Tag>
    );
  }
}

export default InlinedRichText;
