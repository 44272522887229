const CallToActionTypes = {
  REGULAR: 'regular',
  FOOTER: 'footer',
  CENTRAL: 'central',
  LEFT_POINTING: 'left-pointing',
  LARGE: 'large',
  LOGO: 'logo',
  SIGNUP_EVENT: 'signup-event',
};

module.exports = CallToActionTypes;
