import { Component } from 'react';
import { css } from '@emotion/react';

import ProfileAuthorMolecule from './common/molecules/ProfileAuthor';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = css({
  [Breakpoints.Mobile.mq]: {
    paddingTop: '32px',
    paddingBottom: '12px',
  },
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '44px',
  },
  '& .author-flexer': {
    display: 'flex',
    flexWrap: 'wrap',
    '& .single-author': {
      margin: '1em',
      marginLeft: 0,
      '&+.single-author': {
        marginLeft: '1em',
      },
    },
  },
});

type ProfileAuthorProps = {
  persons?: InternalPropTypes.Person[];
  backgroundColor?: TypeBackgroundColorTypes;
};

class ProfileAuthor extends Component<ProfileAuthorProps> {
  static defaultProps = {
    persons: null,
    backgroundColor: null,
  };

  render() {
    const { persons, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]} className="profile-author-component">
        <ContentOuterBound>
          <ContentSpacer>
            <div className="author-flexer">
              {persons.map((person, idx) => (
                <ProfileAuthorMolecule
                  // eslint-disable-next-line react/no-array-index-key
                  key={`key-${idx}`}
                  className="single-author"
                  {...person}
                />
              ))}
            </div>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ProfileAuthor;
