import React, { Component } from 'react';
import { css } from '@emotion/react';

import Image from '../atoms/Image';
import Title from '../atoms/Title';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import NwLink from '../NwLink';
import TitleTypes from '../../../constants/title-types';
import BodyText from '../atoms/BodyText';
import { getDateStringDayMonthYear } from '../../../utils/content/Events';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .promo-image': {
    marginTop: '20px',
    marginBottom: '20px',
    overflow: 'hidden',
  },
  '& :hover': {
    textDecoration: 'none',
    '& .section': {
      textDecoration: 'underline',
    },
    '& .image': {
      transform: 'scale(1.05)',
    },
  },
  '& .image': {
    transition: 'all 200ms linear',
    display: 'block',
    objectFit: 'cover',
    height: '349px',
    width: '100%',
    [Breakpoints.Mobile.mq]: {
      height: '240px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '268px',
    },
  },
  '& .section': {
    marginTop: '20px',
    marginBottom: '0px',
    fontSize: '14px',
    [Breakpoints.Tablet.mq]: {
      marginBottom: '20px',
      fontSize: '30px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '40px',
      fontSize: '30px',
    },
  },
  '& .quote-block': {
    fontWeight: '300',
    lineHeight: '46px',
  },
});

type EventUpComingProps = {
  title?: string;
  date?: number;
  geoLocation?: string;
  image?: InternalPropTypes.Image;
  onlineLocation?: InternalPropTypes.Link;
  language?: InternalPropTypes.LanguageName;
};

class EventUpComing extends Component<EventUpComingProps> {
  static defaultProps = {
    title: '',
    date: null,
    geoLocation: '',
    image: null,
    onlineLocation: { linkTo: '' },
    language: null,
  };

  render() {
    const { title, date, geoLocation, image, onlineLocation, language } =
      this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743', h: '240' },
      [ScreenSize.Tablet]: { w: '527', h: '268' },
      [ScreenSize.Desktop]: { w: '583', h: '349' },
    };

    return (
      <div css={styles}>
        <NwLink
          to={onlineLocation.linkTo}
          aria-label={title}
          openInNewWindowOrTab={onlineLocation.openInNewWindowOrTab}
        >
          <Grid2CellUnevenSplit
            leftCellCount={4}
            rightCellCount={7}
            snapAtBreakpoint={Breakpoints.MobileDown}
            onRenderCell1={() => (
              <>
                <Title seoWeight="p" type={TitleTypes.SECTION}>
                  {title}
                </Title>

                <BodyText type={BodyText.types.QUOTEBLOCK}>
                  {getDateStringDayMonthYear(date, language)}
                </BodyText>
                <BodyText type={BodyText.types.QUOTEBLOCK}>
                  {geoLocation}
                </BodyText>
              </>
            )}
            onRenderCell2={() => (
              <div className="promo-image">
                {image && (
                  <Image className="image" image={image} sizes={sizes} />
                )}
              </div>
            )}
          />
        </NwLink>
      </div>
    );
  }
}

export default EventUpComing;
