import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum, ProfileEnum } from '../../constants/cms-constants';
import Profile from '../../components/Profile';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';
import fontColors from '../../constants/fontColors';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'fontColor',
    definition: controlOptionDefinitions.fontColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.profileTypes,
  },
])
class ProfileBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static profileGridTypes = Profile.types;

  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const fontColor = fontColors[controlOptions?.fontColor];
    const type = ProfileEnum[controlOptions?.type];

    let person;
    blockContent.doQuery((content) => {
      person = content.getFirstOfType(ContentTypeEnum.PERSON);
    });

    return (
      <Profile
        person={person}
        link={block?.link}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        type={type}
        header={block?.header?.main}
        bodyText={block?.bodyText}
      />
    );
  }
}

export default ProfileBlock;
