// eslint-disable-next-line import/prefer-default-export
const font = {
  GNBlack: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '800',
  },
  GNBold: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  GNMedium: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  GNBook: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  GNLight: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '300',
  },
  GNThin: {
    fontFamily: 'Gotham Narrow A, Gotham Narrow B',
    fontStyle: 'normal',
    fontWeight: '100',
  },
} as const;

export default font;
