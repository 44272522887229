import { Component } from 'react';
import { css } from '@emotion/react';

import * as InternalPropTypes from '../../../constants/internal-types';

const styles = css({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
});

type VideoProps = {
  sources?: InternalPropTypes.VideoFile[];
  muted?: boolean;
  controls?: boolean;
  loop?: boolean;
  posterUrl?: string;
  preload?: boolean;
  height?: number;
  width?: number;
  className?: string;
  autoplay?: boolean;
  ariaLabel?: string;
};

class Video extends Component<VideoProps> {
  static defaultProps = {
    sources: [],
    muted: true,
    loop: true,
    controls: false,
    posterUrl: null,
    preload: true,
    height: null,
    width: null,
    className: undefined,
    autoplay: true,
    ariaLabel: 'Video',
  };

  render() {
    const {
      sources,
      muted,
      // eslint-disable-next-line no-unused-vars
      controls,
      loop,
      posterUrl,
      preload,
      height,
      width,
      className,
      autoplay,
      ariaLabel,
    } = this.props;

    return (
      <div css={styles} className={className}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          preload={preload ? 'auto' : 'none'}
          muted={muted}
          loop={loop}
          height={height}
          width={width}
          poster={posterUrl}
          autoPlay={autoplay}
          aria-label={ariaLabel}
          playsInline
          controls={controls}
        >
          {sources.map((s) => (
            <source key={`src-${s.url}`} src={s.url} />
          ))}
        </video>
      </div>
    );
  }
}

export default Video;
