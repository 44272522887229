import { Component } from 'react';
import { css } from '@emotion/react';

import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Image from '../atoms/Image';
import RichTextWrapper from '../RichTextWrapper';

import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .image': {
    objectFit: 'cover',
    [Breakpoints.Mobile.mq]: {
      width: '100%',
      height: '240px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '268px',
      width: '102%',
      marginBottom: '104px',
    },
    [Breakpoints.DesktopUp.mq]: {
      height: '349px',
      width: '105%',
      marginBottom: '104px',
    },
  },
  '& .text': {
    [Breakpoints.Mobile.mq]: {
      height: '240px',
      paddingTop: '16px',
      paddingBottom: '24px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '268px',
    },
    [Breakpoints.DesktopUp.mq]: {
      height: '349px',
    },
    [Breakpoints.TabletUp.mq]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    '& .top-header': {
      [Breakpoints.TabletUp.mq]: {
        width: '175px',
      },
    },
    '& .light-header': {
      [Breakpoints.Mobile.mq]: {
        position: 'absolute',
        top: '16px',
        right: '0',
      },
      [Breakpoints.TabletUp.mq]: {
        width: '175px',
      },
    },
    '& .header': {
      lineHeight: 0.9,
      width: '175px',
      [Breakpoints.TabletUp.mq]: {
        direction: 'rtl',
      },
    },
    '& .main-text': {
      [Breakpoints.Mobile.mq]: {
        width: '60%',
        paddingTop: '16px',
      },
      [Breakpoints.TabletUp.mq]: {
        width: '175px',
      },
      [Breakpoints.DesktopUp.mq]: {
        paddingTop: '40px',
      },
    },
  },
});

type CaseHeroProps = {
  image?: InternalPropTypes.Image;
  caseText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
};

class CaseHero extends Component<CaseHeroProps> {
  static defaultProps = {
    image: null,
    caseText: null,
    lightHeaderText: null,
    headerText: null,
    bodyText: null,
  };

  render() {
    const { image, lightHeaderText, caseText, headerText, bodyText } =
      this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743', h: '240' },
      [ScreenSize.Tablet]: { w: '537', h: '268' },
      [ScreenSize.Desktop]: { w: '612', h: '349' },
    };

    return (
      <div css={styles}>
        <Grid2CellUnevenSplit
          leftCellCount={7}
          rightCellCount={5}
          onRenderCell1={() => (
            <Image className="image" image={image} sizes={sizes} />
          )}
          onRenderCell2={() => (
            <div className="text">
              <BodyText className="top-header" type={BodyText.types.BOLD14}>
                {lightHeaderText}
              </BodyText>
              <BodyText className="light-header" type={BodyText.types.LIGHT12}>
                {caseText}
              </BodyText>
              <Header
                seoWeight="p"
                className="header"
                type={Header.types.VALUES}
              >
                {headerText}
              </Header>
              <BodyText className="main-text" type={BodyText.types.LIGHT14}>
                <RichTextWrapper
                  richText={bodyText}
                  renderFunction={
                    RichTextRenderer.regularTextOnlyWithNoBreakLine
                  }
                />
              </BodyText>
            </div>
          )}
          snapAtBreakpoint={Breakpoints.Mobile}
        />
      </div>
    );
  }
}

export default CaseHero;
