import { Component } from 'react';

import { colorEnum } from '../../../constants/colors';

const useStyles = {
  display: 'inline-block',
  cursor: 'pointer',
  zIndex: '100',
  outlineStyle: 'none',
  userSelect: 'none',
  padding: '18px 14px 14px 14px',
  // padding: '10px',
  '& .bar': {
    width: '16px',
    height: '1px',
  },
  '& .flipBar': {
    margin: '0 0',
    backgroundColor: colorEnum.black,
    outline: '1px solid transparent', // triggers antialising
    willChange: 'transform',
    transition: 'transform 150ms linear',
  },
  '& .barFlipDown': {
    WebkitTransform: 'rotate(-45deg) translate(-3px, 3px)',
    transform: 'rotate(-45deg) translate(-3px, 3px)',
    WebkitBackfaceVisibility: 'hidden',
  },
  '& .barFlipUp': {
    WebkitTransform: 'rotate(45deg) translate(-3px, -4px)',
    transform: 'rotate(45deg) translate(-3px, -4px)',
    WebkitBackfaceVisibility: 'hidden',
  },
  '& .invisibleBar': {
    margin: '4px 0 3px 0',
    visibility: 'hidden',
  },
};

type FoldButtonProps = {
  as?: React.ElementType;
  onChange?: (arg0: boolean) => void;
  isOpen?: boolean;
  className?: string;
};

interface IFoldButtonState {
  isOpen: boolean;
}

class FoldButton extends Component<FoldButtonProps, IFoldButtonState> {
  static defaultProps = {
    as: 'div',
    onChange: () => {}, // noop
    isOpen: null,
    className: null,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const newVal = !this._isOn();
    this.props.onChange(newVal);
    this.setState({ isOpen: newVal });
  }

  _isOn() {
    // if parent component sends a boolean variable, this component will be a controlled component.
    // otherwise it keeps state itself and is uncontrolled
    return typeof this.props.isOpen === 'boolean'
      ? this.props.isOpen
      : this.state.isOpen;
  }

  render() {
    const { as, className } = this.props;
    const ButtonElement = as;
    const isOpen = this._isOn();

    return (
      <ButtonElement
        css={useStyles}
        className={`foldbutton${className ? ` ${className}` : ''}`}
        onClick={this.toggle}
        role="button"
        aria-label="button"
        tabIndex="0"
      >
        <div className={`bar flipBar${isOpen ? ' barFlipDown' : ''}`}> </div>
        <div className="bar invisibleBar"> </div>
        <div className={`bar flipBar${isOpen ? ' barFlipUp' : ''}`}> </div>
      </ButtonElement>
    );
  }
}

export default FoldButton;
