import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Grid3CellBroken from './common/molecules/Grid3CellBroken';
import Image from './common/atoms/Image';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import ScreenSize from '../constants/screenSize';
import { SizesType } from '../types/ts/imgixQuery';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '70px',
    paddingBottom: '70px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '35px',
    paddingBottom: '35px',
  },
};
type ImageGrid3CellsProps = {
  images?: InternalPropTypes.Image[];
  backgroundColor?: TypeBackgroundColorTypes;
};

class ImageGrid3Cells extends Component<ImageGrid3CellsProps> {
  static defaultProps = {
    images: [],
    backgroundColor: null,
  };

  render() {
    const { images, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const leftImageSizes: SizesType = {
      [ScreenSize.Mobile]: { w: '297', h: '417' },
      [ScreenSize.Tablet]: { w: '361', h: '507' },
      [ScreenSize.Desktop]: { w: '400', h: '561' },
    };

    const upperRightImageSizes: SizesType = {
      [ScreenSize.Mobile]: { w: '394', h: '265' },
      [ScreenSize.Tablet]: { w: '479', h: '322' },
      [ScreenSize.Desktop]: { w: '530', h: '357' },
    };

    const lowerRightImageSizes: SizesType = {
      [ScreenSize.Mobile]: { w: '267', h: '316' },
      [ScreenSize.Tablet]: { w: '325', h: '384' },
      [ScreenSize.Desktop]: { w: '360', h: '425' },
    };

    return (
      <div className="image-grid-3-cells-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Grid3CellBroken>
              <Image image={images[0]} sizes={leftImageSizes} />
              <Image image={images[1]} sizes={upperRightImageSizes} />
              <Image image={images[2]} sizes={lowerRightImageSizes} />
            </Grid3CellBroken>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageGrid3Cells;
