import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import LogoScrollerMolecule from './common/molecules/LogoScroller';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.TabletDown.mq]: {
    paddingTop: '46px',
    paddingBottom: '46px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '60px',
    paddingBottom: '60px',
  },
};

type LogoScrollerProps = {
  images?: InternalPropTypes.Image[];
  header?: InternalPropTypes.RichText;
  backgroundColor?: TypeBackgroundColorTypes;
};

class LogoScroller extends Component<LogoScrollerProps> {
  static defaultProps = {
    images: [],
    header: null,
    backgroundColor: null,
  };

  render() {
    const { header, images, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <LogoScrollerMolecule content={images} header={header} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default LogoScroller;
