// eslint-disable-next-line class-methods-use-this
import { LANGUAGES } from '../../constants/language';

export const sortEventsByDate = (events) => {
  events.sort((a, b) => {
    const dateA = a.date;
    const dateB = b.date;
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
};

export const getComingEvents = (events) => {
  sortEventsByDate(events);
  const firstPast = events.indexOf(
    events.find((event) => {
      const eventTime = event.date;
      const now = new Date().getTime();
      return eventTime < now;
    }),
  );
  if (firstPast > -1) {
    events.splice(firstPast, events.length - firstPast);
  }
  events.reverse();
};

export const getFirstComingEvent = (events) => {
  getComingEvents(events);
  return events ? events[0] : null;
};

export const getPastEvents = (events) => {
  sortEventsByDate(events);
  const firstPast = events.indexOf(
    events.find((event) => {
      const eventTime = event.date;
      const now = new Date().getTime();
      return eventTime < now;
    }),
  );
  events.splice(0, firstPast);
};

export const getDateStringDayMonthYear = (dateNumber, language) => {
  const dateObject = new Date(dateNumber);
  const locale = LANGUAGES.find((l) => l.name === language)?.iso;
  const dateString = dateObject.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return `${dateString}.`;
};
