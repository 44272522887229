import Driver from './Driver';
import {
  IDepdendencyInput,
  IErrorInput,
  IEventInput,
  IPageViewInput,
  IRequestInput,
} from '../../constants/reporterServiceInputs';
import Consent from '../../utils/Consent';
import { consentEnum } from '../../constants/consentCategories';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}
export default class ClientGoogleAnalyticsDriver extends Driver {
  constructor() {
    super();
    this.activate = this.activate.bind(this);
  }

  activate(consent: Consent): void {
    this.consent = consent;
    if (this.consent[consentEnum.STATISTICAL]) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('consent', 'update', {
          analytics_storage: 'granted',
        });
      } else {
        console.warn(
          'Google Analytics driver not activated: The gtag function is not ' +
            'defined in window. Make sure that the Google Tag Manager driver ' +
            'defines the script in the HTML-header',
        );
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getHeaderString(nonce: string): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getBodyString(): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  logError(error: IErrorInput): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  trackEvent(event: IEventInput): void {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', event.name, event?.properties);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackRequest(request: IRequestInput): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  trackDependency(dependencyInput: IDepdendencyInput): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  trackPageView(pageView: IPageViewInput): void {
    return null;
  }
}
