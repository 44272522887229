import { Component } from 'react';

import ProfileMolecule from './common/molecules/Profile';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  ProfileEnum,
  TypeBackgroundColorTypes,
  TypeFontColorTypes,
  TypeProfileTypes,
} from '../constants/cms-constants';

const styles = {
  '& .profile-component': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '68px',
      paddingBottom: '104px',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '148px',
      paddingBottom: '144px',
    },
  },
  '& .contact-component': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '25px',
      paddingBottom: '45px',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  },
};

type ProfileProps = {
  backgroundColor?: TypeBackgroundColorTypes;
  fontColor?: TypeFontColorTypes;
  link?: InternalPropTypes.Link;
  person?: InternalPropTypes.Person;
  type?: TypeProfileTypes;
  header?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
};

class Profile extends Component<ProfileProps> {
  static types = ProfileEnum;

  static propTypes = {};

  static defaultProps = {
    backgroundColor: null,
    fontColor: null,
    type: this.types.PROFILE,
    link: null,
    person: null,
    header: null,
    bodyText: null,
  };

  render() {
    const { backgroundColor, fontColor, type, link, person, header, bodyText } =
      this.props;
    const addStyles = backgroundColor ? { background: backgroundColor } : {};
    const addFontColor = fontColor
      ? {
          '& .body-text': { color: fontColor },
          '& .header-text': { color: fontColor },
          '& .caption': { color: fontColor },
        }
      : {};
    const headerProp =
      type === ProfileEnum.CONTACT ? header : person.description;
    const bodyTextProp = type === ProfileEnum.CONTACT ? bodyText : person.quote;

    return (
      <div css={[styles, addStyles, addFontColor]}>
        <div className={`${type}-component`}>
          <ContentOuterBound>
            <ContentSpacer>
              <ProfileMolecule
                backLink={link}
                type={type}
                header={headerProp}
                bodyText={bodyTextProp}
                {...person}
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      </div>
    );
  }
}

export default Profile;
