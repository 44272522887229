import { Cookies } from 'react-cookie-consent';
import CookieService from '../client-server-utils/CookieService';

export default class ClientCookieService extends CookieService {
  // eslint-disable-next-line class-methods-use-this
  protected getCookie(cookieName) {
    return Cookies.get(cookieName);
  }

  protected setCookie(name, value, samesite = 'None', secure = false) {
    document.cookie = `${name}=${value};expires=${new Date(
      new Date().getTime() + this.year,
    ).toUTCString()};path=/;SameSite=${samesite}${
      secure || samesite === 'None' ? ';Secure' : ''
    }`;
  }
}
