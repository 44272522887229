import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const style = {
  border: 'none',
  height: '100%',
  width: '100%',
};

type VimeoData = {
  // eslint-disable-next-line camelcase
  author_name: string;
  title: string;
  html: string;
  data: any;
};

type VimeoVideoProps = {
  url: string;
  loadingAltText?: string;
};

interface IVimeoVideoState {
  vimeoData?: VimeoData;
}
class VimeoVideo extends Component<VimeoVideoProps, IVimeoVideoState> {
  static defaultProps = {
    loadingAltText: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      vimeoData: null,
    };
  }

  componentDidMount() {
    const { url } = this.props;
    this.fetchVimeoData(url);
  }

  formattedVimeoFrame(loadingAltText) {
    // minor security check by checking author of the video, could be replaced by creating a vimeo channel or similar
    if (
      this.state.vimeoData &&
      this.state.vimeoData.author_name === 'Novataris'
    ) {
      const frameSrc = this.state.vimeoData.html.match(/src="([^"]+)"/)[1];
      return (
        <iframe
          src={frameSrc}
          style={style}
          title={this.state.vimeoData.title}
          allow="autoplay; fullscreen; picture-in-picture"
        />
      );
    }
    return this.loadingFrame(loadingAltText);
  }

  // eslint-disable-next-line class-methods-use-this
  loadingFrame(loadingAltText) {
    return (
      <div
        style={{
          padding: '25%',
          textAlign: 'center',
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">{loadingAltText}</span>
        </Spinner>
      </div>
    );
  }

  fetchVimeoData(url) {
    if (url && !this.state.vimeoData) {
      const api = `https://vimeo.com/api/oembed.json?url=`;
      const fetchurl = api.concat(url);
      fetch(fetchurl)
        .then((response) => response.json())
        .then((data) => this.setState({ vimeoData: data }));
    }
  }

  render() {
    const { loadingAltText } = this.props;

    return (
      <div className="vimeo-container">
        {this.formattedVimeoFrame(loadingAltText)}
      </div>
    );
  }
}

export default VimeoVideo;
