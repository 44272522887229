// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { Block, ControlOptions } from '../../constants/internal-types';
import { ControlOptionDefinition } from '../../types/ts/controlOptions';

type ControlOption = {
  key: string;
  definition: ControlOptionDefinition;
};

type ConstructorArgs = (null | undefined | { block: Block })[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ControlOption(controlOptions: ControlOption[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function fn<T extends { new (...args: any[]) }>(constructor: T) {
    // @ts-expect-error This works, but fixing the TS error would be nice
    return class extends constructor {
      controlOptions: ControlOptions;

      constructor(...args: ConstructorArgs) {
        super(...args);

        const blockData = args.find((arg) => !!arg.block).block;
        const controlOptionValuesFromCms = blockData.controlOptionsV2 ?? [];

        const controlOptionValues = {};
        controlOptions.forEach((controlOption: ControlOption) => {
          const valueFromCms = controlOptionValuesFromCms[controlOption.key];

          if (!valueFromCms) return;

          if (controlOption.definition.validation?.enum) {
            controlOptionValues[controlOption.key] =
              controlOption.definition.validation.enum[valueFromCms];
          } else {
            controlOptionValues[controlOption.key] = valueFromCms;
          }
        });

        this.controlOptions = controlOptionValues ?? [];
      }
    };
  };
}

export class ComponentWithControlOptions<P> extends React.Component<P> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  controlOptions: ControlOptions;
}
