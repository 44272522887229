import logoBlack from '../assets/logo-black.png';
import { Image, Page } from '../constants/internal-types';
import { ImageFactoryProps } from './hocs/withImageHelperFactory';
import ImgixParameters from './images/imgixParameters';

type ImageMetaData = {
  widthInPx?: number;
  heightInPx?: number;
  aspectRatio?: number;
  sizeInBytes?: number;
};

export function getTwitterImage(page: Page) {
  return page?.featuredImage || page?.defaultTwitterImage;
}

export function getTwitterImageUrl(
  page: Page,
  imageHelperFactory: ImageFactoryProps,
): string {
  return (
    imageHelperFactory
      .createImageHelper(getTwitterImage(page))
      ?.getImageUrl() || logoBlack
  );
}

export function getOpenGraphImage(page: Page): Image {
  // opengraph tags need height and width, so the logo has these hardcoded. Modelled after mapImageFile.
  const logoImg = {
    simpleImage: {
      assetName: 'Logo black',
      url: logoBlack,
      heightInPx: 75,
      widthInPx: 540,
    },
  };

  return page?.featuredImage || page?.defaultOpenGraphImage || logoImg;
}

export function getImageMetaData(
  image: Image,
  imageHelperFactory: ImageFactoryProps,
): ImageMetaData {
  if (!image) return {};

  const imageHelper = imageHelperFactory.createImageHelper(image);

  if (imageHelper.supportsImgix()) {
    const imgixParams = new ImgixParameters({
      imgixQueryString: image.imgixQueryString,
    });
    const { w, h, ar } = imgixParams.getSizeParams();
    return {
      widthInPx: w,
      heightInPx: h,
      aspectRatio: ar,
      sizeInBytes: undefined,
    };
  }

  const size = imageHelper.getImageSize();
  const sizeInBytes = imageHelper.getImageSizeInBytes();
  return {
    ...size,
    sizeInBytes,
  };
}
