import React, { Component, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import useEmblaCarousel from 'embla-carousel-react';

import Image from '../atoms/Image';
import ArrowButton from '../atoms/ArrowButton';
import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import Caption from '../atoms/Caption';
import Title from '../atoms/Title';

import { colorEnum } from '../../../constants/colors';
import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import TitleTypes from '../../../constants/title-types';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .caption': {
    opacity: '0.4',
  },
  '& .hstack-3-cell-extremety': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '48px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '64px',
    },
  },
  '& .image': {
    objectFit: 'scale-down',
    overflow: 'hidden',
    [Breakpoints.Mobile.mq]: {
      height: 'auto',
      width: '100%',
      paddingRight: '35px',
    },
    [Breakpoints.Tablet.mq]: {
      height: 'auto',
      width: '100%',
      paddingRight: '50px',
    },
    [Breakpoints.DesktopUp.mq]: {
      height: 'auto',
      width: '100%',
      maxHeight: '500px',
      paddingRight: '50px',
    },
  },

  '& .buttons': {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    '& button': {
      marginLeft: '8px',
    },
  },

  '.embla__viewport': {
    overflow: 'hidden',
    [Breakpoints.Mobile.mq]: {
      marginBottom: '56px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '64px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '72px',
    },
  },
  '.embla__container': {
    backfaceVisibility: 'hidden',
    display: 'flex',
    alignItems: 'center',
    touchAction: 'pan-y',
  },
  '.embla__slide': {
    flex: '0 0 100%',
    marginRight: '10px',
    minWidth: 0,
    '& .picture': {
      display: 'contents',
    },
  },

  '& .scroll': {
    width: '100%',
    display: 'flex',
    height: '2px',
    backgroundColor: colorEnum.lightGray,
    '& .active': {
      transition: 'margin 400ms',
      height: '2px',
      backgroundColor: colorEnum.black,
    },
  },
});

type ImageCarouselProps = {
  images?: InternalPropTypes.Image[];
  lightHeaderText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
};

type EmblaCarouselProps = {
  lightHeaderText: InternalPropTypes.RichText;
  headerText: InternalPropTypes.RichText;
  images: InternalPropTypes.Image[];
  sizes: SizesType;
  scrollWidth: number;
};

function EmblaCarousel(props: EmblaCarouselProps) {
  // eslint-disable-next-line react/prop-types
  const { lightHeaderText, headerText, images, sizes, scrollWidth } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [scrollProgress, setScrollProgress] = useState(0);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onScroll = useCallback(
    (emblaApi) => {
      const progress = scrollWidth * emblaApi.selectedScrollSnap();
      setScrollProgress(progress);
    },
    [scrollWidth],
  );

  useEffect(() => {
    if (!emblaApi) return;

    onScroll(emblaApi);
    emblaApi.on('reInit', onScroll);
    emblaApi.on('scroll', onScroll);
  }, [emblaApi, onScroll]);

  return (
    <div>
      <Hstack3CellExtremity
        onRenderLeftCell={() => (
          <div>
            <Caption type={Caption.types.SECTION}>{lightHeaderText}</Caption>
            <Title type={TitleTypes.SECTION}>{headerText}</Title>
          </div>
        )}
        onRenderRightCell={() => (
          <div className="buttons">
            <ArrowButton onClick={onPrevButtonClick} direction="left" />
            <ArrowButton onClick={onNextButtonClick} />
          </div>
        )}
      />

      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {
              // eslint-disable-next-line react/prop-types
              images.map((image) => (
                <div className="embla__slide" key={image.contentfulEntryId}>
                  <Image image={image} sizes={sizes} />
                </div>
              ))
            }
          </div>
        </div>
        <div>
          <div className="scroll">
            <div
              className="active"
              style={{
                width: `${scrollWidth}%`,
                marginLeft: `${scrollProgress}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

class ImageCarousel extends Component<ImageCarouselProps> {
  static defaultProps = {
    images: [],
    lightHeaderText: null,
    headerText: null,
  };

  render() {
    const { lightHeaderText, headerText, images } = this.props;
    const scrollWidth = 100 / images.length;
    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '708' },
      [ScreenSize.Tablet]: { w: '855' },
      [ScreenSize.Desktop]: { w: '1000', 'max-h': '500' },
    };

    return (
      <div css={styles}>
        <EmblaCarousel
          lightHeaderText={lightHeaderText}
          headerText={headerText}
          images={images}
          sizes={sizes}
          scrollWidth={scrollWidth}
        />
      </div>
    );
  }
}

export default ImageCarousel;
