import { Component } from 'react';
import { compose } from 'recompose';
import MediaCarousel from './common/molecules/MediaCarousel';
import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import { AppConfigType } from '../client-server-utils/appConfig';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import PageTags from '../constants/cms/control-options/page-tags';

const styles = {
  '&.related-pages-grid-component': {
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '72px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '80px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '64px',
    },
  },
};

function getTagsFromPage(page) {
  const { primaryPageTag, secondaryPageTag } = page.controlOptionsV2 || {};

  return [primaryPageTag, secondaryPageTag];
}

function createMediaItemFromPage(page) {
  const linkImage: InternalPropTypes.Image = {
    ...page.featuredImage,
    alternateText: page.seo.title,
  };

  return {
    image: linkImage,
    header: page.featuredTitle,
    link: { linkTo: page.linkTo },
    createdAt: page.createdAt,
    contentfulEntryId: page.featuredImage.contentfulEntryId,
  };
}

function sortByDate(array) {
  array.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  return array;
}

function isValidRelatedPage(page, currentContentfulId) {
  return (
    page.controlOptionsV2 !== undefined &&
    page.featuredTitle !== undefined &&
    page.featuredImage !== null &&
    page.contentfulEntryId !== currentContentfulId
  );
}

function getRelatedPages(appConfig: AppConfigType, maxItems) {
  if (!appConfig.relatedPagesInfo) {
    return [];
  }

  const { allPages, currentPageId } = appConfig.relatedPagesInfo;

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const currentTags = getTagsFromPage(
    allPages.filter((elm) => elm.contentfulEntryId === currentPageId)[0],
  )
    .filter((tag) => tag !== getKeyByValue(PageTags, PageTags.NONE))
    .sort();

  const doubleMatches = [];
  const singleMatches = [];
  let ptr = 0;
  while (ptr < allPages.length && doubleMatches.length < maxItems) {
    const page = allPages[ptr];

    if (isValidRelatedPage(page, currentPageId)) {
      const otherTags = getTagsFromPage(page).sort();

      if (currentTags.every((v, i) => v === otherTags[i])) {
        doubleMatches.push(createMediaItemFromPage(page));
      } else if (currentTags.some((v) => otherTags.includes(v))) {
        singleMatches.push(createMediaItemFromPage(page));
      }
    }

    ptr += 1;
  }

  return sortByDate(doubleMatches)
    .concat(sortByDate(singleMatches))
    .slice(0, maxItems);
}

type RelatedPagesGridProps = {
  headerText?: InternalPropTypes.RichText;
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
};

type RelatedPagesGridInnerProps = WithAppConfigProps & RelatedPagesGridProps;

class RelatedPagesGridComponent extends Component<RelatedPagesGridInnerProps> {
  static defaultProps = {
    headerText: null,
    mediaItems: null,
    backgroundColor: null,
  };

  render() {
    const { mediaItems, headerText, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    const minItems = 4;
    const maxItems = 8;
    const relatedMediaItems = getRelatedPages(
      this.props.appConfig,
      maxItems,
    ).concat(mediaItems);

    return relatedMediaItems.length >= minItems ? (
      <div className="related-pages-grid-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <MediaCarousel
              headerText={headerText}
              mediaItems={relatedMediaItems}
              backgroundColor={backgroundColor}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    ) : null;
  }
}

export default compose<RelatedPagesGridInnerProps, RelatedPagesGridProps>(
  withAppConfig,
)(RelatedPagesGridComponent);
