import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import List3GroupsMolecule from './common/molecules/List3Groups';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  TypeList3GroupsTypes,
  TypeList3GroupsHeaders,
  TypeBackgroundColorTypes,
} from '../constants/cms-constants';

const styles = {
  '& .list-3-group-component, .list-3-group-component-headers, .list-3-group-component-link':
    {
      [Breakpoints.Mobile.mq]: {
        paddingTop: '41px',
        paddingBottom: '41px',
      },
      [Breakpoints.Tablet.mq]: {
        paddingTop: '53px',
        paddingBottom: '53px',
      },
      [Breakpoints.DesktopUp.mq]: {
        paddingTop: '68px',
        paddingBottom: '68px',
      },
    },
  '& .list-3-group-component-headers .cell + .cell': {
    [Breakpoints.Tablet.mq]: {
      paddingTop: '64px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '32px',
    },
  },
};

type List3GroupsProps = {
  headerText?: InternalPropTypes.RichText;
  mediaItems?: InternalPropTypes.MediaItem[];
  link?: InternalPropTypes.Link;
  type?: TypeList3GroupsTypes;
  header?: TypeList3GroupsHeaders;
  backgroundColor?: TypeBackgroundColorTypes;
};

class List3Groups extends Component<List3GroupsProps> {
  static types = List3GroupsMolecule.types;

  static defaultProps = {
    headerText: null,
    mediaItems: [],
    link: null,
    type: '',
    header: '',
    backgroundColor: null,
  };

  render() {
    const { link, headerText, mediaItems, type, header, backgroundColor } =
      this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]}>
        <div className={`list-3-group-component${type ? `-${type}` : ''}`}>
          <ContentOuterBound>
            <ContentSpacer>
              <List3GroupsMolecule
                headerText={headerText}
                leftHeader={mediaItems[0] ? mediaItems[0].header : null}
                leftBodytext={mediaItems[0] ? mediaItems[0].bodyText : null}
                middleHeader={mediaItems[1] ? mediaItems[1].header : null}
                middleBodytext={mediaItems[1] ? mediaItems[1].bodyText : null}
                rightHeader={mediaItems[2] ? mediaItems[2].header : null}
                rightBodyText={mediaItems[2] ? mediaItems[2].bodyText : null}
                link={link}
                type={type}
                header={header}
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      </div>
    );
  }
}

export default List3Groups;
