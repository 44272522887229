import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import VimeoEmbeddingComponent from '../../components/VimeoEmbedding';
import { getStringFromRichText } from '../../utils/content/RichTextConverter';
import { ContentTypeEnum } from '../../constants/cms-constants';

class VimeoEmbedding extends Component<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;

    let link;
    blockContent.doQuery((content) => {
      link = content.getFirstOfType(ContentTypeEnum.LINK);
    });

    const loadingAltText = getStringFromRichText(block?.header?.light);

    return (
      <VimeoEmbeddingComponent url={link.url} loadingAltText={loadingAltText} />
    );
  }
}

export default VimeoEmbedding;
