import { Component } from 'react';

import Header from '../atoms/Header';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .text': {
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '80%',
    },
  },
  '& .sub-header': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '24px',
    },
    marginTop: '40px',
  },
};

type CaseHeaderProps = {
  headerText?: InternalPropTypes.RichText;
  lightHeaderText?: InternalPropTypes.RichText;
};

class CaseHeader extends Component<CaseHeaderProps> {
  static defaultProps = {
    headerText: null,
    lightHeaderText: null,
  };

  render() {
    const { headerText, lightHeaderText } = this.props;
    return (
      <div css={styles}>
        <Header className="text" type={Header.types.PARTNER}>
          {headerText}
        </Header>
        {lightHeaderText && (
          <Header
            seoWeight="p"
            className="text sub-header"
            type={Header.types.PARTNER}
          >
            {lightHeaderText}
          </Header>
        )}
      </div>
    );
  }
}

export default CaseHeader;
