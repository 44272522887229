import { ReactNode, Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Breakpoints from '../../../constants/breakpoints';
import { assignCellSizes } from '../../../utils/gridBreakpointCalculations';

const GrooveStyle = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '15px',
    marginTop: '15px',
    borderTop: 'groove',
  },
  [Breakpoints.Tablet.mq]: {
    borderLeft: 'groove',
  },
  [Breakpoints.DesktopUp.mq]: {
    borderLeft: 'groove',
  },
};

type Grid2CellUnevenSplitProps = {
  alwaysBreak?: boolean;
  className?: string;
  onRenderCell1?: (...args: unknown[]) => ReactNode;
  onRenderCell2?: (...args: unknown[]) => ReactNode;
  snapAtBreakpoint?: Breakpoints.BreakpointType;
  leftCellCount: number;
  rightCellCount: number;
  hasGroove?: boolean;
};

const style = {
  [Breakpoints.NotTablet]: {
    // because bootstraps breakpoints are not exclusive, we have to disable the offset for tables outside of the tablet breakpoint
    '& .right-cell.offset-md-2.col-lg-12': {
      marginLeft: 0,
    },
  },
};

class Grid2CellUnevenSplit extends Component<Grid2CellUnevenSplitProps> {
  static defaultProps = {
    alwaysBreak: false,
    onRenderCell1: () => null,
    onRenderCell2: () => null,
    snapAtBreakpoint: Breakpoints.Mobile,
    className: undefined,
    hasGroove: false,
  };

  render() {
    const {
      alwaysBreak,
      onRenderCell1,
      onRenderCell2,
      snapAtBreakpoint,
      className,
      leftCellCount,
      rightCellCount,
      hasGroove,
    } = this.props;

    // This takes account for offset if leftCellCount and rightCellCount doesn't equal 12
    const cellSizes = assignCellSizes(
      leftCellCount,
      rightCellCount,
      snapAtBreakpoint,
      alwaysBreak,
    );
    return (
      <Container css={style} className={className} fluid>
        <Row noGutters>
          <Col className="cell left-cell" {...cellSizes.left}>
            {onRenderCell1()}
          </Col>
          <Col
            className="cell right-cell"
            css={hasGroove ? GrooveStyle : ''}
            {...cellSizes.right}
          >
            {onRenderCell2()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Grid2CellUnevenSplit;
