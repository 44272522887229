import React from 'react';
import { compose } from 'recompose';
import * as InternalPropTypes from '../../constants/internal-types';
import withAppConfig, { WithAppConfigProps } from './withAppConfig';
import ImgixImageHelper from './imageHelpers/imgixImageHelper';
import ContentfulImageHelper from './imageHelpers/contentfulImageHelper';
import SimpleImageHelper from './imageHelpers/simpleImageHelper';
import ImageHelper from './imageHelpers/imageHelper';

export type ImageFactoryProps<T extends ImageHelper = ImageHelper> = {
  createImageHelper: (image: InternalPropTypes.Image) => T;
};

export type WithImageHelperFactoryProps<P = unknown> = P & {
  imageHelperFactory: ImageFactoryProps;
};

// OBS: If composed with HOC withAppConfig, then this needs called before withAppConfig. Otherwise, this component will "eat" the appConfig prop
function withImageHelperFactory(Component: React.ElementType) {
  function ImageHelperFactory(props: WithAppConfigProps) {
    const { appConfig, ...otherProps } = props;

    const createImageHelper = (image: InternalPropTypes.Image) => {
      if (!image) {
        return undefined;
      }

      if (image.imgixImage) {
        return new ImgixImageHelper(image.imgixImage);
      }

      if (image.contentfulImage) {
        return new ContentfulImageHelper(image.contentfulImage, appConfig);
      }

      if (image.simpleImage) {
        return new SimpleImageHelper(image.simpleImage);
      }

      return undefined;
    };

    const imageHelperFactory: ImageFactoryProps = {
      createImageHelper,
    };

    return (
      <Component {...otherProps} imageHelperFactory={imageHelperFactory} />
    );
  }

  return compose<WithAppConfigProps, React.ElementType>(withAppConfig)(
    ImageHelperFactory,
  );
}

export default withImageHelperFactory;
