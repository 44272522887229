import { Component } from 'react';

const styles = {
  display: 'flex',
  '& .hstack-2-cell-item': {
    flexBasis: 0,
    flexGrow: 1,
  },
};

type Hstack2CellProps = {
  onRenderLeftCell?: (...args: any[]) => React.ReactNode;
  onRenderRightCell?: (...args: any[]) => React.ReactNode;
  className?: string;
};

class Hstack2Cell extends Component<Hstack2CellProps> {
  static defaultProps = {
    onRenderLeftCell: () => null,
    onRenderRightCell: () => null,
    className: null,
  };

  render() {
    const { onRenderLeftCell, onRenderRightCell, className } = this.props;

    return (
      <div
        css={styles}
        className={`hstack-2-cell${className ? ` ${className}` : ''}`}
      >
        <div className="hstack-2-cell-item hstack-2-cell-item-left">
          {onRenderLeftCell()}
        </div>
        <div className="hstack-2-cell-item hstack-2-cell-item-right">
          {onRenderRightCell()}
        </div>
      </div>
    );
  }
}

export default Hstack2Cell;
