import { Component } from 'react';
import { css } from '@emotion/react';
import { compose } from 'recompose';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import FrontPageHeroMolecule from './common/molecules/FrontPageHero';
import FullBackground from './common/molecules/FullBackground';
import Image from './common/atoms/Image';
import Video from './common/atoms/Video';

import { iffTernary } from '../utils/utilFunctions';

import * as InternalPropTypes from '../constants/internal-types';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../utils/hocs/withImageHelperFactory';
import ScreenSize from '../constants/screenSize';

import { SizesType } from '../types/ts/imgixQuery';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = css({
  position: 'relative',
});

type FrontPageHeroProps = {
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  leftSnippet?: InternalPropTypes.NamedSnippet;
  middleSnippet?: InternalPropTypes.NamedSnippet;
  rightSnippet?: InternalPropTypes.NamedSnippet;
  image?: InternalPropTypes.Image;
  video?: InternalPropTypes.Video;
  backgroundColor?: TypeBackgroundColorTypes;
};

type FrontPageHeroInnerProps = WithImageHelperFactoryProps & FrontPageHeroProps;

export class FrontPageHero extends Component<FrontPageHeroInnerProps> {
  static defaultProps = {
    headerText: null,
    bodyText: null,
    leftSnippet: {
      name: null,
      text: null,
    },
    middleSnippet: {
      name: null,
      text: null,
    },
    rightSnippet: {
      name: null,
      text: null,
    },
    image: null,
    video: null,
    backgroundColor: null,
  };

  render() {
    const {
      headerText,
      bodyText,
      leftSnippet,
      middleSnippet,
      rightSnippet,
      image,
      video,
      backgroundColor,
      imageHelperFactory,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const posterUrl = imageHelperFactory
      .createImageHelper(video?.poster)
      ?.getImageUrl();

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '767', h: '415' },
      [ScreenSize.Tablet]: { w: '1023', h: '716' },
      [ScreenSize.Desktop]: { w: '1920', h: '1080' },
    };

    return (
      <div css={[styles, addStyle]} className="front-page-hero-component">
        <FullBackground>
          {image ? (
            <Image image={image} sizes={sizes} />
          ) : (
            iffTernary(
              video,
              <Video
                sources={video.videos}
                posterUrl={posterUrl}
                ariaLabel={video.ariaLabel}
              />,
              null,
            )
          )}
        </FullBackground>

        <ContentOuterBound>
          <FrontPageHeroMolecule
            headerText={headerText}
            bodyText={bodyText}
            leftFooterText={leftSnippet.text}
            middleFooterText={middleSnippet.text}
            rightFooterText={rightSnippet.text}
          />
        </ContentOuterBound>
      </div>
    );
  }
}

export default compose<FrontPageHeroInnerProps, FrontPageHeroProps>(
  withImageHelperFactory,
)(FrontPageHero);
