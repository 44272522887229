import { colorEnum } from './colors';

export const backgroundColors = {
  BLACK: colorEnum.black,
  WHITE: colorEnum.white,
  BLUE: colorEnum.blue,
  GRAY: colorEnum.gray,
  LIGHTGRAY: colorEnum.lightGray,
  BRIGHTGRAY: colorEnum.brightGray,
  TRANSPARENT: 'transparent',
} as const;

export default backgroundColors;
