import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ImageTwoTextsMolecule from './common/molecules/ImageTwoTexts';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '47px',
    paddingBottom: '47px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '59px',
    paddingBottom: '59px',
  },
};

type ImageTwoTextsProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
};

class ImageTwoTexts extends Component<ImageTwoTextsProps> {
  static defaultProps = {
    mediaItems: [],
    backgroundColor: null,
  };

  render() {
    const { mediaItems, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]} className="image-two-texts-component">
        <ContentOuterBound>
          <ContentSpacer>
            <ImageTwoTextsMolecule mediaItems={mediaItems} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageTwoTexts;
