import { Component } from 'react';
import { css } from '@emotion/react';

import ArticleTeaser from './ArticleTeaser';

import {
  generateArticleGrid,
  getArticleGridImageSizes,
} from '../../../utils/GridGenerator';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const brokenGridCss = generateArticleGrid();

const styles = {
  '& .article-container': {
    display: 'inline-flex',
    paddingLeft: '8px',
    paddingRight: '8px',
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
      marginBottom: '20px',
    },
    [Breakpoints.Tablet.mq]: {
      maxWidth: '50%',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '33.333333%',
    },
    '& .image-container': {
      overflow: 'hidden',
    },
    '& .image': {
      transform: 'scale(1.0)',
      transition: 'all 200ms linear',
    },
    '& :hover': {
      '& .article-title': {
        textDecoration: 'underline',
      },
      '& .image': {
        transform: 'scale(1.05)',
        transition: 'all 200ms linear',
      },
    },
  },
};

type ArticleGridProps = {
  items?: InternalPropTypes.MediaItem[];
  showAll?: boolean;
};

class ArticleGrid extends Component<ArticleGridProps> {
  static defaultProps = {
    items: [],
    showAll: false,
  };

  render() {
    const { items } = this.props;
    return (
      <div css={styles}>
        <div className="container-fluid">
          <div
            className="row image-padding"
            css={css`
              ${brokenGridCss}
            `}
          >
            {items.map((item, index) => (
              <div
                className={`article-container col-12 col-md-6 col-lg-4 ${
                  this.props.showAll ? '' : 'view-controller'
                }`}
                // eslint-disable-next-line react/no-array-index-key
                key={`article-teaser-${index}`}
              >
                <ArticleTeaser
                  image={item.image}
                  imageSizes={getArticleGridImageSizes(index)}
                  title={item.header}
                  text={item.bodyText}
                  link={item.link}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleGrid;
