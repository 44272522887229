import { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../atoms/Header';
import FooterSnippet from '../atoms/FooterSnippet';

import { changeHashToQuery } from '../../../utils/url';
import CallToAction from './CallToAction';
import * as InternalPropTypes from '../../../constants/internal-types';
import TextButton from './TextButton';

const style = {
  '& .spacer-box': {
    minHeight: '150px',
  },
  '& .social-media-box': {
    display: 'flex',
  },
};

type FooterCardProps = {
  headerLink?: InternalPropTypes.Link;
  finePrint?: InternalPropTypes.MediaItem;
  className?: string;
  soMeLinks?: InternalPropTypes.Link[];
  settings?: InternalPropTypes.MediaItem;
  onClick?: () => void;
};

class FooterCard extends Component<FooterCardProps> {
  static defaultProps = {
    headerLink: null,
    finePrint: null,
    className: null,
    soMeLinks: null,
    settings: null,
    onClick: null,
  };

  render() {
    const { headerLink, finePrint, className, soMeLinks, settings, onClick } =
      this.props;
    return (
      <div
        css={style}
        className={`footer-card ${className ? `${className}` : ''}`}
      >
        <div className="spacer-box">
          {headerLink && (
            <Link to={changeHashToQuery(headerLink.linkTo)}>
              {' '}
              <Header seoWeight="p" type={Header.types.ARTICLE}>
                {headerLink.text}
              </Header>
            </Link>
          )}
        </div>
        <div className="social-media-box">
          {' '}
          {soMeLinks &&
            soMeLinks.map((l, idx) => (
              <CallToAction
                // eslint-disable-next-line react/no-array-index-key
                key={`cta${idx}`}
                link={l}
                type={CallToAction.types.LOGO}
              />
            ))}
        </div>
        {finePrint && <FooterSnippet finePrint={finePrint} />}
        {settings && <TextButton settings={settings} onClick={onClick} />}
      </div>
    );
  }
}

export default FooterCard;
