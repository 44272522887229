import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as middleware from 'i18next-http-middleware';
import * as Language from '../constants/language';
import PathLanguageDetector from './PathLanguageDetector';
import DomainLanguageDetector from './DomainLanguageDectector';
import * as CookieConstants from '../client-server-utils/Cookie';

const isServer = typeof window === 'undefined';
const languageDetector = isServer
  ? new middleware.LanguageDetector()
  : new LanguageDetector();
languageDetector.addDetector(PathLanguageDetector);
languageDetector.addDetector(DomainLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ...(isServer ? {} : { resources: {} }),
    fallbackLng: 'da-DK',
    supportedLngs: Language.LANGUAGES.map((l) => l.iso),
    debug: process.env.NODE_ENV !== 'production',
    detection: {
      order: [PathLanguageDetector.name, 'cookie', DomainLanguageDetector.name],
      lookupCookie: CookieConstants.LANGUAGE,
    },
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
