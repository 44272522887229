/* eslint-disable react/prop-types */
import { Component, ReactNode } from 'react';
import 'core-js/es/promise';
import LoadingManager, {
  LoadResult,
} from '../client-server-utils/loading/LoadingManager';

/*

  A purely abstract object for handling the "load content from contentful as first thing" condition that many of the components in this solution need

  Components that inherit from this should implement at least the loadContent and renderLoaded methods. Loaded content is stored in the
  state.cmsContent variable. That means, loadContent returns a promise. Anything returned on resolvement from that promise gets saved in cmsContent.
  Child components can move around their stuff or do more advanced loading, it just required more overrides.

*/
export type LoadingComponentProps = {
  loadingManager: LoadingManager;
};

export type LoadingComponentState = {
  loadResult?: LoadResult;
};

class LoadingComponent<
  P extends LoadingComponentProps,
  S extends LoadingComponentState,
> extends Component<P, S> {
  constructor(props) {
    super(props);
    this.state = this._stateForHydration() as S;
  }

  // eslint-disable-next-line react/sort-comp
  _stateForHydration(): LoadingComponentState {
    let newState = {};
    const { loadingManager } = this.props;
    const cacheKey = this.getComponentCacheKey();
    if (loadingManager.hasResponse(cacheKey)) {
      newState = { loadResult: loadingManager.retrieveLoadResult(cacheKey) };
    }
    return newState;
  }

  // eslint-disable-next-line class-methods-use-this
  getComponentCacheKey(): string {
    throw new Error(
      'getComponentCacheKey method must be overridden in child of LoadingComponent to provide a unique cache id for the request',
    );
  }

  loadContent(): Promise<object> {
    console.warn(
      `Component ${this.constructor.name} inherits from LoadingComponent, but has not implemented the loadContent() method.`,
    );
    return Promise.resolve({});
  }

  // eslint-disable-next-line class-methods-use-this
  renderLoaded(response: object | undefined): ReactNode {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  renderFailed(error: object | Error | undefined): ReactNode {
    // todo: make a general implementation here
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  renderUnloaded(): ReactNode {
    return null;
  }

  _isLoaded(): boolean {
    const { loadResult } = this.state;
    const cacheKey = this.getComponentCacheKey();
    return loadResult !== undefined && loadResult.key === cacheKey;
  }

  _doLoad() {
    const { loadingManager } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    loadingManager
      .fetch(this.getComponentCacheKey(), this.loadContent())
      .thenIfClient((loadResult) => me.setState({ loadResult }));
  }

  _ensureLoad() {
    const { loadingManager } = this.props;
    if (!loadingManager.hasKey(this.getComponentCacheKey())) {
      this._doLoad();
    }
  }

  render() {
    if (this._isLoaded()) {
      if (this.state.loadResult?.success()) {
        return this.renderLoaded(this.state.loadResult.response);
      }

      return this.renderFailed(this.state.loadResult?.error);
    }

    this._ensureLoad();
    return this.renderUnloaded();
  }
}

export default LoadingComponent;
