import { EN, DK } from './language';

export type ConsentType = {
  key: string;
  text: {
    [language: string]: string;
  };
};

export const NECESSARY = {
  key: 'necessary',
  text: {
    [EN.iso]: 'Necessary cookies',
    [DK.iso]: 'Nødvendige cookies',
  },
} as ConsentType;

export const STATISTICAL = {
  key: 'statistical',
  text: {
    [EN.iso]: 'Statistical cookies',
    [DK.iso]: 'Statistiske cookies',
  },
} as ConsentType;

export const MARKETING = {
  key: 'marketing',
  text: {
    [EN.iso]: 'Marketing cookies',
    [DK.iso]: 'Markedsføringscookies',
  },
} as ConsentType;

export const CONSENTCATEGORIES = [NECESSARY, STATISTICAL, MARKETING];

export const consentEnum = Object.fromEntries(
  CONSENTCATEGORIES.map((item) => [item.key.toUpperCase(), item.key]),
);

export const consentTypes = CONSENTCATEGORIES.map((consent) => consent.key);
