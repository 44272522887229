import { Component } from 'react';
import { css } from '@emotion/react';

import Image from '../atoms/Image';
import Grid2CellWithGutter from './Grid2CellWithGutter';
import TitleTextGroup from './TitleTextGroup';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .image-1, .image-2 ': {
    width: '100%',
    objectFit: 'cover',
    '& .image': {
      [Breakpoints.DesktopUp.mq]: {
        marginBottom: '28px',
      },
      [Breakpoints.Tablet.mq]: {
        marginBottom: '20px',
      },
      [Breakpoints.Mobile.mq]: {
        marginBottom: '16px',
      },
    },
  },
  '& .image-1': {
    [Breakpoints.DesktopUp.mq]: {
      height: '533px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '418px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '460px',
    },
  },
  '& .image-2': {
    [Breakpoints.DesktopUp.mq]: {
      height: '234px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '184px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '220px',
    },
  },
  '& .title-text-group': {
    [Breakpoints.TabletUp.mq]: {
      width: '80%',
    },
  },
  '& .body-text': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '64px',
    },
  },
});

type ImageTextsProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
};

class ImageTexts extends Component<ImageTextsProps> {
  static defaultProps = {
    mediaItems: [],
  };

  render() {
    const { mediaItems } = this.props;

    const leftImageSizes: SizesType = {
      [ScreenSize.Mobile]: { h: '460' },
      [ScreenSize.Tablet]: { h: '418' },
      [ScreenSize.Desktop]: { h: '533' },
    };

    const rightImageSizes: SizesType = {
      [ScreenSize.Mobile]: { h: '234' },
      [ScreenSize.Tablet]: { h: '184' },
      [ScreenSize.Desktop]: { h: '220' },
    };

    return (
      <div css={styles} className="Image-two-texts">
        <Grid2CellWithGutter
          type={Grid2CellWithGutter.SERVICES}
          snapAtBreakpoint={Breakpoints.Mobile}
        >
          {mediaItems[0] && (
            <div className="left">
              <Image
                className="image-1"
                image={mediaItems[0].image}
                sizes={leftImageSizes}
              />
              <TitleTextGroup
                bodyText={mediaItems[0].bodyText}
                titleText={mediaItems[0].header}
              />
            </div>
          )}

          {mediaItems[1] && (
            <div className="right">
              <Image
                className="image-2"
                image={mediaItems[1].image}
                sizes={rightImageSizes}
              />
              <TitleTextGroup
                bodyText={mediaItems[1].bodyText}
                titleText={mediaItems[1].header}
              />
            </div>
          )}
        </Grid2CellWithGutter>
      </div>
    );
  }
}

export default ImageTexts;
