import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';
import Image from '../atoms/Image';
import { BackgroundColorEnum } from '../../../constants/cms-constants';
import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .location-container-not-active': {
    display: 'none',
  },
  '& .show-hide-btn': {
    textAlign: 'left',
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    color: colorEnum.brightGray,
    border: 'none',
    borderBottom: '1px solid white',
    marginBottom: 0,
    padding: 0,
    outline: 0,
    '& p': {
      marginBottom: 0,
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '80px',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    [Breakpoints.TabletDown.mq]: {
      marginBottom: '10px',
      display: 'table',
    },
  },
  '& .location-image, .location-map': {
    height: '300px',
    width: '75%',
    objectFit: 'cover',
    [Breakpoints.DesktopUp.mq]: {
      width: '100%',
      marginTop: '120px',
    },
  },
  '& .location-text, .btn-text': {
    color: colorEnum.brightGray,
  },
});

type LocationProps = {
  locImage?: InternalPropTypes.Image;
  locCaption?: InternalPropTypes.RichText;
  locButtonText?: InternalPropTypes.RichText;
  mapCaption?: InternalPropTypes.RichText;
  mapButtonText?: InternalPropTypes.RichText;
};

interface ILocationState {
  showMap: boolean;
}

class Location extends Component<LocationProps, ILocationState> {
  static defaultProps = {
    locImage: null,
    locCaption: null,
    locButtonText: null,
    mapCaption: null,
    mapButtonText: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showMap: false,
    };
    this.changeState = this.changeState.bind(this);
  }

  changeState(prevState) {
    this.setState({
      showMap: !prevState,
    });
  }

  render() {
    const { locImage, locCaption, locButtonText, mapCaption, mapButtonText } =
      this.props;
    const { showMap } = this.state;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '557', h: '300' },
      [ScreenSize.Tablet]: { w: '677', h: '300' },
      [ScreenSize.Desktop]: { w: '417', h: '300' },
    };

    return (
      <div css={styles}>
        <div
          className={`location-container-${showMap ? 'not-active' : 'active'}`}
        >
          <button
            type="button"
            className="show-hide-btn"
            onClick={() => this.changeState(showMap)}
          >
            <BodyText className="btn-text">{locButtonText}</BodyText>
          </button>
          <div className="location-image">
            <Image image={locImage} sizes={sizes} />
          </div>
          <BodyText className="location-text">{locCaption}</BodyText>
        </div>

        <div
          className={`location-container-${showMap ? 'active' : 'not-active'}`}
        >
          <button
            type="button"
            className="show-hide-btn"
            onClick={() => this.changeState(showMap)}
          >
            <BodyText className="btn-text">{mapButtonText}</BodyText>
          </button>

          <div className="location-map">
            <iframe
              title="google-maps-nyhavn43"
              // In case the office gets a new location the src needs to be updated.
              // At this time it is done in google maps by searching the wanted location
              // Then find the share/embed option in the menu.
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.5143147481476!2d12.588798116418516!3d55.6800455050678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c7e53ffb27043%3A0x50237c0e1798301!2sNovataris%20A%2FS!5e0!3m2!1sda!2sdk!4v1603889599270!5m2!1sda!2sdk"
              width="100%"
              height="100%"
              frameBorder="0"
              aria-hidden="false"
            />
          </div>

          <BodyText className="location-text">{mapCaption}</BodyText>
        </div>
      </div>
    );
  }
}

export default Location;
