import React, { Component } from 'react';
import { css } from '@emotion/react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import MediaQuery from './common/atoms/MediaQuery';
import InlinedRichText from './common/molecules/InlinedRichText';
import NextArticle from './common/molecules/NextArticle';
import Grid2CellUnevenSplit from './common/atoms/Grid2CellUnevenSplit';
import CallToAction from './common/molecules/CallToAction';
import StickyColumn from './common/atoms/StickyColumn';
import MasonryColumns from './common/atoms/MasonryColumns';
import HstackSpacing, { HstackSpacingEnum } from './common/atoms/HstackSpacing';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = css({
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '54px',
    paddingBottom: '54px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  '& .next-up-desktop': {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& .sticky-column-inner': {
      top: '4%',
      '& .next-up-article + .next-up-article': {
        marginTop: '70px',
      },
    },
  },
  '& .next-up-tablet': {
    marginTop: '54px',
    '&.masonry .next-up-article': {
      margin: '30px',
    },
  },
  '& .next-up-cta': {
    marginTop: '24px',
  },
  '& .next-up-mobile': {
    marginTop: '54px',
    '& .next-up-article': {
      marginTop: '60px',
    },
  },
  '& .cta': {
    height: 'fit-content',
    [Breakpoints.Mobile.mq]: {
      marginTop: '54px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '48px',
    },
  },
  '& .hstack-space-between': {
    alignItems: 'flex-end',
  },
});

type ArticleProps = {
  header?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  nextArticles?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  spacing?: HstackSpacingEnum;
};

class Article extends Component<ArticleProps> {
  static defaultProps = {
    header: null,
    link: null,
    nextArticles: [],
    backgroundColor: null,
    spacing: HstackSpacing.SPACEBETWEEN,
  };

  render() {
    const { header, nextArticles, link, backgroundColor, spacing } = this.props;

    const nextArticleComponents = nextArticles.map((nextArticle, idx) => (
      <NextArticle
        header={nextArticle.header}
        teaserTitle={nextArticle.lightHeader}
        url={nextArticle.link?.linkTo}
        bodyText={nextArticle.bodyText}
        backgroundColor={nextArticle.backgroundColor}
        className="next-up-article"
        // eslint-disable-next-line react/no-array-index-key
        key={`nextarticle-${nextArticle.header}-${idx}`}
        openInNewWindowOrTab={nextArticle.link?.openInNewWindowOrTab}
      />
    ));

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="article-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <MediaQuery visible={Breakpoints.Mobile}>
              <InlinedRichText
                richText={header}
                openInNewWindowOrTab={link?.openInNewWindowOrTab ?? false}
              />
              <div className="next-up-mobile">{nextArticleComponents}</div>
              <CallToAction
                type={CallToAction.types.LEFT_POINTING}
                link={link}
              />
            </MediaQuery>
            <MediaQuery visible={Breakpoints.Tablet}>
              <InlinedRichText
                richText={header}
                openInNewWindowOrTab={link?.openInNewWindowOrTab ?? false}
              />
              {nextArticleComponents.length > 1 ? (
                <>
                  <MasonryColumns className="next-up-tablet masonry">
                    {nextArticleComponents}
                  </MasonryColumns>
                  <CallToAction
                    type={CallToAction.types.LEFT_POINTING}
                    className="next-up-cta"
                    link={link}
                  />
                </>
              ) : (
                <HstackSpacing spacing={spacing} className="next-up-tablet">
                  <CallToAction
                    type={CallToAction.types.LEFT_POINTING}
                    link={link}
                  />
                  {nextArticleComponents}
                </HstackSpacing>
              )}
            </MediaQuery>
            <MediaQuery visible={Breakpoints.DesktopUp}>
              <Grid2CellUnevenSplit
                leftCellCount={7}
                rightCellCount={5}
                onRenderCell1={() => (
                  <InlinedRichText
                    richText={header}
                    openInNewWindowOrTab={link?.openInNewWindowOrTab ?? false}
                  />
                )}
                onRenderCell2={() => (
                  <StickyColumn className="next-up-desktop">
                    {nextArticleComponents}
                  </StickyColumn>
                )}
              />
              {link && (
                <CallToAction
                  type={CallToAction.types.LEFT_POINTING}
                  link={link}
                />
              )}
            </MediaQuery>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default Article;
