import { IEventInput } from '../../constants/reporterServiceInputs';

export default class EventInput implements IEventInput {
  name: string;

  properties?: { [key: string]: any };

  constructor(name: string, properties?: object) {
    this.name = name;
    this.properties = properties;
  }
}
