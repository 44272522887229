import { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Route } from 'react-router-dom';
import { css } from '@emotion/react';

import ContentOuterBound from './common/atoms/ContentOuterBound';

import logoWhite from '../assets/logo-white.png';
import closeIcon from '../assets/closeIcon.png';

import * as Breakpoints from '../constants/breakpoints';
import { BackgroundColorEnum } from '../constants/cms-constants';

const styles = css({
  position: 'relative',
  '& .navBar': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '1px',
      paddingLeft: '13px',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '7px',
      paddingLeft: '21px',
    },
  },
  '& .navbar-brand': {
    marginRight: 'auto',
    '& img': {
      maxWidth: '135px',
      position: 'relative',
      zIndex: '1200',
    },
  },
  '& .close-button': {
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    border: 0,
    outline: 0,
    '& img': {
      height: '20px',
    },
  },
});

type ModalMenuProps = {
  handleClose: (...args: object[]) => void; // Required
};

class ModalMenu extends Component<ModalMenuProps> {
  handleClose(history) {
    this.props.handleClose(history);
  }

  render() {
    return (
      <ContentOuterBound>
        <div css={styles}>
          <Navbar className="navBar">
            <Navbar.Brand>
              <NavLink to="/">
                <img src={logoWhite} alt="logo" />
              </NavLink>
            </Navbar.Brand>
            <Route
              render={({ history }) => (
                <button
                  className="close-button"
                  type="button"
                  onClick={() => this.handleClose(history)}
                >
                  <img src={closeIcon} alt="close" />
                </button>
              )}
            />
          </Navbar>
        </div>
      </ContentOuterBound>
    );
  }
}

export default ModalMenu;
