import { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import { compose } from 'recompose';
import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import ImgixParameters from '../../../utils/images/imgixParameters';
import ScreenSize from '../../../constants/screenSize';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../../../utils/hocs/withImageHelperFactory';
import ImageHelper from '../../../utils/hocs/imageHelpers/imageHelper';
import { SizeOrSizes } from '../../../types/ts/imgixQuery';

interface IImageState {
  display: boolean;
}

type ImageType = {
  // The linter is just wrong here
  image?: InternalPropTypes.Image; // eslint-disable-line no-use-before-define
  className?: string;
  sizes?: SizeOrSizes;
};

type ImageInnerType = WithImageHelperFactoryProps & ImageType;

type RenderImageProps = {
  imageUrl: string;
  className: string;
  alternateText: string;
};

type RenderImgixImageProps = RenderImageProps & {
  srcSets: Partial<Record<ScreenSize, string>>;
};

function getMediaQueryFromScreenSize(size: ScreenSize) {
  switch (size) {
    case ScreenSize.Mobile:
      return Breakpoints.Mobile.rawMq;
    case ScreenSize.Tablet:
      return Breakpoints.Tablet.rawMq;
    case ScreenSize.Desktop:
      return Breakpoints.DesktopUp.rawMq;
    default:
      return null;
  }
}

function renderFallbackImage({
  imageUrl,
  className,
  alternateText,
}: RenderImageProps) {
  return (
    <img
      className={`image ${className ?? ''}`.trim()}
      alt={alternateText}
      src={imageUrl}
    />
  );
}

function renderImgixImage({
  imageUrl,
  srcSets,
  className,
  alternateText,
}: RenderImgixImageProps) {
  const sizes = Object.keys(srcSets) as ScreenSize[];

  return (
    <picture className={`picture ${className ?? ''}`.trim()}>
      {sizes.map((size) => (
        <source
          key={size}
          srcSet={srcSets[size]}
          media={getMediaQueryFromScreenSize(size)}
        />
      ))}
      <img className="image" alt={alternateText} src={imageUrl} />
    </picture>
  );
}

class Image extends Component<ImageInnerType, IImageState> {
  static defaultProps = {
    image: null,
    className: null,
    sizes: null,
  };

  private _imageHelper: ImageHelper;

  constructor(props) {
    super(props);
    this.state = { display: false };
    this._imageHelper = props.imageHelperFactory.createImageHelper(props.image);
  }

  render() {
    const { className, image, sizes } = this.props;

    if (!image || !this._imageHelper) {
      return null;
    }

    let imageUrl = encodeURI(this._imageHelper.getImageUrl());
    let srcSets = null;
    const { alternateText } = image;
    const imgixParams = new ImgixParameters({
      imageUrl,
      imgixQueryString: image.imgixQueryString,
      sizes,
    });

    if (this._imageHelper.supportsImgix()) {
      imageUrl = imgixParams.getImgixUrl();
      srcSets = imgixParams.generateMultipleImgixSrcSets();
    }

    const imageHtml = srcSets
      ? renderImgixImage({
          imageUrl,
          srcSets,
          className,
          alternateText,
        })
      : renderFallbackImage({ imageUrl, className, alternateText });

    return (
      <Waypoint onEnter={() => this.setState({ display: true })}>
        {this.state.display ? imageHtml : null}
      </Waypoint>
    );
  }
}

export default compose<ImageInnerType, ImageType>(withImageHelperFactory)(
  Image,
);
