import { ContentTypeEnum } from '../../constants/cms-constants';
import RelatedPagesGridComponent from '../../components/RelatedPagesGrid';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class RelatedPagesGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent, block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    let items;
    blockContent.doQuery((content) => {
      items = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <RelatedPagesGridComponent
        headerText={block?.header?.main}
        backgroundColor={backgroundColor}
        mediaItems={items}
      />
    );
  }
}

export default RelatedPagesGridBlock;
