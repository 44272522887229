import { Component } from 'react';
import LottiePlayer from 'react-lottie-player/dist/LottiePlayerLight';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .animation': {
    cursor: 'default',
    [Breakpoints.Mobile.mq]: {
      maxWidth: '300px',
      display: 'block',
      margin: 'auto',
    },
    [Breakpoints.Tablet.mq]: {
      maxWidth: '315px',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '350px',
    },
  },
};

type SVGRendererProps = {
  animation: InternalPropTypes.ImageFile;
};

class SVGRenderer extends Component<SVGRendererProps> {
  render() {
    const { animation } = this.props;
    return animation ? (
      <div css={styles}>
        <LottiePlayer path={animation.url} play loop className="animation" />
      </div>
    ) : null;
  }
}

export default SVGRenderer;
