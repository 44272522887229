import { Component, PropsWithChildren } from 'react';
import { css } from '@emotion/react';

const styles = css({
  paddingTop: '85%', // force height of box
  position: 'relative',
  '& .cell': {
    position: 'absolute',
    '& .image': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  '& .cell-1': {
    width: '40%',
    height: '66%',
    left: 0,
    top: '12%',
  },
  '& .cell-2': {
    width: '53%',
    height: '42%',
    right: 0,
    top: 0,
  },
  '& .cell-3': {
    width: '36%',
    height: '50%',
    bottom: 0,
    left: '47%',
  },
});

type Grid3CellBrokenProps = PropsWithChildren;

class Grid3CellBroken extends Component<Grid3CellBrokenProps> {
  static defaultProps = {
    children: [],
  };

  render() {
    const { children } = this.props;
    return (
      <div css={styles}>
        <div className="cell cell-1">{children[0]}</div>
        <div className="cell cell-2">{children[1]}</div>
        <div className="cell cell-3">{children[2]}</div>
      </div>
    );
  }
}

export default Grid3CellBroken;
