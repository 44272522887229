import React, { Component } from 'react';
import { css, keyframes } from '@emotion/react';
import PropTypes from 'prop-types';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import font from '../../../constants/fonts';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

const animations = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
     }
    100% {
      opacity: 1;
     }
  `,
  colorShift: keyframes`
    0% {
      opacity: 0.34
     }
    100% {
     opacity: 1
    }
  `,
};

const styles = css({
  '& .navigable-list-molecule': {
    marginBottom: '16px',
    position: 'relative',
  },
  '& .navigable, .section, .lead': {
    paddingBottom: '16px',
  },
  '& .navigable': {
    lineHeight: '21px',
    opacity: '0.32',
    paddingBottom: '8px',
    paddingTop: '8px',
    textAlign: 'left',
    '&.active': {
      ...font.GNMedium,
      opacity: '1',
      fontWeight: '500',
    },
  },
  '& .first-navigable': {
    paddingTop: 0,
  },
  '& .text-container': {
    display: 'none',
    [Breakpoints.Mobile.mq]: {
      position: 'relative',
      paddingTop: '64px',
    },
    [Breakpoints.Tablet.mq]: {
      marginLeft: '24px',
    },
    '&.active': {
      display: 'block',
      animation: `${animations.fadeIn} 600ms forwards ease-in`,
    },
  },
  '& .title-container': {
    marginBottom: '16px',
  },
  '& .header-btn': {
    display: 'block',
    background: 'none',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    outline: 'none',
    '& .navigable.active': {
      animation: `${animations.colorShift} 600ms forwards ease-in`,
    },
  },
});

type NavigableListProps = {
  content?: InternalPropTypes.MediaItem[];
};

interface INavigableListState {
  active: number;
}

class NavigableList extends Component<NavigableListProps, INavigableListState> {
  static defaultProps = {
    content: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
    this.changeIndex = this.changeIndex.bind(this);
  }

  changeIndex(index) {
    this.setState({
      active: index,
    });
  }

  render() {
    const { content } = this.props;
    const { active } = this.state;
    return (
      <div css={styles}>
        <div className="navigable-list-molecule">
          <Grid2CellUnevenSplit
            leftCellCount={4}
            rightCellCount={7}
            onRenderCell1={() => (
              <div>
                {content.map((item, index) => (
                  <button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    type="button"
                    className="header-btn"
                    onClick={() => this.changeIndex(index)}
                  >
                    <Title
                      type={TitleTypes.NAVIGABLE}
                      className={`${index === 0 ? 'first-navigable' : ''} ${
                        active === index ? 'active' : ''
                      }`}
                    >
                      {item.header}
                    </Title>
                  </button>
                ))}
              </div>
            )}
            onRenderCell2={() => (
              <>
                {content.map((item, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`text-container ${
                      active === index ? 'active' : ''
                    }`}
                  >
                    <div className="title-container">
                      <Title type={TitleTypes.SECTION}>{item.header}</Title>
                    </div>

                    <Title seoWeight="p" type={TitleTypes.LEAD}>
                      {item.lightHeader}
                    </Title>
                    <BodyText type={BodyText.types.STATIC16}>
                      {item.bodyText}
                    </BodyText>
                  </div>
                ))}
                <div />
              </>
            )}
            snapAtBreakpoint={Breakpoints.MobileDown}
          />
        </div>
      </div>
    );
  }
}

export default NavigableList;
