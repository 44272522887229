import { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const style = {
  '.badge': {
    padding: '6px',
  },
};

export type TextBugProps = {
  title?: string;
  description?: string;
  badgeText?: string;
};

class TextBug extends Component<TextBugProps> {
  static defaultProps = {
    title: '',
    description: '',
    badgeText: 'Text bug',
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(props: TextBugProps) {
    super(props);
  }

  render() {
    const { title, description, badgeText } = this.props;
    const popover = (
      <Popover id="text-bug-popover">
        <Popover.Title as="h3">{title}</Popover.Title>
        <Popover.Content>{description}</Popover.Content>
      </Popover>
    );

    return (
      <span css={style}>
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement="right"
          overlay={popover}
        >
          <Badge variant="danger">{badgeText}</Badge>
        </OverlayTrigger>
      </span>
    );
  }
}

export default TextBug;
