import { Component } from 'react';

const style = {
  display: 'flex',
  alignItems: 'center',
};

type VerticalCenterProps = {
  className?: string;
  children?: React.ReactNode;
};

class VerticalCenter extends Component<VerticalCenterProps> {
  static defaultProps = {
    className: null,
    children: null,
  };

  render() {
    const { children, className } = this.props;

    return (
      <div
        css={style}
        className={`vertical-center${className ? ` ${className}` : ''}`}
      >
        <div className="vertical-center-item">{children}</div>
      </div>
    );
  }
}

export default VerticalCenter;
