/* eslint-disable import/no-import-module-exports */
import { BrowserRouter } from 'react-router-dom';
import { hydrateRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { useEffect } from 'react';

import App from './App';
import LoadingContext from './contexts/LoadingContext';
import ReporterServiceContext from './contexts/ReporterServiceContext';
import ClientCookieService from './utils/ClientCookieService';
import createEmotionCache from './utils/createEmotionCache';
import ErrorInput from './utils/reporterInput/ErrorInput';
import Consent from './utils/Consent';

import {
  ClientConfig,
  ConfigVariable,
} from './client-server-utils/StaticConfig';
import { SimpleLoadingManager } from './client-server-utils/loading/LoadingManager';
import ReporterService from './client-server-utils/ReporterService';
import ClientDriverFactory from './client-server-utils/ClientReporterDrivers/clientReporterDriversFactory';
import { HydrationError } from './client-server-utils/errors/HydrationErrors';
import { SerializedCache } from './client-server-utils/loading/LoadingCache';

declare global {
  interface Window {
    config: object | string;
    initData: SerializedCache;
  }
}

const emotionCache = createEmotionCache();
const staticConfig = new ClientConfig();
staticConfig.initializeFromSerialized(window.config);
const loadingManager = new SimpleLoadingManager(
  staticConfig.get(ConfigVariable.CacheTimeToLiveInSecs),
  true,
);
const cookieService = new ClientCookieService();
const consentString = cookieService.getConsentCookie();
const consent = new Consent(consentString);
const drivers = ClientDriverFactory.getDrivers(staticConfig, consent);
const reporterService = new ReporterService(drivers);

function HydrateRootWithCallbackAfterRender() {
  useEffect(() => {
    loadingManager.clear();
  });
  return (
    <BrowserRouter>
      <LoadingContext.Provider value={loadingManager}>
        <CacheProvider value={emotionCache}>
          <ReporterServiceContext.Provider value={reporterService}>
            <App cookieService={cookieService} staticConfig={staticConfig} />
          </ReporterServiceContext.Provider>
        </CacheProvider>
      </LoadingContext.Provider>
    </BrowserRouter>
  );
}

const hydratePage = () => {
  hydrateRoot(
    document.getElementById('root'),
    <HydrateRootWithCallbackAfterRender />,
  );

  if (module.hot) {
    module.hot.accept();
  }
};

const initializationError = (e) => {
  const err = new HydrationError('Cache initialization or hydration failed!', {
    cause: e,
  });
  reporterService.reportError(ErrorInput.createFromException(err));
  // hydrate from empty cache as fallback
  hydratePage();
};

loadingManager
  .initializeForHydration(window.initData)
  .then(hydratePage)
  .catch(initializationError);
