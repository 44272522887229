import { Component } from 'react';
import GridEvenSplit from '../atoms/GridEvenSplit';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = {
  '& .left-1': {
    [Breakpoints.Mobile.mq]: {},
    [Breakpoints.Tablet.mq]: {
      marginRight: '16px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '16px',
    },
  },
  '& .left-2': {
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '4%',
    },
  },
  '& .middle-1': {
    [Breakpoints.Mobile.mq]: {},
    [Breakpoints.Tablet.mq]: {
      marginRight: '6px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '16px',
      marginLeft: '16px',
    },
  },
  '& .middle-2': {
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '4%',
      marginLeft: '4%',
    },
  },
  '& .right-1': {
    [Breakpoints.Tablet.mq]: {},
    [Breakpoints.DesktopUp.mq]: {
      marginLeft: '24px',
    },
  },
  '& .right-2': {
    [Breakpoints.DesktopUp.mq]: {
      marginLeft: '4%',
    },
  },
};
type Grid3CellEvenWithGutterProps = {
  snapAtBreakpoint?: Breakpoints.BreakpointType;
  onRenderCells?: ((...args: any[]) => React.ReactNode)[];
};
class Grid3CellEvenWithGutter extends Component<Grid3CellEvenWithGutterProps> {
  static defaultProps = {
    snapAtBreakpoint: Breakpoints.Mobile,
    onRenderCells: [() => null, () => null, () => null],
  };

  render() {
    const { onRenderCells, snapAtBreakpoint } = this.props;
    return (
      <div css={styles}>
        <GridEvenSplit
          snapAtBreakpoint={snapAtBreakpoint}
          onRenderCells={[
            () => (
              <div
                className={`left${
                  snapAtBreakpoint === Breakpoints.Mobile ? '-1' : '-2'
                }`}
              >
                {onRenderCells[0]()}
              </div>
            ),
            () => (
              <div
                className={`middle${
                  snapAtBreakpoint === Breakpoints.Mobile ? '-1' : '-2'
                }`}
              >
                {onRenderCells[1]()}
              </div>
            ),
            () => (
              <div
                className={`right${
                  snapAtBreakpoint === Breakpoints.Mobile ? '-1' : '-2'
                }`}
              >
                {onRenderCells[2]()}
              </div>
            ),
          ]}
        />
      </div>
    );
  }
}
export default Grid3CellEvenWithGutter;
