/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { compose } from 'recompose';
import { css } from '@emotion/react';
import Spinner from 'react-bootstrap/Spinner';
import Header from '../components/Header';
import Page from './Page';
import { EntriesQueries } from '../client-server-utils/connectors/contentful';
import Footer from './Footer';
import '../styles/app.scss';
import ContentfulLoadingComponent, {
  ContentfulLoadingComponentProps,
  ContentfulLoadingComponentState,
} from './ContentfulLoadingComponent';
import FullscreenOverlay from '../components/common/atoms/FullscreenOverlay';
import {
  getPageUrlWithoutLanguageFromPage,
  getPageUrlFromPage,
  changeHashToQuery,
  urlContainsUppercase,
} from '../utils/url';

import withAppConfig from '../utils/hocs/withAppConfig';
import ContentfulPropMapper from '../utils/ContentfulPropMapper';
import * as Breakpoints from '../constants/breakpoints';
import MediaQuery from '../components/common/atoms/MediaQuery';
import DevMenu from '../components/common/molecules/DevMenu';
import { colorEnum } from '../constants/colors';
import withLoadingContext from '../utils/hocs/withLoadingContext';
import SeoInspector from '../components/common/molecules/SeoInspector';
import { ROOT_KEY } from '../constants/cachekeys';
import BottomPanel from '../components/common/atoms/BottomPanel';
import CookieSettingsComponent from '../components/CookieSettings';
import PreserveWrapper from '../components/common/PreserveWrapper';

const styles = css({
  '& .topnavigation': {
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
    top: 0,
  },
  '& .devmenubar': {
    display: 'flex',
    paddingLeft: '2px',
    paddingTop: '2px',
    position: 'absolute',
    width: '100%',
    '&>div + div': {
      marginLeft: '5px',
    },
  },
  '& .normal-page-container': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    '& .page': {
      flex: 1,
    },
  },
  '& .storybook-container': {
    position: 'absolute',
    zIndex: 1001,
    height: '100vh',
    width: '100%',
    backgroundColor: colorEnum.black,
    '& .storybook-iframe': {
      marginTop: '50px',
      height: 'calc(100% - 50px)',
      width: '100%',
      border: 'none',
    },
  },
});

function ModalPage(props, modalPages) {
  const modalPage = modalPages.find(
    (page) => changeHashToQuery(page.slug.modal) === props.location.search,
  );
  if (modalPage) {
    return (
      <FullscreenOverlay>
        <Page page={modalPage} />
      </FullscreenOverlay>
    );
  }
  return null;
}

function Menu(props) {
  return (
    <div className="topnavigation">
      <Header
        pages={props.menuItems}
        currentPage={props.currentPage}
        language={props.language}
        currentPageAlternates={props.currentPageAlternates}
      />
    </div>
  );
}

function CookieConsentBanner(props) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!props.hidden) {
      setVisible(true);
    }
    return () => {
      setVisible(false);
    };
  }, [props.hidden]);

  if (!visible) {
    return null;
  }

  return (
    <PreserveWrapper activated={visible}>
      <BottomPanel visible={visible} backgroundColor={props.backgroundColor}>
        <CookieSettingsComponent
          consentMessage={props.consentMessage}
          cookiePolicyVersion={props.cookiePolicyVersion}
          acceptAllButtonText={props.acceptAllButtonText}
          acceptNecessaryButtonText={props.acceptNecessaryButtonText}
          saveSettingsButtonText={props.saveSettingsButtonText}
          language={props.language}
          propConsent={props.consent}
        />
      </BottomPanel>
    </PreserveWrapper>
  );
}

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = code;
        return children;
      }}
    />
  );
}

function NotFound({ page404, menuItems, currentLanguage }) {
  return (
    <Status code={404}>
      <Menu
        menuItems={menuItems}
        language={currentLanguage}
        currentPage={page404}
      />
      {page404 ? <Page page={page404} /> : <p>404</p>}
    </Status>
  );
}

// eslint-disable-next-line no-unused-vars
function DevMenuBar(props) {
  return (
    <MediaQuery visible={Breakpoints.TabletUp}>
      <div className="devmenubar">
        <DevMenu />
        <SeoInspector />
      </div>
    </MediaQuery>
  );
}

function DefaultTemplate(props) {
  return (
    <div css={styles} className="app" data-environment={props.environment}>
      {props.showDevMenu ? <DevMenuBar /> : null}
      {props.children}
    </div>
  );
}

type RootComponentProps = ContentfulLoadingComponentProps &
  // eslint-disable-next-line @typescript-eslint/ban-types
  RouteComponentProps & {};

// eslint-disable-next-line @typescript-eslint/ban-types
type RootComponentState = ContentfulLoadingComponentState & {};

export const isRootCacheKey = (cacheKey: string) =>
  cacheKey.startsWith(ROOT_KEY);

export class RootComponent extends ContentfulLoadingComponent<
  RootComponentProps,
  RootComponentState
> {
  loadContent(): Promise<object> {
    const { appConfig } = this.props;
    const { connectors } = appConfig;
    const connector = connectors.contentful;
    return new Promise((resolve, reject) => {
      Promise.all([
        connector.call(EntriesQueries.getPagesAsReferences),
        connector.call(EntriesQueries.getRoot),
      ])
        .then(([getPagesResponse, getRootResponse]) => {
          const rootResponse = getRootResponse.isPreview
            ? getRootResponse.preview
            : getRootResponse.content;
          const pagesResponse = getPagesResponse.isPreview
            ? getPagesResponse.preview
            : getPagesResponse.content;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const root = (rootResponse as any).items[0];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const pages = (pagesResponse as any).items;
          const mapper = new ContentfulPropMapper(appConfig);
          resolve(mapper.mapRoot(root, pages, getRootResponse.language));
        })
        .catch(reject);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getTypeAndEntryId() {
    return ROOT_KEY;
  }

  renderUnloaded() {
    const { appConfig } = this.props;
    const appSettings = appConfig.settings;

    return (
      <DefaultTemplate
        showDevMenu={appSettings.editorToolsEnabled}
        environment={appSettings.contentful.environment}
      >
        <div
          style={{
            textAlign: 'center',
            paddingTop: '60px',
            paddingBottom: '20px',
          }}
        >
          <Spinner animation="grow" role="status">
            <span className="sr-only">Loading page</span>
          </Spinner>
        </div>
      </DefaultTemplate>
    );
  }

  renderFailed(error) {
    const { appConfig } = this.props;
    const appSettings = appConfig.settings;
    const showDebugScreen = appSettings.editorToolsEnabled;

    return (
      <DefaultTemplate
        showDevMenu={appSettings.editorToolsEnabled}
        environment={appSettings.contentful.environment}
      >
        {showDebugScreen ? (
          <>
            <hr />
            <h2>Error occurred while loading</h2>
            <pre>{JSON.stringify(error)}</pre>
          </>
        ) : null}
      </DefaultTemplate>
    );
  }

  renderLoaded(root) {
    const { appConfig, history } = this.props;
    const { pages, modalPages, systemPages } = root;
    const { currentPageAlternates, settings: appSettings } =
      this.props.appConfig;
    const isoLanguage = appConfig.settings.language.currentIso;
    const isHidden =
      !!appConfig.settings.consent && !appConfig.settings.isCookieBannerClosed;

    // systemPages is only 404 pages - if this changes another way of identifying 404 pages is needed.
    const page404 = systemPages.find(
      (page) =>
        page.language === this.props.appConfig.settings.language.current?.name,
    );

    appConfig.relatedPagesInfo.allPages = pages;

    return (
      <DefaultTemplate
        showDevMenu={appSettings.editorToolsEnabled}
        environment={appSettings.contentful.environment}
      >
        <div className="normal-page-container">
          <Switch>
            {urlContainsUppercase(history.location.pathname) && (
              <Redirect to={history.location.pathname.toLowerCase()} />
            )}
            {pages.map((page) => (
              <Route
                key={`page-${page.slug.page}`}
                exact
                sensitive
                path={getPageUrlFromPage(page)}
              >
                <>
                  <Menu
                    menuItems={root.menuItems}
                    language={this.props.appConfig.settings.language.current}
                    currentPage={page}
                    currentPageAlternates={currentPageAlternates}
                  />
                  <Page page={page} />
                </>
              </Route>
            ))}
            {pages.map((page) => (
              <Route
                key={`page-no-lang-${page.slug.page}`}
                exact
                path={getPageUrlWithoutLanguageFromPage(page, isoLanguage)}
              >
                <Redirect to={getPageUrlFromPage(page)} />
              </Route>
            ))}
            <Route
              key="404"
              exact
              path="*"
              render={(props) => (
                <NotFound
                  {...props}
                  page404={page404}
                  menuItems={root.menuItems}
                  currentLanguage={
                    this.props.appConfig.settings.language.current
                  }
                />
              )}
            />
          </Switch>
          <Footer
            root={root}
            onClick={appConfig.eventHandlers.onCookieSettingsClick}
          />
        </div>
        <Route
          key="modal-page"
          exact
          path="*"
          render={(routeProps) => ModalPage(routeProps, modalPages)}
        />
        <CookieConsentBanner
          hidden={isHidden}
          backgroundColor={colorEnum.black}
          consentMessage={root.cookie.message}
          cookiePolicyVersion={root.cookie.policyVersion || 'no-version'}
          acceptAllButtonText={root.cookie.acceptAllButtonLabel}
          acceptNecessaryButtonText={root.cookie.acceptNecessaryButtonLabel}
          saveSettingsButtonText={root.cookie.saveSettingsButtonLabel}
          language={isoLanguage}
          consent={appConfig.settings.consent}
        />
      </DefaultTemplate>
    );
  }
}

export default compose(
  withAppConfig,
  withRouter,
  withLoadingContext,
)(RootComponent);
