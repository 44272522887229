import React from 'react';

function wrap(Context, key: string, WrappedComp) {
  return class Connected<P, S> extends React.Component<P, S> {
    static displayName = `${
      WrappedComp.displayName || WrappedComp.name || 'Component'
    } (${
      Context.Consumer.displayName ||
      Context.Consumer.name ||
      'Context.Consumer'
    }.${key})`;

    render(): React.ReactNode {
      const { props } = this;
      return (
        <Context.Consumer>
          {(context) => <WrappedComp {...{ [key]: context, ...props }} />}
        </Context.Consumer>
      );
    }
  };
}

export function withContext(Context, ...rest) {
  const key =
    // eslint-disable-next-line prefer-rest-params
    arguments.length > 1 && arguments[1] !== undefined
      ? // eslint-disable-next-line prefer-rest-params
        arguments[1]
      : 'context';

  return function (WrappedComp) {
    return wrap(Context, key, WrappedComp);
  };
}

export function withMultiContext(map) {
  return function (WrappedComp) {
    return Object.keys(map).reduce(
      (Comp, key) => wrap(map[key], key, Comp),
      WrappedComp,
    );
  };
}
