import { Component } from 'react';
import { compose } from 'recompose';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FormControl } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import * as EmailValidator from 'email-validator';
import hammerAndWrenchSrc from '../../../assets/hammer_and_wrench.png';
import { colorEnum } from '../../../constants/colors';
import withAppConfig, {
  WithAppConfigProps,
} from '../../../utils/hocs/withAppConfig';
import emailTypes from '../../../server-utils/express-middleware/email-middleware/email-types';

const useStyles = {
  zIndex: 1003,
  transition: 'opacity .2s ease-in-out',
  '& .dropdown-toggle': {
    backgroundColor: colorEnum.white,
  },
  '.devButton': {
    opacity: 0.3,
  },
  '.devButton:hover': {
    opacity: 1,
  },
  '.devButton.show': {
    opacity: 1,
    '.dropdown-toggle': {
      img: {
        filter: 'invert(1)',
      },
    },
  },
  '& .btn-primary, & .btn-primary.dropdown-toggle, & .btn-primary.dropdown-toggle:not(:disabled):not(.disabled):active':
    {
      color: colorEnum.black,
      borderColor: colorEnum.white,
    },
  '& .dropdown-item.active': {
    color: colorEnum.black,
    backgroundColor: colorEnum.brightGray,
    borderLeft: `${colorEnum.blue} 3px solid`,
    paddingLeft: '21px',
  },
  '& .sub-dropdown': {
    marginLeft: '10px',
  },
  '& .btn-secondary': {
    color: colorEnum.black,
    borderColor: colorEnum.white,
  },
  '& .btn-secondary.dropdown-toggle': {
    color: colorEnum.black,
    backgroundColor: colorEnum.white,
    borderColor: colorEnum.white,
  },
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type DevMenuProps = WithAppConfigProps;

interface IDevMenuState {
  devEmail: string;
  devEmailType: string;
}

class DevMenu extends Component<DevMenuProps, IDevMenuState> {
  constructor(props) {
    super(props);
    this._onContentfulEnvironmentClick =
      this._onContentfulEnvironmentClick.bind(this);
    this._onContentfulPreviewChange =
      this._onContentfulPreviewChange.bind(this);
    this._onClearCache = this._onClearCache.bind(this);
    const initialState = {
      devEmail: '',
      devEmailType: emailTypes.BOOK,
    };
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(email, emailType) {
    if (!EmailValidator.validate(email)) {
      console.log(
        `Invalid email address supplied to ${this.constructor.name} '${email}'`,
      );
      return false;
    }
    this._sendDevEmail(email, emailType);
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  _sendDevEmail(mailTo, emailType) {
    const url =
      typeof window !== 'undefined' ? `${window.location.origin}/mail` : '';
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mailTo,
        emailType,
        name: 'John Doe',
        email: 'johndoe@email.com',
        date: '1/1/2001',
        participants: '4',
        msg: 'This is a sample message for a contact email',
        links: [
          {
            text: 'Dummy pdf',
            url: 'https://www.novataris.com/en-US/downloads/5HLW8kqgLKolXSdOOwnM9C/downloadable/dummy.pdf',
          },
          {
            text: 'Dummy pdf',
            url: 'https://www.novataris.com/en-US/downloads/5HLW8kqgLKolXSdOOwnM9C/downloadable/dummy.pdf',
          },
        ],
        mailContent: {
          subject: 'test email',
          title:
            "Tak for din interesse i vores indlæg på EnterPrise Architecture data '23",
          description:
            'Nedenfor finder du materialet som vi gennemgik på dagen eller som har øvrig relevans',
          articleHeader: 'Andre artikler',
        },
      }),
    });
  }

  _onContentfulEnvironmentClick(event, environment) {
    this.props.appConfig.eventHandlers.onChangeContentfulEnvironment(
      environment,
    );
  }

  _onContentfulPreviewChange(event) {
    this.props.appConfig.eventHandlers.onTogglePreview();
  }

  _onClearCache() {
    this.props.appConfig.connectors.self.resetCache();
  }

  render() {
    const { appConfig } = this.props;
    const appSettings = appConfig.settings;
    const devMenuButtonLabel =
      capitalizeFirstLetter(appSettings.contentful.environment) +
      (appSettings.contentful.isPreview ? ' (Preview)' : '');
    const emailTypeValues = Object.values(emailTypes);

    return (
      <div css={useStyles}>
        <Dropdown className="devButton">
          <Dropdown.Toggle
            id="dropdownButton"
            size="sm"
            title={devMenuButtonLabel}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={hammerAndWrenchSrc} height="20" width="20" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>
              Novaweb build: {appConfig.static.buildVersion}
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Header>Environment</Dropdown.Header>
            {appSettings.contentful.environments.map((environment, idx) => (
              <Dropdown.Item
                onClick={(event) =>
                  this._onContentfulEnvironmentClick(event, environment)
                }
                eventKey={idx}
                /* eslint-disable-next-line react/no-array-index-key */
                key={`environment-${idx}`}
                active={environment === appSettings.contentful.environment}
              >
                {capitalizeFirstLetter(environment)}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Header>View mode</Dropdown.Header>
            <Dropdown.Item eventKey="view-mode">
              <FormCheck
                id="preview-switch"
                label="Preview"
                checked={appSettings.contentful.isPreview}
                onChange={this._onContentfulPreviewChange}
              />
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Cache</Dropdown.Header>
            <Dropdown.Item as="button" onClick={this._onClearCache}>
              Clear server
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Links</Dropdown.Header>
            <Dropdown.Item eventKey="5" href="/storybook">
              Storybook
            </Dropdown.Item>
            <DropdownButton
              className="sub-dropdown"
              key="right"
              id="dropdown-button-drop-right"
              drop="right"
              variant="secondary"
              title="Sample Email"
            >
              <Dropdown.Header>Enter Email Address of Receiver</Dropdown.Header>
              <Dropdown.Header>
                <FormControl
                  id="email-form"
                  type="text"
                  pattern="[^@\s]+@[^@\s]+\.+[^@\s]+"
                  required
                  placeholder="Enter Email Address"
                  onChange={(e) => this.setState({ devEmail: e.target.value })}
                  value={this.state.devEmail}
                />
              </Dropdown.Header>
              <Dropdown.Header>Choose Email Type</Dropdown.Header>

              <DropdownButton
                className="sub-dropdown"
                key="right"
                id="dropdown-button-drop-right"
                drop="right"
                variant="secondary"
                title={capitalizeFirstLetter(this.state.devEmailType)}
              >
                {emailTypeValues.map((value, index) => (
                  <Dropdown.Item
                    type="button"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => {
                      this.setState({ devEmailType: value });
                    }}
                  >
                    {capitalizeFirstLetter(value)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <Dropdown.Divider />
              <Dropdown.Item
                type="button"
                onClick={() => {
                  this.handleSubmit(
                    this.state.devEmail,
                    this.state.devEmailType,
                  );
                }}
              >
                Send Sample Email
              </Dropdown.Item>
            </DropdownButton>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default compose<WithAppConfigProps, Record<string, never>>(
  withAppConfig,
)(DevMenu);
