export const colorEnum = {
  black: '#141C21',
  white: '#FFFFFF',
  blue: '#3D8AF9',
  gray: '#BEBEBE',
  lightGray: '#FAF9F8',
  brightGray: '#EAEAEA',
  lightBlue: '#71ABFF',
  darkGrey: '#626567',
  green: '#32A237',
  paleGreen: '#EAFFBB',
  yellow: '#FFFF00',
  red: '#FF0000',
  whiteSmoke: '#F5F5F5',
  borderGray: '#484848',
  orange: '#FFA500',
  gray2: '#D9D9D94D',
  gray3: '#B3B6B7',
  lightGray2: '#E8EBED',
  shadow10: '#0000001A',
  shadow15: '#00000026',
  shadow20: '#00000033',
  blueShadow: '#71ABFF33',
} as const;

export const defaultColors = {
  header: colorEnum.black,
  lightHeader: colorEnum.gray3,
  bodyText: colorEnum.black,
  linkHover: colorEnum.paleGreen,
  lineBreak: colorEnum.lightGray2,
} as const;
