import { Component } from 'react';
import { css } from '@emotion/react';

import KeyFigure from './common/molecules/KeyFigure';
import Hstack3CellExtremity from './common/atoms/Hstack3CellExtremity';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Grid2CellUnevenSplit from './common/atoms/Grid2CellUnevenSplit';
import GridHorizontalSnap2 from './common/atoms/GridHorizontalSnap2';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  KeyFigureGridTypeEnum,
  TypeBackgroundColorTypes,
  TypeFontColorTypes,
  TypeKeyFigureGridTypes,
} from '../constants/cms-constants';

const styles = css({
  '& .hstack-3-cell-extremety': {
    maxWidth: '304px',
    width: '100%',
  },
  '& .outergrid': {
    '&.horizontal': {
      marginTop: '60px',
      textAlign: 'center',
      '& .col:nth-of-type(3), & .col:nth-of-type(4)': {
        [Breakpoints.Mobile.mq]: {
          marginTop: '30px',
        },
      },
    },
    '&.two-columns': {
      '& .key-figure-element': {
        width: '95px',
        marginTop: '30px',
        '& .key-figure': {
          opacity: '0.24',
        },
      },
    },
  },
});

function getPairs(items) {
  const pairs = [];
  let i = 0;
  for (i; i < items.length; i += 2) {
    pairs.push([items[i], items[i + 1]]);
  }
  return pairs;
}
type KeyFigureGridComponentProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
  gridType?: TypeKeyFigureGridTypes;
  backgroundColor?: TypeBackgroundColorTypes;
  fontColor?: TypeFontColorTypes;
};

class KeyFigureGridComponent extends Component<KeyFigureGridComponentProps> {
  static defaultProps = {
    mediaItems: [],
    backgroundColor: null,
    fontColor: null,
    gridType: KeyFigureGridTypeEnum.TWO_COLUMNS,
  };

  render() {
    const { backgroundColor, fontColor, gridType, mediaItems } = this.props;
    const pairs = getPairs(mediaItems);
    const addBackgroundColor = backgroundColor
      ? { background: backgroundColor }
      : {};
    const addFontColor = fontColor
      ? { '& .key-figure': { color: fontColor } }
      : {};

    if (gridType === KeyFigureGridTypeEnum.HORIZONTAL) {
      return (
        <div
          className="key-figure-grid-component"
          css={[styles, addBackgroundColor, addFontColor]}
        >
          <ContentOuterBound>
            <ContentSpacer>
              <GridHorizontalSnap2
                className="outergrid horizontal"
                onRenderCells={mediaItems.map((item) => () => (
                  <KeyFigure measure={item.header} value={item.lightHeader} />
                ))}
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      );
    }
    if (gridType === KeyFigureGridTypeEnum.TWO_COLUMNS) {
      return (
        <div
          className="key-figure-grid-component"
          css={[styles, addBackgroundColor, addFontColor]}
        >
          <ContentOuterBound>
            <ContentSpacer>
              <Grid2CellUnevenSplit
                leftCellCount={7}
                rightCellCount={5}
                className="outergrid two-columns"
                snapAtBreakpoint={Breakpoints.MobileDown}
                onRenderCell1={() => null}
                onRenderCell2={() =>
                  pairs.map((pair, index) => (
                    /* eslint-disable react/no-array-index-key */
                    <div
                      className={`key-figure-row-${index}`}
                      key={`key-figure-row-${index}`}
                    >
                      <Hstack3CellExtremity
                        onRenderLeftCell={() => (
                          <div className="key-figure-element">
                            <KeyFigure
                              measure={pair[0].header}
                              value={pair[0].lightHeader}
                            />
                          </div>
                        )}
                        onRenderRightCell={() =>
                          pair[1] && (
                            <div className="key-figure-element">
                              <KeyFigure
                                measure={pair[1].header}
                                value={pair[1].lightHeader}
                              />
                            </div>
                          )
                        }
                      />
                    </div>
                    /* eslint-enable */
                  ))
                }
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      );
    }
    return null;
  }
}

export default KeyFigureGridComponent;
