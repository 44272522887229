/* eslint-disable max-classes-per-file */
import { Subject, Subscription } from 'rxjs';
import DependencyInput from '../../utils/reporterInput/DependencyInput';
import ErrorInput from '../../utils/reporterInput/ErrorInput';

export default class BaseConnector {
  subject = new Subject<DependencyInput | ErrorInput>();

  constructor() {
    this.afterFetch = this.afterFetch.bind(this);
  }

  subscribeToFetches(onEvent: (DependencyInput) => void): Subscription {
    return this.subject.asObservable().subscribe(onEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  unsubscribeToFetches(subscription: Subscription) {
    subscription.unsubscribe();
  }

  protected afterFetch(dependencyInput: DependencyInput | ErrorInput) {
    this.subject.next(dependencyInput);
  }
}
