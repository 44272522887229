import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';
import RichTextWrapper from '../RichTextWrapper';

import * as InternalPropTypes from '../../../constants/internal-types';

const style = css({
  '& .sub-header-field': {
    opacity: 0.3,
  },
  '& .body-text-field': {
    marginTop: '10px',
    opacity: 0.5,
    whiteSpace: 'break-spaces',
  },
  '& .body-text-field:hover': {
    cursor: 'pointer',
    textDecorationLine: 'underline',
  },
});

type TextButtonProps = {
  settings?: InternalPropTypes.MediaItem;
  className?: string;
  onClick?: () => void;
};

class TextButton extends Component<TextButtonProps> {
  static defaultProps = {
    settings: null,
    className: null,
    onClick: null,
  };

  render() {
    const { settings, className, onClick } = this.props;

    return (
      <div css={style} className={className}>
        <BodyText type={BodyText.types.LIGHT14} className="sub-header-field">
          {settings && (
            <RichTextWrapper
              richText={settings.header}
              renderFunction={RichTextRenderer.regularTextOnly}
            />
          )}
        </BodyText>
        <BodyText
          type={BodyText.types.LIGHT12}
          className="body-text-field"
          onClick={onClick}
        >
          {settings && settings.bodyText}
        </BodyText>
      </div>
    );
  }
}

export default TextButton;
