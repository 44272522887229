import { Component } from 'react';

import Toggle from '../atoms/Toggle';

import { fontColorEnum } from '../../../constants/cms-constants';

const styles = {
  '&.toggle-container': {
    marginLeft: '0px',
    display: 'flex',
    '& .toggle-caption': {
      width: '180px',
      color: fontColorEnum.WHITE,
    },
    '& .toggle-button': {
      zoom: '80%',
    },
  },
};

type CookieSettingsProps = {
  disabled?: boolean;
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  cookieTypeText?: string;
  value?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: any[]) => any;
};

class CookieSettings extends Component<CookieSettingsProps> {
  static defaultProps = {
    disabled: false,
    name: null,
    cookieTypeText: null,
    value: false,
  };

  render() {
    const { disabled, name, cookieTypeText, value, onChange } = this.props;

    return (
      <div className="toggle-container" css={styles}>
        <div className="toggle-caption">{cookieTypeText && cookieTypeText}</div>
        <div className="toggle-button">
          <Toggle
            disabled={disabled}
            name={name}
            onChange={onChange}
            checked={value}
          />
        </div>
      </div>
    );
  }
}

export default CookieSettings;
