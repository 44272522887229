import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import { getStringFromRichText } from '../../utils/content/RichTextConverter';
import { ContentTypeEnum } from '../../constants/cms-constants';
import OEmbed from '../../components/OEmbed';

class OEmbedding extends Component<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;

    let link;
    blockContent.doQuery((content) => {
      link = content.getFirstOfType(ContentTypeEnum.LINK);
    });

    const loadingAltText = getStringFromRichText(block?.header?.light);

    return <OEmbed contentUrl={link.url} loadingAltText={loadingAltText} />;
  }
}

export default OEmbedding;
