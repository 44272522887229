import { Heuristics, HeuristicsResponseType } from './Heuristics';
import {
  STATUS_ERROR,
  STATUS_OKAY,
  STATUS_WARNING,
} from '../../constants/seoStatus';
import 'core-js/es/promise';
import { Page, Root } from '../../constants/internal-types';

export default class PageTitleHeuristic extends Heuristics {
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let result: HeuristicsResponseType[] = [];
      if (page && root) {
        const title = page.seo?.title;
        const allPages = root.pages;

        const response = {
          name: 'Page SEO title',
          generalText: this.getGeneralText(),
          title: this.getTitleOfPage(title),
          path: this.getFixPathText(page),
        } as HeuristicsResponseType;

        if (!title) {
          response.error = 'This page does not have a SEO title';
          response.status = STATUS_ERROR;
        } else if (
          allPages?.filter((page) => page?.seo?.title === title).length !== 1
        ) {
          response.error = 'This page does not have a unique SEO title.';
          response.status = STATUS_ERROR;
        } else if (title.length < 10) {
          response.error =
            'This page has a shorter SEO title than recommended.';
          response.status = STATUS_WARNING;
        } else if (title.length > 70) {
          response.error = 'This page has a longer SEO title than recommended.';
          response.status = STATUS_WARNING;
        } else if (!this.isUpperCase(title[0])) {
          response.error =
            'This page has a SEO title that is not capitalized or starts with a number.';
          response.status = STATUS_WARNING;
        } else {
          response.status = STATUS_OKAY;
        }
        result = [response];
      }
      resolve(result);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        The title is the main and most important anchor of a site. The title
        element is what appears as a clickable headline in the search engines,
        social networks and in browsers. This is the first thing a user will see
        and has to convince the user that the page is likely to answer the
        search intent. A well written title is one of the biggest steps to
        increase web traffic. Best practises for a title are:
        <ul>
          <li>
            {' '}
            Give each page a unique title that describes the page&apos;s content
            concisely and accurately.
          </li>
          <li>
            {' '}
            Keep titles between 10 and 70 characters. Note that this title is
            also used for Twitter cards, where it is truncated at 70 characters.
          </li>
          <li>
            {' '}
            Put important keywords first, but in a natural manner, as the titles
            are primarily for visitors.
          </li>
          <li>
            {' '}
            Make use of the brands name in the title. Even if this is not shown,
            it will still make a difference for the search engine.
          </li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getTitleOfPage(title) {
    if (title) {
      return `This page has the SEO title "${title}".`;
    }
    return 'This page has no SEO title.';
  }

  // eslint-disable-next-line class-methods-use-this
  getFixPathText(mappedData) {
    return `To fix this in Contentful go to "${this.getFixPath(
      mappedData,
    )}" and edit this field.`;
  }

  // eslint-disable-next-line class-methods-use-this
  getFixPath(mappedData) {
    return `Pages/${mappedData.name}/PageTitleInBrowser(seo)`;
  }
}
