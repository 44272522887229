import { Component } from 'react';
import { css } from '@emotion/react';

const styles = css({
  height: '100%',
  '.sticky-column-inner': {
    top: 0,
    position: 'sticky',
  },
});

type StickyColumnProps = {
  className?: string;
  children?: React.ReactNode;
};

class StickyColumn extends Component<StickyColumnProps> {
  static defaultProps = {
    className: null,
    children: null,
  };

  render() {
    const { children, className } = this.props;

    return (
      <div
        css={styles}
        className={`sticky-column${className ? ` ${className}` : ''}`}
      >
        <div className="sticky-column-inner">{children}</div>
      </div>
    );
  }
}

export default StickyColumn;
