import { Heuristics, HeuristicsResponseType } from './Heuristics';
import generateEditorLinkToEntry from '../contentful';
import {
  STATUS_OKAY,
  STATUS_WARNING,
  STATUS_ERROR,
} from '../../constants/seoStatus';
import NwLink from '../../components/common/NwLink';
import * as seoUtil from '../../utils/seo';
import { Image as ImageType, Page, Root } from '../../constants/internal-types';

export default class PageSharingTwitterHeuristic extends Heuristics {
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    return new Promise((resolve, reject) => {
      if (page && root) {
        const twImage = seoUtil.getTwitterImage(page);
        const trImageUrl = seoUtil.getTwitterImageUrl(
          page,
          this.imageHelperFactory,
        );

        const { aspectRatio } = seoUtil.getImageMetaData(
          twImage,
          this.imageHelperFactory,
        );

        const twitterImage = new Image();
        twitterImage.src = trImageUrl;

        twitterImage.onload = () => {
          const response = {
            name: 'Twitter card',
            generalText: this.getGeneralText(),
            path: this.getFixImagePathText(page),
            title: this.getImageSourceText(twImage, page, trImageUrl),
          } as HeuristicsResponseType;

          /* The below throws at most one error, with the logic being that if e.g., file size is too large the dimensions
            input image do not matter. Similarly it will never complain about issues with the default card, which should
            be validated separately. */
          if (!/\.(jpg|png|webp|gif)/i.test(twitterImage.src)) {
            response.status = STATUS_ERROR;
            response.error =
              'This page uses an unsupported filetype for its Twitter image. Supported filetypes are jpg, png, webp, or gif.';
          } else if (
            twImage === page.featuredImage &&
            (this.imageHelperFactory
              .createImageHelper(twImage)
              .getImageSizeInBytes() || 0) >
              1024 * 1024 * 5
          ) {
            response.status = STATUS_ERROR;
            response.error =
              'This page uses a Twitter image with a file size exceeding the maximum of 5 MB.';
          } else if (
            !(
              twitterImage.height > 157 &&
              twitterImage.height < 4097 &&
              twitterImage.width > 300 &&
              twitterImage.width < 4097
            )
          ) {
            response.status = STATUS_ERROR;
            response.error =
              'This page uses a Twitter image which is outside of the allowed image pixel size range and it will therefore' +
              'be either stretched or cropped. Twitter images must be from 144x144px to 4096x4096px.';
          } else if (twImage !== page.featuredImage) {
            response.status = STATUS_OKAY;
            response.error =
              'This page uses the default image for Twitter. Note that this image may be cropped and should be validated separately.';
          } else if (!(aspectRatio > 1.95 && aspectRatio < 2.05)) {
            response.status = STATUS_WARNING;
            response.error =
              'This page uses an image that has an aspect ratio different from 2:1 which will therefore be cropped on twitter cards.';
          } else if (!aspectRatio) {
            response.status = STATUS_WARNING;
            response.error =
              'The aspect ratio could not be found and might be unfit. Use the image cropper to make the sure the image has the correct aspect ratio and size.';
          } else {
            response.status = STATUS_OKAY;
          }
          resolve([response]);
        };

        twitterImage.onerror = () => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`failed to load image at ${twitterImage.src}`);
        };
      } else {
        resolve([]);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        Twitter cards allows you to control how the page looks when shared
        Twitter. The three important parts of such a card are the SEO title, the
        SEO description and the image selected to represent the page. The SEO
        title and description and their best practises are described in great
        detail in the Core Meta Content. We use large twitter cards so the image
        should have an aspect ratio of 2:1. It should be specific to the current
        page, especially if this page is likely to be shared online. Best
        practises for a Twitter image are:
        <ul>
          <li>
            {' '}
            Select an image specifically for pages that are likely to be shared.{' '}
          </li>
          <li> Pick a good and fitting default image. </li>
          <li>
            {' '}
            Validate the twitter cards once they are done using eg.
            <NwLink to="https://cards-dev.twitter.com/validator">
              {' '}
              Twitter card validator{' '}
            </NwLink>
            .
          </li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getImageSourceText(twImage: ImageType, page: Page, imgSrc: string) {
    return (
      <span>
        The image used is the {/* eslint-disable-next-line no-nested-ternary */}
        {twImage === page?.defaultTwitterImage ? (
          <>default twitter image configured on the Root page</>
        ) : twImage === page?.featuredImage ? (
          <>
            <NwLink
              to={generateEditorLinkToEntry(
                this.appSettings,
                page?.contentfulEntryId,
              )}
            >
              page
            </NwLink>{' '}
            Twitter image
          </>
        ) : (
          <>
            basic <NwLink to={imgSrc}>image</NwLink> in the solution
          </>
        )}
      </span>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixImagePathText(mappedData) {
    return (
      <div>
        To fix this in Contentful click{' '}
        <NwLink to={this.getFixImagePath(mappedData)}> here </NwLink> and upload
        another image in the &quot;Twitter image&quot; section.
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixImagePath(mappedData) {
    const entryId = mappedData.contentfulEntryId;
    return generateEditorLinkToEntry(this.appSettings, entryId);
  }
}
