import { Heuristics, HeuristicsResponseType } from './Heuristics';
import generateEditorLinkToEntry from '../contentful';
import {
  STATUS_OKAY,
  STATUS_WARNING,
  STATUS_ERROR,
} from '../../constants/seoStatus';
import OpenGraphPreview from '../../components/common/molecules/OpenGraphPreview';
import HeuristicResponseTemplate from '../../components/common/molecules/HeuristicResponseTemplate';
import { getRtOneParagraph } from '../content/RichTextConverter';
import NwLink from '../../components/common/NwLink';
import { Page, Root } from '../../constants/internal-types';
import * as seoUtil from '../../utils/seo';
import * as InternalPropTypes from '../../constants/internal-types';

export default class PageSharingOpenGraphHeuristic extends Heuristics {
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    return new Promise((resolve, reject) => {
      if (page && root) {
        const ogImage = seoUtil.getOpenGraphImage(page);
        const openGraphImage = new Image();
        openGraphImage.src =
          this.imageHelperFactory.createImageHelper(ogImage)?.getImageUrl() ||
          '';

        const { aspectRatio, sizeInBytes } = seoUtil.getImageMetaData(
          ogImage,
          this.imageHelperFactory,
        );

        openGraphImage.onload = () => {
          const response = {
            name: 'Facebook/LinkedIn card',
            generalText: this.getGeneralText(),
            title: this.getImageSourceText(openGraphImage.src, page),
            path: this.getFixImagePathText(page),
          } as HeuristicsResponseType;

          if (
            ogImage === page.featuredImage &&
            (sizeInBytes || 0) > 1024 * 1024 * 5
          ) {
            response.status = STATUS_ERROR;
            response.error =
              'This page uses a picture with a file size exceeding the maximum of 5 MB prescribed by LinkedIn.';
          } else if (aspectRatio > 1.93 || aspectRatio < 1.89) {
            response.status = STATUS_WARNING;
            response.error =
              'This page uses an image that is not 1.91:1, which is the recommended ratio for Facebook and LinkedIn.';
          } else if (!aspectRatio) {
            response.status = STATUS_WARNING;
            response.error =
              'The aspect ratio could not be found. Use the image cropper to make the sure the image has the correct aspect ratio and size.';
          } else if (
            openGraphImage.width < 1200 ||
            openGraphImage.height < 627
          ) {
            response.status = STATUS_WARNING;
            response.error =
              'This page uses an image with suboptimal resolution. A resolution of at least 1200x627 pixels is preferred.';
          } else if (ogImage === page.defaultOpenGraphImage) {
            response.status = STATUS_OKAY;
            response.error =
              'This page does not have a dedicated teaser image. It will fallback to the root teaser.';
          } else {
            response.status = STATUS_OKAY;
          }

          response.display = this.getResponseDisplay(ogImage, page, response);
          return resolve([response]);
        };

        openGraphImage.onerror = (e) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`Failed to load image at ${openGraphImage.src}`);
        };
      } else {
        resolve([]);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        Open Graph cards allows you to control how the page look when shared on
        social media such as Facebook and LinkedIn. An Open Graph image should
        have an aspect ratio of 1.91 with the ideal size being 1200x627px, and
        it should be specific to the current page, especially if this page is
        likely to be shared online. Best practises for a Open Graph image are:
        <ul>
          <li>
            Select an image specifically for pages that are likely to be shared.
          </li>
          <li>Pick a good and fitting default image.</li>
          <li>Validate the Open Graph cards in the previewer above.</li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getResponseDisplay(
    image: InternalPropTypes.Image,
    mappedData: Page,
    response: HeuristicsResponseType,
  ) {
    return (
      <>
        <OpenGraphPreview
          image={image}
          postTitle={getRtOneParagraph(mappedData?.seo?.title)}
          postProvider={getRtOneParagraph('novataris.com')}
          errorText={getRtOneParagraph('')}
          warningText={getRtOneParagraph('')}
          caption={getRtOneParagraph(
            'This is what the page looks like when shared on LinkedIn.',
          )}
          indicator={getRtOneParagraph('')}
        />
        <HeuristicResponseTemplate
          title={response.title}
          errorMessage={response.error}
          path={response.path}
          description={response.generalText}
        />
      </>
    );
  }

  getImageSourceText(ogImageSrc: string, page: Page) {
    return (
      <span>
        The image used is the{' '}
        {ogImageSrc ===
        this.imageHelperFactory
          .createImageHelper(page?.defaultOpenGraphImage)
          ?.getImageUrl() ? (
          <>default Open graph image configured on the Root</>
        ) : (
          <>
            <NwLink
              to={generateEditorLinkToEntry(
                this.appSettings,
                page?.contentfulEntryId,
              )}
            >
              page
            </NwLink>{' '}
            Open Graph image
          </>
        )}
      </span>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixImagePathText(mappedData: Page) {
    return (
      <div>
        To fix this in Contentful click{' '}
        <NwLink to={this.getFixImagePath(mappedData)}> here </NwLink> and upload
        another image in the &quot;Open Graph teaser&quot; section.
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixImagePath(mappedData: Page) {
    const entryId = mappedData?.contentfulEntryId;
    return generateEditorLinkToEntry(this.appSettings, entryId);
  }
}
