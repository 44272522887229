import { BlockInstanceProps } from '../Block';
import ProfileGrid from '../../components/ProfileGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'profileGridType',
    definition: controlOptionDefinitions.profileGridTypes,
  },
])
class ProfileGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static profileGridTypes = ProfileGrid.profileGridTypes;

  render() {
    const { blockContent } = this.props;
    const { backgroundColor, profileGridType } = this.controlOptions;

    let persons;
    blockContent.doQuery((content) => {
      persons = content.getAllOfType(ContentTypeEnum.PERSON);
    });

    return (
      <ProfileGrid
        persons={persons}
        backgroundColor={backgroundColor}
        profileGridType={profileGridType}
      />
    );
  }
}

export default ProfileGridBlock;
