import { BlockInstanceProps } from '../Block';
import SectionTop from '../../components/SectionTop';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';
import { BodyTextWidthEnum } from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'bodyTextWidth',
    definition: controlOptionDefinitions.bodyTextWidth,
  },
  {
    key: 'seoWeight',
    definition: controlOptionDefinitions.SEOWeightTypes,
  },
])
class SectionTopBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const bodyTextWidth = BodyTextWidthEnum[controlOptions?.bodyTextWidth];
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const long = controlOptions?.long;
    const { seoWeight } = this.controlOptions;

    return (
      <SectionTop
        lightHeaderText={block?.header?.light}
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        link={block?.link}
        bodyTextWidth={bodyTextWidth}
        backgroundColor={backgroundColor}
        seoWeight={seoWeight}
      />
    );
  }
}

export default SectionTopBlock;
