// Component just like VimeoEmbedding, but with a different name and different props.
import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import OEmbed from './common/atoms/OEmbed';
import ContentSpacer from './common/atoms/ContentSpacer';
import getValidatedOEmbedEntry from '../utils/OEmbedLinkResolver';
import * as Breakpoints from '../constants/breakpoints';

const styles = {
  '& .vimeo-container': {
    [Breakpoints.DesktopUp.mq]: {
      height: '675px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '500px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '280px',
    },
    margin: 'auto',
  },
  '& .youtube-container': {
    margin: 'auto',
    aspectRatio: '16/9',
  },
  '& .soundcloud-container': {
    margin: 'auto',
  },
};

type OEmbeddingProps = {
  contentUrl: string;
  loadingAltText?: string;
};

class OEmbedding extends Component<OEmbeddingProps> {
  static defaultProps = {
    loadingAltText: '',
  };

  render() {
    const { contentUrl, loadingAltText } = this.props;

    const OEmbedEntry = getValidatedOEmbedEntry(contentUrl);

    return (
      <div className="oembed-embedding-component" css={styles}>
        <ContentOuterBound>
          <ContentSpacer>
            {OEmbedEntry ? (
              <OEmbed
                OEmbedURL={`${OEmbedEntry.urlPrefix}${contentUrl}`}
                type={OEmbedEntry.type}
                loadingAltText={loadingAltText}
              />
            ) : (
              <div>Unsupported Embedded Content</div>
            )}
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default OEmbedding;
