import { CreateClientParams } from 'contentful';
import * as Language from '../constants/language';
import { ConfigVariable, ConfigBase } from './StaticConfig';
import Consent from '../utils/Consent';
import CookieService from './CookieService';

export const ENDPOINT_CONTENT = 'cdn.contentful.com';
export const ENDPOINT_PREVIEW = 'preview.contentful.com';

const getEnvironment = (cookieService, staticConfig) => {
  const contentfulEnvironment = cookieService.getEnvironment();
  const environments = staticConfig.get(ConfigVariable.ContentfulEnvironments);
  return contentfulEnvironment && environments.includes(contentfulEnvironment)
    ? contentfulEnvironment
    : environments[0];
};

export const generateContentfulSettings = (
  cookieService,
  staticConfig,
  isPreview,
) => {
  const environment = getEnvironment(cookieService, staticConfig);
  const space = staticConfig.get(ConfigVariable.ContentfulSpaceId);
  const host = isPreview ? ENDPOINT_PREVIEW : ENDPOINT_CONTENT;
  const accessToken = staticConfig.get(
    isPreview
      ? ConfigVariable.ContentfulAccessTokenPreview
      : ConfigVariable.ContentfulAccessTokenContent,
  );
  return {
    host,
    environment,
    space,
    accessToken,
  };
};

type AppSettingsConstrutorArgs = {
  isCookieBannerClosed: boolean;
  staticConfig: ConfigBase;
  cookieService: CookieService;
  isoLanguage: string;
  consent: Consent | null;
};

export default class AppSettings {
  public isCookieBannerClosed: boolean;

  public editorToolsEnabled: boolean;

  public azureStorageAccount: string;

  public language: {
    current: Language.LanguageType | undefined;
    currentIso: string;
  };

  public contentful: {
    isPreview: boolean;
    spaceId: string;
    environments: Array<string>;
    environment: string;
    current: { content: CreateClientParams; preview: CreateClientParams };
  };

  public consent: Consent;

  constructor({
    staticConfig,
    cookieService,
    isoLanguage,
    consent,
    isCookieBannerClosed,
  }: AppSettingsConstrutorArgs) {
    this.isCookieBannerClosed = isCookieBannerClosed;
    this.editorToolsEnabled = staticConfig.get(
      ConfigVariable.EditorToolsEnabled,
    );
    this.azureStorageAccount = staticConfig.get(
      ConfigVariable.AzureStorageAccount,
    );
    const isPreview = cookieService.getPreview() && this.editorToolsEnabled;
    const environments = staticConfig.get(
      ConfigVariable.ContentfulEnvironments,
    );
    const environment = getEnvironment(cookieService, staticConfig);
    const space = staticConfig.get(ConfigVariable.ContentfulSpaceId);
    this.language = {
      current: Language.LANGUAGES.find((l) => l.iso === isoLanguage),
      currentIso: isoLanguage,
    };
    this.contentful = {
      isPreview,
      spaceId: space,
      environments,
      environment,
      current: {
        content: generateContentfulSettings(cookieService, staticConfig, false),
        preview: generateContentfulSettings(cookieService, staticConfig, true),
      },
    };
    this.consent = consent;
  }
}
