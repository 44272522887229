import { Component } from 'react';
import { css } from '@emotion/react';

const useStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .item + .item': {
    marginTop: '2%',
  },
  '& .item-center': {
    flexGrow: 1,
  },
  '& .item': {},
});

type Vstack3CellExtremityProps = {
  onRenderTopCell?: (...args: any[]) => any;
  onRenderMiddleCell?: (...args: any[]) => any;
  onRenderBottomCell?: (...args: any[]) => any;
  className?: string;
};

class Vstack3CellExtremity extends Component<Vstack3CellExtremityProps> {
  static defaultProps = {
    onRenderTopCell: () => null,
    onRenderBottomCell: () => null,
    onRenderMiddleCell: () => null,
    className: undefined,
  };

  render() {
    const {
      onRenderTopCell,
      onRenderBottomCell,
      onRenderMiddleCell,
      className,
    } = this.props;
    return (
      <div
        css={useStyles}
        className={`vstack-3-cell-extremety${className ? ` ${className}` : ''}`}
      >
        <div className="item">{onRenderTopCell()}</div>
        <div className="item item-center">{onRenderMiddleCell()}</div>
        <div className="item">{onRenderBottomCell()}</div>
      </div>
    );
  }
}

export default Vstack3CellExtremity;
