import { ContentError } from '../../client-server-utils/errors/ContentErrors';

class ErrorCollector {
  errors: ContentError[];

  constructor() {
    this.errors = [];
  }

  _reportError(error) {
    this.errors.push(error);
  }

  _resetAndRun(func) {
    this.errors = [];
    func();
  }

  hasHardErrors() {
    return this.errors.filter((e) => e.isHardError()).length > 0;
  }

  getErrors() {
    return this.errors;
  }

  hasOnlySoftErrors() {
    return !this.hasHardErrors();
  }

  throwFirstHardError() {
    const hardErrors = this.errors.filter((error) => error.isHardError());
    throw hardErrors[0];
  }

  getSoftErrors() {
    return this.errors.filter((error) => !error.isHardError());
  }
}

export default ErrorCollector;
