import { Children, Component } from 'react';
import { XMasonry, XBlock } from 'react-xmasonry';

const style = {};

type MasonryColumnsProps = {
  className?: string;
  children: React.ReactNode;
};

class MasonryColumns extends Component<MasonryColumnsProps> {
  static defaultProps = {
    className: null,
  };

  render() {
    const { children, className } = this.props;

    return (
      <div
        className={`masonry-columns${
          className !== null ? ` ${className}` : ''
        }`}
        css={style}
      >
        <XMasonry>
          {Children.map(children, (child) => (
            <XBlock>{child}</XBlock>
          ))}
        </XMasonry>
      </div>
    );
  }
}

export default MasonryColumns;
