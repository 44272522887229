import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import TeaserGridMolecule, {
  formatMediaItemArrayAsTeaserItemArray,
} from './common/molecules/TeaserGrid';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  '&.case-grid-component': {
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '72px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '80px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '64px',
    },
  },
};

type CaseGridProps = {
  items?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  showNumbering?: boolean;
};

class CaseGrid extends Component<CaseGridProps> {
  static defaultProps = {
    items: null,
    backgroundColor: null,
    showNumbering: false,
  };

  render() {
    const { items, backgroundColor, showNumbering } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div className="case-grid-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <TeaserGridMolecule
              items={formatMediaItemArrayAsTeaserItemArray(items)}
              showNumbering={showNumbering}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default CaseGrid;
