// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigBase, ConfigVariable } from '../StaticConfig';
import Driver from './Driver';
import {
  SeverityLevels,
  IDepdendencyInput,
  IErrorInput,
  IEventInput,
  IRequestInput,
  IPageViewInput,
} from '../../constants/reporterServiceInputs';
import Consent from '../../utils/Consent';
import { consentEnum } from '../../constants/consentCategories';

function convertDependencyInput(dependencyInput: IDepdendencyInput) {
  return {
    id: uuidv4(),
    name: dependencyInput.name,
    duration: dependencyInput.duration,
    success: dependencyInput.success,
    startTime: dependencyInput.startTime,
    responseCode:
      typeof dependencyInput.resultCode === 'string'
        ? Number(dependencyInput.resultCode)
        : dependencyInput.resultCode,
    type: dependencyInput.dependencyTypeName,
    data: dependencyInput.data,
    target: dependencyInput.target,
    properties: dependencyInput?.properties,
  };
}

/* Application Insights can track most relevant metrics automatically.
     This is controlled through config, where relevant tracking may be 
     enabled/disabled.
     For documentation see:
     https://github.com/microsoft/applicationinsights-js
*/
function generateConfig(activationKey: string, consent: Consent) {
  return {
    config: {
      instrumentationKey: activationKey,
      // Auto-track exceptions
      disableExceptionTracking: false,

      // Auto-track dependencies
      disableFetchTracking: false,
      disableAjaxTracking: false,
      disableCorrelationHeaders: false,
      excludeRequestFromAutoTrackingPatterns: [/client.js/, /hot-update/],

      // Auto track page views/routes
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: false,

      // Set Cookies and browser storage
      disableCookiesUsage: !consent[consentEnum.STATISTICAL],

      // log AI internal errors: 0 => off
      loggingLevelTelemetry: 0,
    },
  };
}

export default class ClientApplicationInsightsDriver extends Driver {
  private activationKey: string;

  private appInsights: ApplicationInsights;

  constructor(staticConfig: ConfigBase, consent: Consent) {
    super();
    this.consent = consent;
    if (
      staticConfig.isSet(ConfigVariable.ApplicationInsightsInstrumentationKey)
    ) {
      this.activationKey = staticConfig.get(
        ConfigVariable.ApplicationInsightsInstrumentationKey,
      );
      this.appInsights = new ApplicationInsights(
        generateConfig(this.activationKey, this.consent),
      );
      this.appInsights.loadAppInsights();
    } else {
      console.warn(
        'Application Insights driver not activated: The activation key is missing.',
      );
    }

    this.activate = this.activate.bind(this);
    this.getHeaderString = this.getHeaderString.bind(this);
    this.logError = this.logError.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.trackRequest = this.trackRequest.bind(this);
    this.trackDependency = this.trackDependency.bind(this);
  }

  activate(consent: Consent): void {
    this.consent = consent;

    // If necessary consent is given, and the instance was
    // initialized without cookies, we want to enable them
    if (
      this.appInsights &&
      this.consent[consentEnum.STATISTICAL] &&
      this.appInsights.config.disableCookiesUsage
    ) {
      this.appInsights.getCookieMgr().setEnabled(true);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getHeaderString(nonce: string): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getBodyString(): string {
    return null;
  }

  logError(error: IErrorInput): void {
    if (this.appInsights) {
      if (error.severityLevel === SeverityLevels.ERROR) {
        this.appInsights.trackException({
          exception: error.exception,
          properties: {
            ...error.properties,
            message: error.message,
            name: error.name,
            stackTrace: error.stack,
          },
        });
      } else {
        this.appInsights.trackTrace({
          message: error.message,
          severityLevel: error.severityLevel as number,
          properties: {
            ...error.properties,
            exception: error.exception?.name,
            stackTrace: error.stack,
          },
        });
      }
    }
  }

  trackEvent(event: IEventInput): void {
    if (this.appInsights) {
      this.appInsights.trackEvent(event);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackRequest(request: IRequestInput): void {
    return null;
  }

  trackDependency(dependencyInput: IDepdendencyInput): void {
    // if automatic tracking is disabled, track dependencies manually
    if (this.appInsights && this.appInsights?.config.disableAjaxTracking) {
      const input = convertDependencyInput(dependencyInput);
      this.appInsights.trackDependencyData(input);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackPageView(pageView: IPageViewInput): void {
    return null;
  }
}
