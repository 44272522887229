import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import ImageLeftAndTextRightComponent from '../../components/ImageLeftAndTextRightComponent';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ImageLeftAndTextRight extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let image;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <ImageLeftAndTextRightComponent
        mainHeaderText={block?.header?.main}
        bodyText={block?.bodyText}
        lightHeaderText={block?.header?.light}
        image={image}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default ImageLeftAndTextRight;
