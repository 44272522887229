import { Component } from 'react';

import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import Hstack2Cell from '../atoms/Hstack2Cell';
import IndexSpinner from '../atoms/IndexSpinner';
import ArrowButton from '../atoms/ArrowButton';

type PagedCarouselControlProps = {
  currentIndex?: number;
  maxIndex?: number;
  onChangeIndex?: (...args: any[]) => any;
};

class PagedCarouselControl extends Component<PagedCarouselControlProps> {
  static defaultProps = {
    maxIndex: 0,
    currentIndex: 0,
    onChangeIndex: null,
  };

  constructor(props) {
    super(props);
    this._onClickLeft = this._onClickLeft.bind(this);
    this._onClickRight = this._onClickRight.bind(this);
  }

  _onClickLeft() {
    const { currentIndex, maxIndex, onChangeIndex } = this.props;
    const newVal = currentIndex - 1 < 1 ? maxIndex : currentIndex - 1;
    onChangeIndex(newVal, currentIndex);
  }

  _onClickRight() {
    const { currentIndex, maxIndex, onChangeIndex } = this.props;
    const newVal = currentIndex + 1 > maxIndex ? 1 : currentIndex + 1;
    onChangeIndex(newVal, currentIndex);
  }

  render() {
    const { currentIndex, maxIndex } = this.props;

    return (
      <Hstack3CellExtremity
        className="paged-carousel-cells2 outer-hstack"
        onRenderLeftCell={() => (
          <IndexSpinner currentIndex={currentIndex} maxIndex={maxIndex} />
        )}
        onRenderRightCell={() => (
          <Hstack2Cell
            className="inner-hstack"
            onRenderLeftCell={() => (
              <ArrowButton direction="left" onClick={this._onClickLeft} />
            )}
            onRenderRightCell={() => (
              <ArrowButton onClick={this._onClickRight} />
            )}
          />
        )}
      />
    );
  }
}

export default PagedCarouselControl;
