import { Component } from 'react';

import BodyText from '../atoms/BodyText';
import Grid2CellWithGutter from './Grid2CellWithGutter';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .header-text': {
    marginBottom: '40px',
    [Breakpoints.Mobile.mq]: {
      marginBottom: '26px',
    },
    [Breakpoints.Tablet.mq]: {
      width: '430px',
    },
    [Breakpoints.DesktopUp.mq]: {
      width: '446px',
    },
  },
  '& .left-text': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '20px',
    },
  },
  [Breakpoints.TabletUp.mq]: {
    '& .left-text': {
      paddingRight: '10px',
    },
    '& .right-text': {
      paddingLeft: '10px',
    },
  },
};
type Header2TextGroupProps = {
  headerText?: InternalPropTypes.RichText;
  leftText?: InternalPropTypes.RichText;
  rightText?: InternalPropTypes.RichText;
};

class Header2TextGroup extends Component<Header2TextGroupProps> {
  static defaultProps = {
    headerText: null,
    leftText: null,
    rightText: null,
  };

  render() {
    const { headerText, leftText, rightText } = this.props;

    return (
      <div css={styles}>
        <div className="header-2-text-group-molecule">
          <div className="header-text">
            {headerText && (
              <BodyText
                className="header"
                type={BodyText.types.HEADER2TEXTGROUP}
              >
                {headerText}
              </BodyText>
            )}
          </div>

          <Grid2CellWithGutter snapAtBreakpoint={Breakpoints.Mobile}>
            <div className="left-text">
              <BodyText className="body-text" type={BodyText.types.STATIC16}>
                {leftText}
              </BodyText>
            </div>
            <div className="right-text">
              <BodyText className="body-text" type={BodyText.types.STATIC16}>
                {rightText}
              </BodyText>
            </div>
          </Grid2CellWithGutter>
        </div>
      </div>
    );
  }
}

export default Header2TextGroup;
