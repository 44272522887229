import React, { Component, ReactNode } from 'react';
import { compose } from 'recompose';

import TextBug from './molecules/TextBug';

import withAppConfig, {
  WithAppConfigProps,
} from '../../utils/hocs/withAppConfig';
import RichTextErrorReporter from '../../utils/content/errors/RichTextErrorReporter';

import { RichText } from '../../constants/internal-types';

type RichTextWrapperProps = {
  richText: RichText;
  renderFunction: (...args: any[]) => ReactNode;
};

type RichTextWrapperInnerProps = WithAppConfigProps & RichTextWrapperProps;

class RichTextWrapper extends Component<RichTextWrapperInnerProps> {
  errorContainer: { error: any };

  constructor(props) {
    super(props);
    this.errorContainer = { error: null };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { richText, renderFunction, appConfig } = this.props;

    const textOutput = renderFunction(
      richText,
      new RichTextErrorReporter(this.errorContainer),
    );
    const showNotifier =
      this.errorContainer.error && appConfig.settings.editorToolsEnabled;

    return (
      <>
        <span>{textOutput}</span>
        {showNotifier && (
          <TextBug
            title="Rich text input error"
            description={this.errorContainer.error.message}
          />
        )}
      </>
    );
  }
}

export default compose<RichTextWrapperInnerProps, RichTextWrapperProps>(
  withAppConfig,
)(RichTextWrapper);
