import { Component } from 'react';
import { keyframes } from '@emotion/react';

import Caption from './common/atoms/Caption';
import Quote from './common/molecules/Quote';
import PagedCarouselControl from './common/molecules/PagedCarouselControl';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import { defaultColors } from '../constants/colors';
import * as InternalPropTypes from '../constants/internal-types';
import {
  TypeQuoteTypes,
  BackgroundColorEnum,
} from '../constants/cms-constants';

const animations = {
  currentQuoteUp: keyframes`
  0% {
    transform: translateY(150%)
  }
  100% {
    transform: translateY(0%)
  }
  `,
  currentQuoteDown: keyframes`
  0% {
  transform: translateY(-150%)
  }
  100% {
    transform: translateY(0%)
  }
  `,
  prevQuoteUp: keyframes`
  0% {
  transform: translateY(-100%)
  }
  100% {
    transform: translateY(-250%)
  }
  `,
  prevQuoteDown: keyframes`
  0% {
  transform: translateY(-100%)
  }
  100% {
    transform: translateY(50%)
  }
  `,
};

const animationTime = '1500ms';

const styles = {
  '& .caption': {
    color: defaultColors.lightHeader,
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '100px',
    paddingBottom: '100px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '150px',
    paddingBottom: '40px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '85px',
    paddingBottom: '30px',
  },
  '& .carousel': {
    overflow: 'hidden',
    [Breakpoints.DesktopUp.mq]: {
      height: '365px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '465px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '250px',
    },
    '& .current-container-up, .current-container-down, .prev-container-up, .prev-container-down':
      {
        [Breakpoints.DesktopUp.mq]: {
          height: '305px',
        },
        [Breakpoints.Tablet.mq]: {
          height: '405px',
        },
        [Breakpoints.Mobile.mq]: {
          height: '190px',
        },
      },
  },
  '& .current-container-up': {
    animation: `${animations.currentQuoteUp} ${animationTime} forwards ease`,
  },
  '& .current-container-down': {
    animation: `${animations.currentQuoteDown} ${animationTime} forwards ease`,
  },
  '& .prev-container-up': {
    animation: `${animations.prevQuoteUp} ${animationTime} forwards ease`,
  },
  '& .prev-container-down': {
    animation: `${animations.prevQuoteDown} ${animationTime} forwards ease`,
  },
};

type QuoteCarouselProps = {
  lightHeaderText?: InternalPropTypes.RichText;
  backgroundColor?: InternalPropTypes.hexCode;
  quotes?: InternalPropTypes.MediaItem[];
  type?: TypeQuoteTypes;
};

interface IQuoteCarouselState {
  curIdx: number;
  prevIdx: number;
}

class QuoteCarousel extends Component<QuoteCarouselProps, IQuoteCarouselState> {
  static types = Quote.types;

  static defaultProps = {
    lightHeaderText: null,
    backgroundColor: BackgroundColorEnum.WHITE,
    quotes: [],
    type: this.types.SINGLE,
  };

  constructor(props) {
    super(props);
    this.state = {
      curIdx: 1,
      prevIdx: 2,
    };
    this._onChangeIndex = this._onChangeIndex.bind(this);
  }

  _onChangeIndex(newIdx) {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      prevIdx: this.state.curIdx,
      curIdx: Math.max(0, Math.min(this.props.quotes.length, newIdx)),
    });
  }

  _animationCase(curIdx, prevIdx) {
    let result;
    const maxIndex = this.props.quotes.length - 1;
    switch (curIdx - prevIdx) {
      case -maxIndex:
        result = '-up';
        break;
      case maxIndex:
        result = '-down';
        break;
      case 1:
        result = '-up';
        break;
      default:
        result = '-down';
    }
    return result;
  }

  render() {
    const { lightHeaderText, backgroundColor, quotes, type } = this.props;
    const { curIdx, prevIdx } = this.state;
    const curQuote = quotes[curIdx - 1];
    const prevQuote = quotes[prevIdx - 1];

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]} className="quote-carousel-component">
        <ContentOuterBound>
          <ContentSpacer>
            {lightHeaderText && (
              <Caption type={Caption.types.SECTION}>{lightHeaderText}</Caption>
            )}
            <div className="carousel">
              <div
                className={`current-container${this._animationCase(
                  curIdx,
                  prevIdx,
                )}`}
                key={`cur-${curIdx}`}
              >
                {curQuote.header && curQuote.sender && (
                  <Quote
                    quote={curQuote.header}
                    sender={curQuote.sender.name}
                    type={type}
                  />
                )}
              </div>

              <div
                className={`prev-container${this._animationCase(
                  curIdx,
                  prevIdx,
                )}`}
                key={`prev-${prevIdx}`}
              >
                {prevQuote.header && prevQuote.sender && (
                  <Quote
                    quote={prevQuote.header}
                    sender={prevQuote.sender.name}
                    type={type}
                  />
                )}
              </div>
            </div>

            <PagedCarouselControl
              currentIndex={curIdx % (quotes.length + 1)}
              maxIndex={quotes.length}
              onChangeIndex={this._onChangeIndex}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default QuoteCarousel;
