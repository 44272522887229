import React, { Children, Component } from 'react';
import { css } from '@emotion/react';

const styles = css({
  position: 'absolute',
  top: 0,
  zIndex: 0,
  height: '100%',
  width: '100%',
  '& img, & video': {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
});

type FullBackgroundProps = {
  children?: React.ReactNode;
};

class FullBackground extends Component<FullBackgroundProps> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;

    return <div css={styles}>{Children.only(children)}</div>;
  }
}

export default FullBackground;
