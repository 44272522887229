import { Heuristics, HeuristicsResponseType } from './Heuristics';
import { STATUS_ERROR, STATUS_OKAY } from '../../constants/seoStatus';
import { ContentTypeEnum } from '../../constants/cms-constants';
import 'core-js/es/promise';
import {
  BlockContentEntry,
  Block,
  Image,
  MediaItem,
  Page,
  Person,
  Root,
} from '../../constants/internal-types';
import NwLink from '../../components/common/NwLink';
import generateEditorLinkToEntry from '../contentful';

type SimpleHeuristicImageType = {
  assetName: string;
  alternateText: string;
  imageContentfulEntryId: string;
  blockContentfulEntryId: string;
};

class PageImageHeuristic extends Heuristics {
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let result: HeuristicsResponseType[] = [];
      if (page && root) {
        const imagesOnPage = this.getImagesFromPage(page);

        result = imagesOnPage.map(
          (image, index): HeuristicsResponseType => ({
            name: `Image ${index + 1} - ${image.assetName}`,
            generalText: this.getGeneralText(),
            title: this.getAltTextFromImage(image),
            status: image.alternateText ? STATUS_OKAY : STATUS_ERROR,
            error: image.alternateText
              ? null
              : `The image "${image.assetName}" has no alt text.`,
            path: image.alternateText ? null : this.getFixPathText(image),
          }),
        );
      }
      resolve(result);
    });
  }

  getImagesFromPage(page: Page): Array<SimpleHeuristicImageType> {
    const toSimple = (
      image: Image,
      blockContentfulEntryId: string,
    ): SimpleHeuristicImageType => ({
      assetName: this.imageHelperFactory
        .createImageHelper(image)
        .getAssetName(),
      alternateText: image?.alternateText || '',
      imageContentfulEntryId: image?.contentfulEntryId || '',
      blockContentfulEntryId,
    });
    const extractImage = (
      block: Block,
      blockContentEntry: BlockContentEntry,
      // eslint-disable-next-line consistent-return
    ): SimpleHeuristicImageType | undefined => {
      if (blockContentEntry?.cmsType && blockContentEntry?.content) {
        // eslint-disable-next-line no-nested-ternary
        return blockContentEntry.cmsType === ContentTypeEnum.MEDIAITEM &&
          (blockContentEntry.content as MediaItem)?.image
          ? toSimple(
              (blockContentEntry.content as MediaItem)?.image,
              block?.contentfulEntryId || '',
            )
          : // eslint-disable-next-line no-nested-ternary
          blockContentEntry.cmsType === ContentTypeEnum.PERSON &&
            (blockContentEntry.content as Person)?.image
          ? toSimple(
              (blockContentEntry.content as Person)?.image,
              block?.contentfulEntryId || '',
            )
          : blockContentEntry.cmsType === ContentTypeEnum.IMAGE
          ? toSimple(
              blockContentEntry.content as Image,
              block?.contentfulEntryId || '',
            )
          : undefined;
      }
    };

    return page?.blocks
      ?.map((block) =>
        block?.content?.map((contentEntry) =>
          extractImage(block, contentEntry),
        ),
      )
      .flat()
      .filter((x) => x !== undefined) as SimpleHeuristicImageType[];
  }

  // eslint-disable-next-line class-methods-use-this
  getBlocksWithImages(blocks: Array<Block>): Array<MediaItem | Person | Image> {
    return blocks.filter((block) =>
      block?.content?.some(
        (innerContent) =>
          innerContent?.cmsType &&
          innerContent?.content &&
          ((innerContent.cmsType === ContentTypeEnum.MEDIAITEM &&
            (innerContent.content as MediaItem)?.image) ||
            (innerContent.cmsType === ContentTypeEnum.PERSON &&
              (innerContent.content as Person)?.image) ||
            (innerContent.cmsType === ContentTypeEnum.IMAGE &&
              (innerContent.content as Image))),
      ),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        An alt text is added to an image tag to describe its contents. Alt
        attributes are important in terms of on-page optimization for two
        reasons.
        <ol>
          <li>
            {' '}
            Alt text is displayed to visitors if any particular image cannot be
            loaded or images are disabled.
          </li>
          <li>
            {' '}
            Alt attributes provides context because the search engine cannot
            &quot;see&quot; images.
          </li>
        </ol>
        This helps search engines understand what the images are about and how
        they go with the rest of the content on the page may help them choose a
        page for a suitable search query. Best practises for alt texts are:
        <ul>
          <li> Have an alt text for all images.</li>
          <li>
            {' '}
            Optimize the most important images that might be looked up in a
            Google Images search.
          </li>
          <li>
            {' '}
            Keep the alt text clear and descriptive. Use the keywords reasonably
            and make sure they fit naturally into the general topic of the page.
          </li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAltTextFromImage(image: SimpleHeuristicImageType) {
    if (image.alternateText) {
      return `The image "${image.assetName}" has the alt text: "${image.alternateText}".`;
    }
    return `The image "${image.assetName}" is missing an alt text.`;
  }

  getFixPathText(image: SimpleHeuristicImageType): React.ReactNode {
    return (
      <span>
        To fix this, navigate to the{' '}
        <NwLink
          to={generateEditorLinkToEntry(
            this.appSettings,
            image.imageContentfulEntryId,
          )}
          openInNewWindowOrTab
        >
          image
        </NwLink>{' '}
        or the{' '}
        <NwLink
          to={generateEditorLinkToEntry(
            this.appSettings,
            image.blockContentfulEntryId,
          )}
          openInNewWindowOrTab
        >
          block
        </NwLink>{' '}
        it belongs to in Contentful
      </span>
    );
  }
}

export default PageImageHeuristic;
