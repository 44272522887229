import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import CaseHeroMolecule from './common/molecules/CaseHero';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '143px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '83px',
  },
};

type CaseHeroProps = {
  mediaItem?: InternalPropTypes.MediaItem;
  header?: InternalPropTypes.RichText;
  backgroundColor?: TypeBackgroundColorTypes;
};

class CaseHero extends Component<CaseHeroProps> {
  static defaultProps = {
    mediaItem: null,
    header: null,
    backgroundColor: null,
  };

  render() {
    const { mediaItem, header, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="case-page-hero-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <CaseHeroMolecule
              headerText={mediaItem.header}
              lightHeaderText={header}
              bodyText={mediaItem.bodyText}
              caseText={mediaItem.lightHeader}
              image={mediaItem.image}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default CaseHero;
