import { BlockInstanceProps } from '../Block';
import NextPage from '../../components/NextPage';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'seoWeight',
    definition: controlOptionDefinitions.SEOWeightTypes,
  },
])
class NextPageBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { seoWeight } = this.controlOptions;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    let image;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <NextPage
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        link={block?.link}
        backgroundColor={backgroundColor}
        image={image}
        seoWeight={seoWeight}
      />
    );
  }
}

export default NextPageBlock;
