import {
  SeverityLevels,
  IErrorInput,
} from '../../constants/reporterServiceInputs';

export default class ErrorInput implements IErrorInput {
  name: string;

  message: string;

  exception?: Error;

  severityLevel?: SeverityLevels;

  stack?: string;

  properties?: { [key: string]: any };

  constructor(message: string, name?: string) {
    this.message = message;
    this.name = name ?? 'Unknown Error';
  }

  static createFromException(exception: Error, message?: string) {
    const me = new ErrorInput(message ?? exception.message, exception.name);
    me.exception = exception;
    me.severityLevel = SeverityLevels.ERROR;
    me.stack = exception.stack;
    if (exception.cause)
      me.properties = {
        ...me.properties,
        cause: exception.cause,
      };
    if (message)
      me.properties = { ...me.properties, originalMessage: exception.message };

    return me;
  }
}
