import { Component } from 'react';
import generateRandomString from '../../utils/generateRandomString';

type PreserveWrapperProps = {
  children?: React.ReactNode;
  activated?: boolean;
};

class PreserveWrapper extends Component<PreserveWrapperProps> {
  static defaultProps = {
    children: null,
    activated: true,
  };

  childObserver: MutationObserver;
  parentObserver: MutationObserver;
  uniqueString: string;
  constructor(props) {
    super(props);
    this.childObserver = null;
    this.parentObserver = null;
    this.uniqueString = `preserve-${generateRandomString(10)}`;
  }

  componentDidMount(): void {
    const preserveDiv = document.querySelector(`.${this.uniqueString}`);
    const config = { attributes: true, childList: true, subtree: true };

    const childCallback = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          if (mutation.removedNodes.length) {
            mutation.target.appendChild(mutation.removedNodes[0]);
          }
        }
        observer.disconnect();
        observer.observe(preserveDiv, config);
      });
    };
    this.childObserver = new MutationObserver(childCallback);
    this.childObserver.observe(preserveDiv, config);

    const parentCallback = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        if (mutation.removedNodes.length) {
          if (mutation.removedNodes[0] === preserveDiv) {
            mutation.target.appendChild(preserveDiv);
          }
        }
        observer.disconnect();
        observer.observe(preserveDiv.parentNode, config);
      });
    };
    this.parentObserver = new MutationObserver(parentCallback);
    this.parentObserver.observe(preserveDiv.parentNode, config);
  }

  componentWillUnmount(): void {
    this.childObserver.disconnect();
    this.parentObserver.disconnect();
  }

  render() {
    const { children, activated } = this.props;

    if (!activated) {
      this.childObserver.disconnect();
      this.parentObserver.disconnect();
    }
    return <div className={this.uniqueString}>{children}</div>;
  }
}

export default PreserveWrapper;
