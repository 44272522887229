import { Component } from 'react';

import Header from '../atoms/Header';
import InlinedRichText from './InlinedRichText';

import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = {
  '& .header-text': {
    color: colorEnum.white,
  },
  '& .text': {
    color: colorEnum.gray,
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '30px',
    },
  },
};

type LetsTalkMoleculeProps = {
  header?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
};

class LetsTalkMolecule extends Component<LetsTalkMoleculeProps> {
  static defaultProps = {
    header: null,
    bodyText: null,
  };

  // We want richtext for the body text such that hyperlinks is possible
  // But InlinedRichText should be replaced or atleast renamed
  render() {
    const { header, bodyText } = this.props;

    return (
      <div css={styles}>
        <Header>{header}</Header>
        <div className="text">
          <InlinedRichText richText={bodyText} />
        </div>
      </div>
    );
  }
}

export default LetsTalkMolecule;
