import { ReactNode } from 'react';
import AppSettings from '../../client-server-utils/AppSettings';
import type { SeoStatusType } from '../../constants/seoStatus';
import { ImageFactoryProps } from '../hocs/withImageHelperFactory';

export interface HeuristicsInterface {
  checkHeuristics: (mappedData: any, root: any) => void;
  getGeneralText: () => ReactNode;
  getFixPathText: (mappedData: any) => ReactNode;
  getFixPath: (mappedData: any) => string;
  isUpperCase: (str: string) => boolean;
}

export type HeuristicsResponseType = {
  title: ReactNode;
  error: string | null;
  status: SeoStatusType;
  name: string;
  generalText: ReactNode;
  path: ReactNode;
  display?: ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export abstract class Heuristics implements HeuristicsInterface {
  constructor(
    protected appSettings: AppSettings,
    protected imageHelperFactory: ImageFactoryProps,
  ) {
    this.appSettings = appSettings;
    this.imageHelperFactory = imageHelperFactory;
  }

  abstract checkHeuristics(mappedData, root): void;

  abstract getGeneralText(): ReactNode;

  getFixPathText(mappedData): ReactNode {
    return ` ${this.getFixPath(mappedData)} `;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getFixPath(mappedData): string | null {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  isUpperCase(string): boolean {
    return typeof string === 'string' && /^[A-Z]*$/.test(string);
  }
}
