import ScreenSize from '../../constants/screenSize';
import { ImgixParams, SizeOrSizes, SizesType } from '../../types/ts/imgixQuery';

const queryToKeyValue = (str: string) =>
  str.split('&').reduce((acc, cur) => {
    if (cur.match(/=/)) {
      const [key, value] = cur.split('=');
      acc[key] = value;
    }
    return acc;
  }, {});

const keyValueToQuery = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');

const defaultParameters = { auto: 'format', fit: 'crop' };

type ImgixParameterProps = {
  imageUrl?: string;
  imgixQueryString?: string;
  sizes?: SizeOrSizes;
};

class ImgixParameters {
  private _params: ImgixParams;

  private _sizes: SizesType;

  private _url: string;

  constructor(props: ImgixParameterProps) {
    const { imageUrl, imgixQueryString, sizes } = props;

    this._params = queryToKeyValue(imgixQueryString ?? '');
    this._url = imageUrl;

    if (
      sizes &&
      Object.values(sizes)?.some((value) => typeof value === 'object')
    ) {
      this._sizes = sizes as SizesType;
    } else if (sizes) {
      this._params = { ...(sizes as ImgixParams), ...this._params };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generateImgixSrcForMultipleDpr(url: string) {
    return [`${url}&dpr=1 1x`, `${url}&dpr=2 2x`, `${url}&dpr=3 3x`].join(', ');
  }

  getSizeParams() {
    const { h, w, ar } = this._params;
    const toNumber = (str: string) => (Number(str) ? Number(str) : undefined);

    const result = { h: toNumber(h), w: toNumber(w), ar: undefined };

    if (w && h) {
      result.ar = result.w / result.h;
    } else if (ar) {
      const [arW, arH] = ar.split(':');
      result.ar = toNumber(arW) / toNumber(arH);
    }

    if (w && !h) {
      result.h = result.w / result.ar;
    } else if (h && !w) {
      result.w = result.h * result.ar;
    }

    return result;
  }

  generateImgixSrcSet(size: ScreenSize) {
    const params = (this._sizes[size] as SizesType) ?? {};

    const queryString = {
      ...defaultParameters,
      ...params,
      ...this._params,
    };
    const queryParams = keyValueToQuery(queryString);
    const imgixUrl = `${this._url}?${queryParams}`;

    return this.generateImgixSrcForMultipleDpr(imgixUrl);
  }

  generateMultipleImgixSrcSets(): Partial<Record<ScreenSize, string>> {
    if (!this._sizes) return null;

    return Object.keys(this._sizes).reduce((acc, size: ScreenSize) => {
      acc[size] = this.generateImgixSrcSet(size);
      return acc;
    }, {});
  }

  getImgixUrl() {
    const queryString = {
      ...defaultParameters,
      ...this._params,
    };
    const queryParams = keyValueToQuery(queryString);
    return `${this._url}?${queryParams}`;
  }
}

export default ImgixParameters;
