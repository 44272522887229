import { BlockInstanceProps } from '../Block';
import NextCase from '../../components/NextCase';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class NextCaseBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    let mediaItem;
    blockContent.doQuery((content) => {
      mediaItem = content.getFirstOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <NextCase
        title={block?.header?.main}
        openInNewWindowOrTab={block?.link?.openInNewWindowOrTab}
        caseText={block?.header?.light}
        caseSlug={block?.link?.linkTo}
        mediaItem={mediaItem}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default NextCaseBlock;
