import { Component } from 'react';
import { css } from '@emotion/react';

import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import Image from '../atoms/Image';
import NwLink from '../NwLink';

import { getStringFromRichText } from '../../../utils/content/RichTextConverter';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';
import { SizeOrSizes } from '../../../types/ts/imgixQuery';

const styles = css({
  marginBottom: '80px',
  alignContent: 'end',
  [Breakpoints.Mobile.mq]: {
    maxHeight: '416px',
  },
  [Breakpoints.TabletUp.mq]: {
    maxHeight: '100%',
  },
  '&.left': {
    [Breakpoints.DesktopUp.mq]: {
      paddingRight: '32px',
    },
  },
  '&.center': {
    [Breakpoints.Mobile.mq]: {
      width: 'auto',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  '&.right': {
    [Breakpoints.DesktopUp.mq]: {
      paddingLeft: '32px',
    },
  },
  '& .clickable-area': {
    minWidth: '0',
    '&:hover': {
      textDecoration: 'none',
    },
    '& .image': {
      objectFit: 'cover',
      [Breakpoints.Mobile.mq]: {
        maxHeight: '336px',
        maxWidth: '100%',
        minWidth: '80%',
      },
      [Breakpoints.TabletUp.mq]: {
        maxHeight: '256px',
        width: '100%',
      },
    },
    '& .text-area': {
      [Breakpoints.Mobile.mq]: {
        maxWidth: '450px',
      },
      '& .teaser': {
        paddingTop: '16px',
        paddingBottom: '8px',
      },
    },
  },
});

type ArticleTeaserProps = {
  link: InternalPropTypes.Link;
  image?: InternalPropTypes.Image;
  imageSizes?: SizeOrSizes;
  title?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  className?: string;
};

class ArticleTeaser extends Component<ArticleTeaserProps> {
  static defaultProps = {
    image: null,
    imageSizes: null,
    title: null,
    text: '',
    className: '',
  };

  render() {
    const { image, imageSizes, title, text, link, className } = this.props;

    const titleAsString = getStringFromRichText(title);
    return (
      <div css={styles} className={`article-teaser ${className}`}>
        <NwLink
          to={link.linkTo}
          className="clickable-area"
          aria-label={titleAsString}
          openInNewWindowOrTab={link.openInNewWindowOrTab}
        >
          <div className="image-container">
            <Image image={image} sizes={imageSizes} />
          </div>
          <div className="text-area">
            <Title className="article-title" type={TitleTypes.TEASER}>
              {title}
            </Title>
            <BodyText type={BodyText.types.LIGHT14}>{text}</BodyText>
          </div>
        </NwLink>
      </div>
    );
  }
}

export default ArticleTeaser;
