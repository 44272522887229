import { Component } from 'react';

import RichTextRenderer from '../utils/content/RichTextRenderer';
import RichTextWrapper from './common/RichTextWrapper';

import * as InternalPropTypes from '../constants/internal-types';

type TextBoxProps = {
  input?: InternalPropTypes.RichText;
  openInNewTab?: boolean;
};

class TextBox extends Component<TextBoxProps> {
  static defaultProps = {
    input: null,
    openInNewTab: true,
  };

  render() {
    const { input, openInNewTab } = this.props;

    return (
      <RichTextWrapper
        richText={input}
        renderFunction={(richText, errorReporter) =>
          RichTextRenderer.headersItalicBoldLinksAndLists(
            richText,
            errorReporter,
            openInNewTab,
          )
        }
      />
    );
  }
}

export default TextBox;
