import { Component } from 'react';
import { css, SerializedStyles } from '@emotion/react';

import * as Breakpoints from '../../../constants/breakpoints';
import Image from '../atoms/Image';
import * as InternalPropTypes from '../../../constants/internal-types';
import {
  WellTypeEnum,
  TypeWellTypes,
  TypePositionTypes,
} from '../../../constants/cms-constants';
import NwLink from '../NwLink';
import ScreenSize from '../../../constants/screenSize';
import { SizeOrSizes, SizesType } from '../../../types/ts/imgixQuery';

const useStyles = css({
  width: '100%',
  display: 'flex',
  '& .picture': {
    display: 'flex',
  },
  '& .nav-link': {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  '&.client': {
    [Breakpoints.Mobile.mq]: {
      height: '88px',
    },
    [Breakpoints.TabletUp.mq]: {
      height: '160px',
    },
    '& .image': {
      maxWidth: '69%',
      maxHeight: '40%',
    },
  },
  '&.partner': {
    height: '120px',
    '& .image': {
      maxWidth: '66%',
      maxHeight: '60%',
    },
  },
  '&.big-scale-down': {
    '& .image': {
      maxWidth: '100%',
      objectFit: 'scale-down',
      maxHeight: '500px',
    },
  },
  '&.big-cropped': {
    '& .image': {
      width: '100%',
      objectFit: 'cover',
      maxHeight: '500px',
    },
  },
  '&.constant-relative-width-and-height': {
    '& .image': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },
  },
  '&.Left': {
    '& .image': {
      margin: 'auto auto auto 0',
    },
  },
  '&.Right': {
    '& .image': {
      margin: 'auto 0 auto auto',
    },
  },
  '&.Center': {
    '& .image': {
      margin: 'auto auto auto auto',
    },
  },
});

type WellProps = {
  type?: TypeWellTypes;
  image?: InternalPropTypes.Image;
  link?: InternalPropTypes.Link;
  position?: TypePositionTypes;
  style?: SerializedStyles;
};

class Well extends Component<WellProps> {
  static types = WellTypeEnum;

  static defaultProps = {
    type: null,
    image: null,
    link: null,
    position: null,
    style: null,
  };

  // eslint-disable-next-line class-methods-use-this
  getSizesFromType = (type: string): SizeOrSizes => {
    switch (type) {
      case Well.types.PARTNER:
        return { height: '72' };
      case Well.types.CLIENT:
        return {
          [ScreenSize.Mobile]: { h: '36', w: '256', fit: 'clip' },
          [ScreenSize.Tablet]: { h: '64', w: '312', fit: 'clip' },
          [ScreenSize.Desktop]: { h: '64', w: '230', fit: 'clip' },
        };
      case Well.types.BIG_SCALE_DOWN:
        return {
          [ScreenSize.Mobile]: { w: '743', 'max-h': '500' },
          [ScreenSize.Tablet]: { w: '903', 'max-h': '500' },
          [ScreenSize.Desktop]: { w: '1000', 'max-h': '500', fit: 'clip' },
        };
      case Well.types.BIG_CROPPED:
        return {
          [ScreenSize.Mobile]: { w: '743', 'max-h': '500' },
          [ScreenSize.Tablet]: { w: '903', 'max-h': '500' },
          [ScreenSize.Desktop]: { w: '1000', 'max-h': '500' },
        };
      case Well.types.CONSTANT_RELATIVE_WIDTH_AND_HEIGHT:
      default:
        return {
          [ScreenSize.Mobile]: { w: '711' },
          [ScreenSize.Tablet]: { w: '871' },
          [ScreenSize.Desktop]: { w: '968' },
        };
    }
  };

  render() {
    const { image, type, link, position, style } = this.props;

    const sizes = this.getSizesFromType(type);

    return (
      <div css={[useStyles, style]} className={`${type} ${position}`}>
        {link ? (
          <NwLink
            className="nav-link"
            to={link.linkTo}
            openInNewWindowOrTab={link.openInNewWindowOrTab}
          >
            <Image image={image} sizes={sizes} />
          </NwLink>
        ) : (
          <Image image={image} sizes={sizes} />
        )}
      </div>
    );
  }
}

export default Well;
