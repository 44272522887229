import { Component, Children, PropsWithChildren } from 'react';
import MediaQuery from '../atoms/MediaQuery';
import GridEvenSplit from '../atoms/GridEvenSplit';
import getRows from '../../../utils/utilFunctions';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = {
  '& .row': {
    marginBottom: '10px',
  },
};

type Grid3CellTabletUp2CellMobileProps = PropsWithChildren<{
  snapAtBreakpoint?: Breakpoints.BreakpointType;
}>;

class Grid3CellTabletUp2CellMobile extends Component<Grid3CellTabletUp2CellMobileProps> {
  static defaultProps = {
    children: [],
    snapAtBreakpoint: Breakpoints.Mobile,
  };

  render() {
    const { children, snapAtBreakpoint } = this.props;
    const childArray = Children.toArray(children);
    const rowsOf2 = getRows(childArray, 2);
    const rowsOf3 = getRows(childArray, 3);
    return (
      <div css={styles}>
        <div className="profile-grid-molecule">
          <MediaQuery visible={Breakpoints.Mobile}>
            {rowsOf2.map((row, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="row" key={`grid-${idx}`}>
                <GridEvenSplit
                  // eslint-disable-next-line react/no-array-index-key
                  key={`grid-${idx}`}
                  onRenderCells={[() => row[0], () => (row[1] ? row[1] : null)]}
                  snapAtBreakpoint={snapAtBreakpoint}
                />
              </div>
            ))}
          </MediaQuery>

          <MediaQuery visible={Breakpoints.TabletUp}>
            {rowsOf3.map((row, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="row" key={`grid-${idx}`}>
                <GridEvenSplit
                  // eslint-disable-next-line react/no-array-index-key
                  key={`grid-${idx}`}
                  onRenderCells={[
                    () => row[0],
                    () => (row[1] ? row[1] : null),
                    () => (row[2] ? row[2] : null),
                  ]}
                  snapAtBreakpoint={Breakpoints.Mobile}
                />
              </div>
            ))}
          </MediaQuery>
        </div>
      </div>
    );
  }
}
export default Grid3CellTabletUp2CellMobile;
