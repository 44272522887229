import { BlockInstanceProps } from '../Block';
import FrontPageHero from '../../components/FrontPageHero';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class FrontPageHeroBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    let image;
    let video;
    let snippets;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE, false);
      video = content.getFirstOfType(ContentTypeEnum.VIDEO, false);
      snippets = content.getAllOfType(ContentTypeEnum.SNIPPET, false);
    });

    return (
      <FrontPageHero
        image={image}
        video={video}
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        leftSnippet={snippets[0]}
        middleSnippet={snippets[1]}
        rightSnippet={snippets[2]}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default FrontPageHeroBlock;
