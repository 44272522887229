import { Component } from 'react';
import { css } from '@emotion/react';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import HeroText from './HeroText';
import SVGRenderer from '../atoms/SVGRenderer';
import ExpandableVideo from './ExpandableVideo';
import Image from '../atoms/Image';
import CallToAction from './CallToAction';
import TextLinkButton from './TextLinkButton';
import VerticalCenter from '../atoms/VerticalCenter';
import { getStringFromRichText } from '../../../utils/content/RichTextConverter';
import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import { HeroTypeEnum, TypeHeroTypes } from '../../../constants/cms-constants';
import { SizesType } from '../../../types/ts/imgixQuery';
import ScreenSize from '../../../constants/screenSize';

const styles = css({
  '& .row': {
    position: 'relative',
    alignItems: 'flex-end',
  },
  '& .hero-molecule-video .row': {
    [Breakpoints.TabletUp.mq]: {
      height: '431px',
    },
  },
  '& .hero-molecule-video .row .right-cell': {
    [Breakpoints.TabletUp.mq]: {
      position: 'absolute',
      right: 0,
      maxWidth: '100%',
    },
  },
  '& .left-cell': {
    zIndex: 999,
  },
  '& .cell.right-cell': {
    overflow: 'hidden',
    alignItems: 'flex-end',
  },
  '& .hero-text': {
    [Breakpoints.Tablet.mq]: {
      maxWidth: '95%',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '85%',
    },
  },
  '& .video': {
    width: '100%',
  },
  '& .body-text': {
    [Breakpoints.Tablet.mq]: {
      paddingBottom: '30px',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingBottom: '50px',
    },
  },
  '& .image': {
    objectFit: 'cover',
    [Breakpoints.Mobile.mq]: {
      height: '370px',
      width: 'auto',
    },
    [Breakpoints.Tablet.mq]: {
      height: '338px',
      width: '260px',
    },
    [Breakpoints.DesktopUp.mq]: {
      height: '380px',
      width: '300px',
    },
  },
  '& .right-cell': {
    [Breakpoints.TabletUp.mq]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  '& .cta': {
    marginTop: '-20px',
    marginBottom: '20px',
  },
  '& .animation': {
    [Breakpoints.Tablet.mq]: {
      maxHeight: '315px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingBottom: '30px',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxHeight: '350px',
    },
  },
  '.hero-molecule.signup-hero': {
    '& .left-inner-cell': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& .left-cell-vertical-center': {
        flexGrow: 1,
        [Breakpoints.Mobile.mq]: {
          paddingBottom: '40px',
          paddingTop: '25px',
        },
      },
      '& .body-text': {
        [Breakpoints.DesktopUp.mq]: {
          paddingBottom: '1',
        },
        [Breakpoints.Mobile.mq]: {
          marginBottom: '15px',
        },
      },
    },
  },
  '.hero-molecule.signup-event-hero': {
    '& .body-text': {
      fontSize: '22px',
      [Breakpoints.Mobile.mq]: {
        marginBottom: '20px',
      },
    },
    '& .title': {
      [Breakpoints.TabletUp.mq]: {
        fontSize: '46px',
        marginTop: '70px',
        marginBottom: '40px',
      },
      [Breakpoints.Mobile.mq]: {
        fontSize: '38px',
        marginTop: '50px',
        marginBottom: '20px',
      },
    },
    '& .image': {
      objectFit: 'cover',
      [Breakpoints.TabletUp.mq]: {
        height: '100%',
        width: '100%',
      },
      [Breakpoints.Mobile.mq]: {
        height: '370px',
        width: '100%',
      },
    },
    '& .cta': {
      marginTop: '0px',
    },
  },
});

type HeroProps = {
  lightHeader?: InternalPropTypes.RichText;
  mainHeader?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  animation?: InternalPropTypes.ImageFile;
  video?: InternalPropTypes.Video;
  type?: TypeHeroTypes;
  link?: InternalPropTypes.Link;
};
class Hero extends Component<HeroProps> {
  static types = HeroTypeEnum;

  static defaultProps = {
    lightHeader: null,
    mainHeader: null,
    text: null,
    image: null,
    video: null,
    animation: null,
    type: this.types.DEFAULT,
    link: null,
  };

  render() {
    const {
      lightHeader,
      mainHeader,
      text,
      image,
      video,
      animation,
      type,
      link,
    } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743', h: '370' },
      [ScreenSize.Tablet]: { w: '260', h: '338' },
      [ScreenSize.Desktop]: { w: '300', h: '380' },
    };

    const CompLoader = () => {
      if (animation) {
        return <SVGRenderer animation={animation} />;
      }

      if (image) {
        return (
          <Image
            image={image}
            className={type === Hero.types.SIGNUP_EVENT ? 'signup-event' : ''}
            sizes={sizes}
          />
        );
      }

      if (video) {
        return (
          <div className="video">
            <ExpandableVideo video={video} />
          </div>
        );
      }

      return null;
    };
    const mediaType = video ? '-video' : '';

    return (
      <div css={styles}>
        <div className={`hero-molecule${mediaType} ${type}`}>
          <Grid2CellUnevenSplit
            leftCellCount={6}
            rightCellCount={5}
            onRenderCell1={() => (
              <div className="left-cell left-inner-cell">
                <HeroText
                  header={mainHeader}
                  lightHeader={lightHeader}
                  text={text}
                  type={type}
                />
                {link &&
                  (type === Hero.types.SIGNUP ? (
                    <VerticalCenter className="left-cell-vertical-center">
                      <TextLinkButton
                        url={link.linkTo}
                        label={getStringFromRichText(link.text)}
                        openInNewWindowOrTab={link.openInNewWindowOrTab}
                      />
                    </VerticalCenter>
                  ) : (
                    <CallToAction
                      link={link}
                      type={
                        type === Hero.types.SIGNUP_EVENT
                          ? CallToAction.types.SIGNUP_EVENT
                          : CallToAction.types.REGULAR
                      }
                    />
                  ))}
              </div>
            )}
            onRenderCell2={() => CompLoader()}
            snapAtBreakpoint={Breakpoints.MobileDown}
          />
        </div>
      </div>
    );
  }
}

export default Hero;
