import { BlockInstanceProps } from '../Block';
import ProfileAuthor from '../../components/ProfileAuthor';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ProfileAuthorBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];

    let persons;
    blockContent.doQuery((content) => {
      persons = content.getAllOfType(ContentTypeEnum.PERSON);
    });

    return (
      <ProfileAuthor persons={persons} backgroundColor={backgroundColor} />
    );
  }
}

export default ProfileAuthorBlock;
