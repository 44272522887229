import { Component, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import GridEvenSplit from '../atoms/GridEvenSplit';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = css({
  '& .cells-with-gutter-default': {
    '& .left-text': {
      [Breakpoints.TabletUp.mq]: { marginRight: '8%' },
    },
    '& .right-text': {
      [Breakpoints.TabletUp.mq]: {
        marginLeft: '4%',
        marginRight: '4%',
      },
    },
  },
  '& .cells-with-gutter-services': {
    '& .left-text': {
      [Breakpoints.TabletUp.mq]: { marginRight: '4%' },
    },
    '& .right-text': {
      [Breakpoints.TabletUp.mq]: {
        marginLeft: '11%',
      },
    },
  },
});

type Grid2CellWithGutterProps = PropsWithChildren<{
  snapAtBreakpoint?: Breakpoints.BreakpointType;
  type?: string;
}>;

class Grid2CellWithGutter extends Component<Grid2CellWithGutterProps> {
  static SERVICES = 'services';

  static DEFAULT = 'default';

  static defaultProps = {
    children: [],
    snapAtBreakpoint: Breakpoints.Tablet,
    type: null,
  };

  render() {
    const { type, children, snapAtBreakpoint } = this.props;
    const className = `cells-with-gutter-${type}`;
    return (
      <div css={styles}>
        <div className={className}>
          <GridEvenSplit
            snapAtBreakpoint={snapAtBreakpoint}
            onRenderCells={[
              () => <div className="left-text">{children[0]}</div>,
              () => <div className="right-text">{children[1]}</div>,
            ]}
          />
        </div>
      </div>
    );
  }
}
export default Grid2CellWithGutter;
