/* eslint-disable react/prop-types */
import { compose } from 'recompose';
import './language/i18n';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import type { UnregisterCallback, Location, Action } from 'history';
import AppConfigContext from './contexts/AppConfigContext';
import { withAppErrors } from './utils/hocs/withErrors';
import CookieService from './client-server-utils/CookieService';
import { ConfigBase } from './client-server-utils/StaticConfig';
import AppSettings from './client-server-utils/AppSettings';
import * as urlutil from './utils/url';
import LoadingListener, {
  LoadingListenerProps,
  LoadingListenerState,
} from './containers/LoadingListener';
import withAppConfig, { WithAppConfigProps } from './utils/hocs/withAppConfig';
import { LoadResult } from './client-server-utils/loading/LoadingManager';
import { isPageCacheKey } from './containers/Page';
import Root, { isRootCacheKey } from './containers/Root';
import { Page as PageType, Root as RootType } from './constants/internal-types';
import withLoadingContext from './utils/hocs/withLoadingContext';
import Consent from './utils/Consent';
import { createAppConfig } from './client-server-utils/appConfig';
import withReporterService, {
  withReporterServiceContextProps,
} from './utils/hocs/withReporterService';
import { MissingContentWarning } from './client-server-utils/errors/ContentErrors';

// Typescript decorators are incorrectly flagged as references to global
// variables, some of which are restricted. Hence the ignores below.

type AppProps = {
  cookieService: CookieService;
  staticConfig: ConfigBase;
};

type AppInnerProps = RouteComponentProps &
  LoadingListenerProps &
  WithAppConfigProps &
  withReporterServiceContextProps &
  AppProps & {
    i18n: i18n;
  };

type AppState = LoadingListenerState & {
  appSettings: AppSettings;
  historyUnregistration?: UnregisterCallback | null;
  root: RootType;
  currentPageAlternates: PageType;
  consent: {
    resolve: (consent: Consent) => void | null;
    promise: Promise<Consent>;
  };
  scrollPositions: { [key: string]: number };
  currentPage: string;
  previousPage: string;
};

class App extends LoadingListener<AppInnerProps, AppState> {
  constructor(props: AppInnerProps) {
    super(props);

    const { consentPromise, resolveConsentFunction } =
      Consent.initializeConsent();

    this.state = {
      ...this.state,
      consent: { resolve: resolveConsentFunction, promise: consentPromise },
      appSettings: new AppSettings({
        isCookieBannerClosed: false,
        staticConfig: this.props.staticConfig,
        cookieService: props.cookieService,
        isoLanguage: props.i18n.language,
        consent: null,
      }),
      scrollPositions: {},
      currentPage: typeof window !== 'undefined' ? window.location.href : '',
      previousPage: '',
    };

    this._onChangeLanguage = this._onChangeLanguage.bind(this);
    this._onCookieConsentGiven = this._onCookieConsentGiven.bind(this);
    this._onCookieSettingsClick = this._onCookieSettingsClick.bind(this);
    this._onChangeContentfulEnvironment =
      this._onChangeContentfulEnvironment.bind(this);
    this._onTogglePreview = this._onTogglePreview.bind(this);
    this._historyEvent = this._historyEvent.bind(this);
    this.updateConsent = this.updateConsent.bind(this);
    this._scrollRestoration = this._scrollRestoration.bind(this);
    this._saveScrollPosition = this._saveScrollPosition.bind(this);
  }

  onLoadResult(loadResult: LoadResult): void {
    if (isRootCacheKey(loadResult.key)) {
      this.setState({ root: loadResult.response });
      this.updateConsent(loadResult.response);
    } else if (isPageCacheKey(loadResult.key)) {
      const page = loadResult.response as PageType;
      this.setState({ currentPageAlternates: page });
    }
  }

  updateConsent(root: RootType): void {
    const { cookieService, staticConfig, reporterService } = this.props;
    const { appSettings } = this.state;

    const consentString = cookieService.getConsentCookie();
    const currentConsent = new Consent(consentString);
    let rootPolicyVersion = root.cookie.policyVersion;

    if (rootPolicyVersion === undefined) {
      const warn = new MissingContentWarning('Privacy policy version');
      reporterService.reportError(warn);
      rootPolicyVersion = 'no-version';
    }

    if (currentConsent.versionIsValid(rootPolicyVersion)) {
      if (this.state.consent.resolve !== null) {
        this.state.consent.resolve(currentConsent);
        this.setState({
          consent: { promise: this.state.consent.promise, resolve: null },
          appSettings: new AppSettings({
            isCookieBannerClosed: appSettings.isCookieBannerClosed,
            staticConfig,
            cookieService,
            consent: currentConsent,
            isoLanguage: appSettings.language.currentIso,
          }),
        });
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  _historyEvent(location: Location, action: Action) {
    const { currentPage } = this.state;

    this.setState({
      currentPage: window.location.href,
      previousPage: currentPage,
    });
    if (action) {
      const urlLanguage = urlutil.getLanguageIsoFromUrl(location.pathname);
      if (
        urlLanguage &&
        urlLanguage !== this.state.appSettings.language.currentIso
      ) {
        // language was changed during navigation
        this._onChangeLanguage(urlLanguage);
      }
      this._saveScrollPosition(this.props.location.pathname);
    }
  }

  componentDidMount(...args) {
    const { reporterService } = this.props;
    super.componentDidMount.apply(this, args);
    this.setState({
      historyUnregistration: this.props.history.listen(this._historyEvent),
    });
    this.state.consent.promise.then((consent) =>
      reporterService.activate(consent),
    );
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.state.historyUnregistration && this.state.historyUnregistration();
    this.setState({ historyUnregistration: null });
  }

  componentDidUpdate(prevProps) {
    const { reporterService } = this.props;
    const reporterServiceDidChange =
      prevProps.reporterService !== reporterService;
    if (reporterService && reporterServiceDidChange) {
      this.state.consent.promise.then((consent) =>
        reporterService.activate(consent),
      );
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._scrollRestoration();
    }
  }

  _onChangeContentfulEnvironment(environment) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setEnvironment(environment);
    this.setState({
      appSettings: new AppSettings({
        isCookieBannerClosed: appSettings.isCookieBannerClosed,
        staticConfig,
        cookieService,
        consent: appSettings.consent,
        isoLanguage: appSettings.language.currentIso,
      }),
    });
  }

  _onTogglePreview() {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setPreview(!appSettings.contentful.isPreview);
    this.setState({
      appSettings: new AppSettings({
        isCookieBannerClosed: appSettings.isCookieBannerClosed,
        staticConfig,
        cookieService,
        consent: appSettings.consent,
        isoLanguage: appSettings.language.currentIso,
      }),
    });
  }

  _onCookieConsentGiven(consent) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setConsentCookie(consent.getCookieString());
    this._updateLanguage(appSettings.language.currentIso);
    if (this.state.consent.resolve !== null) {
      this.state.consent.resolve(consent);
      this.setState({
        consent: { promise: this.state.consent.promise, resolve: null },
        appSettings: new AppSettings({
          isCookieBannerClosed: appSettings.isCookieBannerClosed,
          staticConfig,
          cookieService,
          consent,
          isoLanguage: appSettings.language.currentIso,
        }),
      });
    } else {
      this.setState({
        consent: {
          promise: new Promise<Consent>((resolve) => {
            resolve(consent);
          }),
          resolve: null,
        },
        appSettings: new AppSettings({
          isCookieBannerClosed: !appSettings.isCookieBannerClosed,
          staticConfig,
          cookieService,
          consent,
          isoLanguage: appSettings.language.currentIso,
        }),
      });
    }
  }

  _onCookieSettingsClick() {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    this.setState({
      appSettings: new AppSettings({
        isCookieBannerClosed: !appSettings.isCookieBannerClosed,
        staticConfig,
        cookieService,
        consent: appSettings.consent,
        isoLanguage: appSettings.language.currentIso,
      }),
    });
  }

  _updateLanguage(lng) {
    const { cookieService } = this.props;
    const { consent } = this.state;
    this.props.i18n.changeLanguage(lng);
    consent.promise.then(() => {
      cookieService.setLanguage(lng);
    });
    if (typeof window !== 'undefined') {
      document.documentElement.lang = lng;
    }
  }

  _onChangeLanguage(lng) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    this._updateLanguage(lng);
    this.setState({
      appSettings: new AppSettings({
        isCookieBannerClosed: appSettings.isCookieBannerClosed,
        staticConfig,
        consent: appSettings.consent,
        cookieService,
        isoLanguage: lng,
      }),
    });
  }

  _saveScrollPosition = (pathname: string) => {
    this.setState((prevState) => ({
      scrollPositions: {
        ...prevState.scrollPositions,
        [pathname]: window.scrollY,
      },
    }));
  };

  _scrollRestoration() {
    window.scrollTo({ top: 0, behavior: 'instant' });
    if (this.props.history.action === 'POP') {
      const savedPosition =
        this.state.scrollPositions[this.props.location.pathname];
      setTimeout(() => {
        if (savedPosition !== undefined) {
          this._stepScroll(savedPosition, 1500, 100); // target scroll position, step, delay
        }
      }, 100);
    }
  }

  _stepScroll = (targetScrollPosition: number, step: number, delay: number) => {
    const scrollStep = () => {
      const currentScroll = window.scrollY;
      if (currentScroll !== targetScrollPosition) {
        window.scrollTo({
          top: Math.min(targetScrollPosition, currentScroll + step),
          behavior: 'instant',
        });
        setTimeout(() => {
          this._stepScroll(targetScrollPosition, step, delay);
        }, delay);
      }
    };
    scrollStep();
  };

  render() {
    const { staticConfig, reporterService, cookieService } = this.props;

    const eventHandlers = {
      onChangeLanguage: this._onChangeLanguage,
      onChangeContentfulEnvironment: this._onChangeContentfulEnvironment,
      onTogglePreview: this._onTogglePreview,
      onCookieConsentGiven: this._onCookieConsentGiven,
      onCookieSettingsClick: this._onCookieSettingsClick,
    };
    const services = {
      cookieService,
      reporterService,
    };
    const relatedPagesInfo = {
      allPages: [],
      currentPageId: '',
    };

    const pageHistory = {
      current: this.state.currentPage,
      previous: this.state.previousPage,
    };

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const appConfig = createAppConfig(
      staticConfig,
      this.state.appSettings,
      this.state.currentPageAlternates,
      services,
      eventHandlers,
      relatedPagesInfo,
      pageHistory,
    );

    return (
      <AppConfigContext.Provider value={appConfig}>
        <Root />
      </AppConfigContext.Provider>
    );
  }
}

export default compose<AppInnerProps, AppProps>(
  withAppErrors,
  withTranslation(),
  withRouter,
  withReporterService,
  withAppConfig,
  withLoadingContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(App as any);
