import { Component } from 'react';

import { compose } from 'recompose';
import * as InternalPropTypes from '../constants/internal-types';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import HeroMolecule from './common/molecules/Hero';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import {
  TypeBackgroundColorTypes,
  TypeHeroTypes,
} from '../constants/cms-constants';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../utils/hocs/withImageHelperFactory';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '104px',
    height: 'fit-content',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '128px',
    height: '466px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '154px',
    height: '534px',
  },
  '&.hero-component-img': {
    height: 'fit-content',
  },
  '&.hero-component-video': {
    height: 'fit-content',
    paddingTop: '77px',
    paddingBottom: '26px',
  },
};

type HeroProps = {
  mainHeader?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  video?: InternalPropTypes.Video;
  type?: TypeHeroTypes;
  link?: InternalPropTypes.Link;
  backgroundColor?: TypeBackgroundColorTypes;
};

type HeroInnerProps = WithImageHelperFactoryProps & HeroProps;

export class Hero extends Component<HeroInnerProps> {
  static defaultProps = {
    mainHeader: null,
    lightHeader: null,
    bodyText: null,
    image: null,
    video: null,
    type: null,
    link: null,
    backgroundColor: null,
  };

  render() {
    const {
      mainHeader,
      lightHeader,
      bodyText,
      image: asset,
      video,
      type,
      link,
      backgroundColor,
      imageHelperFactory,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const imageHelper = imageHelperFactory.createImageHelper(asset);
    const isAnimation = imageHelper?.isAnimation();

    let image;
    let animation;
    let className = 'hero-component';
    if (video) {
      className = 'hero-component-video';
    } else if (isAnimation) {
      className = 'hero-component-svg';
      animation = imageHelper.getRawImage() as InternalPropTypes.ImageFile;
    } else if (asset) {
      className = 'hero-component-img';
      image = asset;
    }

    return (
      <div css={[styles, addStyle]} className={className}>
        <ContentOuterBound>
          <ContentSpacer>
            <HeroMolecule
              mainHeader={mainHeader}
              lightHeader={lightHeader}
              text={bodyText}
              type={type}
              image={image}
              video={video}
              animation={animation}
              link={link}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default compose<HeroInnerProps, HeroProps>(withImageHelperFactory)(Hero);
