import { Component } from 'react';
import { css, keyframes } from '@emotion/react';
import 'react-step-progress-bar/styles.css';
import GridEvenSplit from '../atoms/GridEvenSplit';
import Image from '../atoms/Image';
import Caption from '../atoms/Caption';
import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { defaultColors } from '../../../constants/colors';
import TitleTypes from '../../../constants/title-types';
import { ImgixParams } from '../../../types/ts/imgixQuery';

const animations = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
     }
    100% {
      opacity: 1;
     }
  `,
};

const useStyles = css({
  [Breakpoints.Mobile.mq]: {
    height: '624px',
  },
  [Breakpoints.TabletUp.mq]: {
    height: '496px',
  },
  '& .caption.section': {
    color: defaultColors.lightHeader,
    marginBottom: '24px',
  },
  '& .section': {
    width: '264px',
  },
  '& .body-text': {
    height: '105px',
  },
  '& .content': {
    width: '268px',
    [Breakpoints.Mobile.mq]: {
      marginTop: '40px',
    },
    [Breakpoints.Tablet.mq]: {
      marginLeft: 'auto',
    },
    '& .image': {
      animation: `${animations.fadeIn} 800ms forwards ease-in`,
      height: '320px',
      width: 'inherit',
      objectFit: 'cover',
      marginBottom: '16px',
    },
    '& .enquiry-case': {
      animation: `${animations.fadeIn} 800ms forwards ease-in`,
      width: 'calc(100% - 40px)',
    },
  },
});

type SlideShowContentProps = {
  lightHeader?: InternalPropTypes.RichText;
  header?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
};

class SlideShowContent extends Component<SlideShowContentProps> {
  static defaultProps = {
    lightHeader: null,
    header: null,
    bodyText: null,
    image: null,
  };

  render() {
    const { lightHeader, header, image, bodyText } = this.props;

    const sizes: ImgixParams = { w: '268', h: '320' };

    return (
      <div css={[useStyles]}>
        <GridEvenSplit
          onRenderCells={[
            () => (
              <div>
                <Caption type={Caption.types.SECTION}>{lightHeader}</Caption>
                <Title type={TitleTypes.SECTION}>{header}</Title>
              </div>
            ),
            () => (
              <div className="content">
                <Image image={image} sizes={sizes} />
                <BodyText type={BodyText.types.STATIC16}>{bodyText}</BodyText>
              </div>
            ),
          ]}
          snapAtBreakpoint={Breakpoints.Mobile}
        />
      </div>
    );
  }
}

export default SlideShowContent;
