import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import VimeoVideo from './common/atoms/VimeoVideo';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';

const styles = {
  '& .vimeo-container': {
    [Breakpoints.DesktopUp.mq]: {
      height: '675px',
    },
    [Breakpoints.Tablet.mq]: {
      height: '500px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '280px',
    },
    margin: 'auto',
  },
};

type VimeoEmbeddingProps = {
  url?: string;
  loadingAltText?: string;
};

class VimeoEmbedding extends Component<VimeoEmbeddingProps> {
  static defaultProps = {
    url: null,
    loadingAltText: '',
  };

  render() {
    const { url, loadingAltText } = this.props;

    return (
      <div className="vimeo-embedding-component" css={styles}>
        <ContentOuterBound>
          <ContentSpacer>
            <VimeoVideo url={url} loadingAltText={loadingAltText} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default VimeoEmbedding;
