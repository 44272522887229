import { BlockInstanceProps } from '../Block';
import QuoteCarousel from '../../components/QuoteCarousel';
import { ContentTypeEnum, QuoteTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.quoteTypes,
  },
])
class QuoteCarouselBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const type = QuoteTypeEnum[controlOptions?.type];

    let quotes;
    blockContent.doQuery((content) => {
      quotes = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <QuoteCarousel
        quotes={quotes}
        type={type}
        backgroundColor={backgroundColor}
        lightHeaderText={block?.header?.light}
      />
    );
  }
}

export default QuoteCarouselBlock;
