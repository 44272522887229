import { BlockInstanceProps } from '../Block';
import HtmlParser from '../../components/HtmlParser';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class HtmlParserBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const { backgroundColor } = this.controlOptions;

    return (
      <HtmlParser content={block?.bodyText} backgroundColor={backgroundColor} />
    );
  }
}

export default HtmlParserBlock;
