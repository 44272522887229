import { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { css } from '@emotion/react';

import HeuristicResponseTemplate from './HeuristicResponseTemplate';

import redWarning from '../../../assets/red-check-mark.png';
import yellowWarning from '../../../assets/yellow-warning.png';
import greenCheckMark from '../../../assets/green-check-mark.png';

import { STATUS_ERROR, STATUS_WARNING } from '../../../constants/seoStatus';
import { HeuristicsResponseType } from '../../../utils/heuristics/Heuristics';
import backgroundColors from '../../../constants/background-color';

const useStyles = css({
  '.accordion-indicator': {
    float: 'inline-end',
    img: {
      height: '25px',
      width: '25px',
    },
  },
  '.accordion .card::first-of-type': {
    borderTop: 'none',
  },
  '.empty': {
    textAlign: 'center',
    paddingTop: '2em',
    color: backgroundColors.GRAY,
  },
});

function getStatusIcon(status) {
  if (status === STATUS_ERROR) {
    return redWarning;
  }
  if (status === STATUS_WARNING) {
    return yellowWarning;
  }
  return greenCheckMark;
}

function renderResponse(heuristicResponse) {
  // Responses from heuristics can be rendered by template or bespoke display.
  return (
    heuristicResponse.display ?? (
      <HeuristicResponseTemplate
        title={heuristicResponse.title}
        errorMessage={heuristicResponse.error}
        path={heuristicResponse.path}
        description={heuristicResponse.generalText}
      />
    )
  );
}

type SeoAccordionProps = {
  heuristicResponses?: HeuristicsResponseType[];
};

class SeoAccordion extends Component<SeoAccordionProps> {
  static defaultProps = {
    heuristicResponses: [],
  };

  render() {
    const { heuristicResponses } = this.props;
    const isEmpty = !heuristicResponses || heuristicResponses.length === 0;
    return (
      <div css={useStyles}>
        <Accordion>
          {heuristicResponses.map((response, idx) => {
            const eventKey = `${response.name}-${idx}`;
            return (
              <Card key={eventKey}>
                <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                  {response.name}
                  <div className="accordion-indicator">
                    <img
                      src={getStatusIcon(response.status)}
                      alt="Icon indicating level of issue for a seo reader"
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={eventKey}>
                  <Card.Body>{renderResponse(response)}</Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
        {isEmpty ? <p className="empty">Empty</p> : null}
      </div>
    );
  }
}

export default SeoAccordion;
