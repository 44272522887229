import { Component } from 'react';
import GridEvenSplit from '../atoms/GridEvenSplit';
import ProductCard from './ProductCard';
import getRows from '../../../utils/utilFunctions';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .cell': {
    marginTop: '30px',
  },
};

type ProductCardGridProps = {
  items?: InternalPropTypes.MediaItem[];
};

class ProductCardGrid extends Component<ProductCardGridProps> {
  static defaultProps = {
    items: [],
  };

  render() {
    const { items } = this.props;
    const rowsOf3: InternalPropTypes.MediaItem[][] = getRows(items, 3);
    const makeProductCard = (
      mediaItem: InternalPropTypes.MediaItem,
    ): JSX.Element => (
      <ProductCard
        image={mediaItem.image}
        header={mediaItem.header}
        lightHeader={mediaItem.lightHeader}
        link={mediaItem.link}
      />
    );

    return (
      <div css={styles}>
        {rowsOf3.map((row, index) => (
          <GridEvenSplit
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onRenderCells={[
              () => makeProductCard(row[0]),
              () => (row[1] ? makeProductCard(row[1]) : null),
              () => (row[2] ? makeProductCard(row[2]) : null),
            ]}
          />
        ))}
      </div>
    );
  }
}

export default ProductCardGrid;
