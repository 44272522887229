import { ElementType } from 'react';

export const generatePhoneProps = (filteredPhone) =>
  filteredPhone && filteredPhone?.length
    ? {
        as: 'a' as ElementType,
        href: `tel:${filteredPhone}`,
        style: { display: 'block' },
      }
    : {};

export const generateEmailProps = (filteredEmail) =>
  filteredEmail && filteredEmail?.length
    ? {
        as: 'a' as ElementType,
        href: `mailto:${filteredEmail}`,
        style: { display: 'block' },
      }
    : {};
