import React, { Component } from 'react';
import { css } from '@emotion/react';
import { compose } from 'recompose';
import Dropdown from 'react-bootstrap/Dropdown';

import shareImage from '../../../assets/share.png';

import NwLink from '../NwLink';

import withAppConfig, {
  WithAppConfigProps,
} from '../../../utils/hocs/withAppConfig';
import generateEditorLinkToEntry from '../../../utils/contentful';
import { colorEnum } from '../../../constants/colors';
import * as ContentState from '../../../constants/contentstate';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = css({
  [Breakpoints.Mobile.mq]: {
    display: 'none',
  },
  position: 'absolute',
  paddingTop: '2px',
  marginLeft: '98px',
  zIndex: 1002,
  transition: 'opacity .2s ease-in-out',
  '& .btn-primary, & .btn-primary.dropdown-toggle, & .btn-primary.dropdown-toggle:not(:disabled):not(.disabled):active':
    {
      color: colorEnum.black,
      borderColor: colorEnum.white,
    },
  '& .dropdown-toggle': {
    backgroundColor: colorEnum.white,
  },
  '& .dropdown': {
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
    '&.show': {
      opacity: 1,
    },
  },
  '& .dropdown-item': {
    color: colorEnum.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .custom-item': {
    padding: '0.5rem 1.5rem',
    minWidth: '400px',
  },
});

type PagePublishedStateProps = {
  page: InternalPropTypes.Page;
};

type PagePublishedStateInnerProps = WithAppConfigProps &
  PagePublishedStateProps;

class PagePublishedState extends Component<PagePublishedStateInnerProps> {
  render() {
    const { appConfig, page } = this.props;
    const contentfulEntryId = page?.contentfulEntryId;
    const state = page?.state;
    const appSettings = appConfig.settings;

    return (
      <div
        css={styles}
        className="page-state-container"
        key={contentfulEntryId}
      >
        <Dropdown>
          <Dropdown.Toggle size="sm">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={shareImage} height="20" width="20" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>Page</Dropdown.Header>
            <Dropdown.Item
              target="_blank"
              href={generateEditorLinkToEntry(appSettings, contentfulEntryId)}
            >
              {page?.name}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Published state</Dropdown.Header>
            <div className="custom-item">
              <p>
                This page has state {state?.state}
                {state?.aggregatedState !== ContentState.PUBLISHED ? (
                  <>
                    , but some content on this page is <i>not</i> published
                    (lowest state is {state?.aggregatedState})
                  </>
                ) : (
                  ''
                )}
              </p>
              {state?.aggregatedState !== ContentState.PUBLISHED ? (
                <ul>
                  {page?.state?.remarks?.map((remark, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`pageremark-${idx}`}>
                      <NwLink
                        to={generateEditorLinkToEntry(
                          appSettings,
                          remark?.entryId,
                        )}
                        openInNewWindowOrTab
                      >
                        {remark?.entryId}
                      </NwLink>{' '}
                      is {remark?.state}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default compose<PagePublishedStateInnerProps, PagePublishedStateProps>(
  withAppConfig,
)(PagePublishedState);
