import * as blocks from '../containers/blocks';

const initCapWord = (word) => word.charAt(0).toUpperCase() + word.substring(1);

const collapseAndCap = (line) => line.split(/\s+/).map(initCapWord).join('');

const blockMap = {
  'The perfect partner': 'PerfectPartner',
};

// eslint-disable-next-line import/prefer-default-export
export const resolveBlock = (blockObj) => {
  const blockType = blockObj.type;
  let result = null;
  try {
    let guessBlockName = collapseAndCap(blockType);
    let blockExists = !!blocks[guessBlockName];
    if (!blockExists) {
      guessBlockName = blockMap[blockType];
      blockExists = !!blocks[guessBlockName];
    }

    if (blockExists) {
      result = blocks[guessBlockName];
    }
  } catch (e) {
    // do nothing
  }
  return result;
};
