/* eslint-disable max-classes-per-file */
import { ReplaySubject, Subscription } from 'rxjs';
import LoadingCache, { SerializedCache } from './LoadingCache';

export class LoadResult {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public key: string,
    public response: object | undefined,
    public error: Error | object | undefined,
  ) {}

  success() {
    return !this.error;
  }
}

class LoadingPromise<LoadResult> extends Promise<LoadResult> {
  constructor(
    executor: (
      resolve: (value: LoadResult | PromiseLike<LoadResult>) => void,
      reject: (reason?: any) => void,
    ) => void,
    private isClient = false,
  ) {
    super(executor);
  }

  private thenIf(
    callBack: (value: LoadResult) => void | PromiseLike<void>,
    shouldDoIt: boolean,
  ): LoadingPromise<LoadResult> {
    const promise = this.then((result) => {
      if (shouldDoIt) {
        callBack(result);
      }
    }) as LoadingPromise<LoadResult>;
    promise.isClient = this.isClient;
    return promise;
  }

  private finallyIf(
    callBack: () => void,
    condition: boolean,
  ): LoadingPromise<LoadResult> {
    const promise = this.finally(() => {
      if (condition) {
        callBack();
      }
    }) as LoadingPromise<LoadResult>;
    promise.isClient = this.isClient;
    return promise;
  }

  thenIfClient(callBack: (value: LoadResult) => void | PromiseLike<void>) {
    return this.thenIf(callBack, this.isClient);
  }

  finallyIfClient(callBack: () => void) {
    return this.finallyIf(callBack, this.isClient);
  }
}

class LoadingTrace {
  public trace: Array<string>;

  private initialCycleCount: number | null = null;

  constructor(public url: string) {
    this.trace = [];
  }

  addKey(key: string): void {
    if (!this.trace.includes(key)) {
      this.trace.push(key);
    }
  }

  startCycle(): void {
    this.initialCycleCount = this.trace.length;
  }

  endCycle(): boolean {
    if (this.initialCycleCount === null) {
      throw new Error('Cannot end cycle that was never started');
    }
    const result = this.trace.length > this.initialCycleCount;
    this.initialCycleCount = null;
    return result;
  }
}

export default class LoadingManager {
  subject = new ReplaySubject<LoadResult>(3);

  liveFetches: Map<string, Promise<LoadResult>>;

  failedFetches: Map<string, Error | object>;

  constructor(public cache: LoadingCache, public isClientSide: boolean) {
    this._liveFetchCompleted = this._liveFetchCompleted.bind(this);
    this._liveFetchFailed = this._liveFetchFailed.bind(this);
  }

  /**
   * Setup interception of any LoadResult that has been resolved
   * @param onEvent The callback that will be called
   * @see unsubscribeToLoadsOnCache
   * @returns A subscription that represents the callback
   */
  subscribeToLoadsOnCache(
    onEvent: (loadResult: LoadResult) => void,
  ): Subscription {
    return this.subject.asObservable().subscribe(onEvent);
  }

  /**
   * Remove the subscription setup prior
   * @param subscription The subscription that was returned upon setup
   * @see subscribeToLoadsOnCache
   */
  // eslint-disable-next-line class-methods-use-this
  unsubscribeToLoadsOnCache(subscription: Subscription) {
    subscription.unsubscribe();
  }

  /**
   * Establishes cache from a "frozen" state
   * @param serializedCache A cache that was previously serialized
   * @returns A promise that indicates, when the cache has been initialized
   */
  initializeForHydration(serializedCache: SerializedCache) {
    return this.cache.initializeFromSerialized(serializedCache);
  }

  /**
   *
   * @returns Url for the page that is currently being traced
   */
  getCurrentUrl(): string {
    return this.isClientSide ? window.location.href : '';
  }

  /**
   * Check if there is a prepared and ready response for a given cache key
   * @param key Given cache key
   * @returns True if mapped response is ready for immediate retrieval
   */
  hasResponse(key: string) {
    return this.cache.hasKey(key) || this.failedFetches.has(key);
  }

  /**
   * Check if the manager has a prepared response or a response underway for the given key
   * @param key Given cache key
   * @returns True if there is a completed, failed or live response for the given key
   */
  hasKey(key: string) {
    return this.liveFetches.has(key) || this.hasResponse(key);
  }

  /**
   * Start an async process to fetch data that will be cached and later transferred to the front end for hydration
   * @param cacheKey The given cache key
   * @param loadingPromise Promise that will return the final value that the components requires to be loaded
   * @returns A promise that resolves once the fetch has resolved
   */
  fetch(cacheKey: string, loadingPromise: Promise<object>) {
    if (this.hasResponse(cacheKey)) {
      throw new Error('Tried to add fetch that is already scheduled');
    }
    const fetchPromise = new LoadingPromise<LoadResult>(
      (
        resolve: (value: LoadResult | PromiseLike<LoadResult>) => void,
      ): void => {
        loadingPromise
          .then((promiseResult) =>
            this._liveFetchCompleted(cacheKey, promiseResult),
          )
          .catch((error) => this._liveFetchFailed(cacheKey, error))
          // always resolve with a LoadResult as that can also carry errors
          .finally(() => resolve(this.retrieveLoadResult(cacheKey)));
      },
      this.isClientSide,
    ).finallyIfClient(() => {
      // avoid cache build-up on the client side
      this.clearKey(cacheKey);
    });
    this.liveFetches.set(cacheKey, fetchPromise);

    return fetchPromise;
  }

  _liveFetchCompleted(key: string, loadingPromiseResult: object): void {
    this.cache.add(key, loadingPromiseResult);
    this.liveFetches.delete(key);
  }

  _liveFetchFailed(key: string, error: Error): void {
    this.liveFetches.delete(key);
    this.failedFetches.set(key, error);
  }

  /**
   * Fetch a completed LoadResult
   * @param key The given cache key
   * @returns A prepared LoadResult with either data or error
   */
  retrieveLoadResult(key: string): LoadResult {
    if (!this.hasResponse(key)) {
      throw new Error('Cannot retrieve load not in cache');
    }
    let error: Error | object | undefined;
    if (this.failedFetches.has(key)) {
      error = this.failedFetches.get(key);
    }
    let response: object | undefined;
    if (this.cache.hasValid(key)) {
      response = this.cache.get(key);
    }
    const result = new LoadResult(key, response, error);
    this.subject.next(result);
    return result;
  }

  clear(): void {
    this.cache.evictAll();
    this.liveFetches = new Map();
    this.failedFetches = new Map();
  }

  clearKey(key: string): void {
    this.cache.remove(key);
    this.liveFetches.delete(key);
    this.failedFetches.delete(key);
  }

  resolveFetches(): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    const requestCount = this.liveFetches.size;
    return new Promise((resolve, reject) => {
      if (requestCount > 0) {
        Promise.all(this.liveFetches.values()).then(() => {
          if (me.failedFetches.size > 0) {
            reject(me.failedFetches.values());
          } else {
            resolve(true);
          }
        });
      } else {
        resolve(false);
      }
    });
  }
}

export class SimpleLoadingManager extends LoadingManager {
  constructor(cacheTimeToLiveInSecs: number, isClientSide = false) {
    super(new LoadingCache(cacheTimeToLiveInSecs), isClientSide);
    this.clear();
  }
}

export class TracableLoadingManager extends LoadingManager {
  loadingTrace: LoadingTrace | null = null;

  constructor(loadingManager: LoadingManager, url: string) {
    super(loadingManager.cache, loadingManager.isClientSide);
    this.liveFetches = new Map();
    this.failedFetches = new Map();
    this.prepareForNextTrace(url);
  }

  /**
   *
   * @returns A serialized cache for the current trace
   */
  serializeCurrentTraceForHydration() {
    return this.cache.serializeForClient(this.loadingTrace?.trace || []);
  }

  /**
   * Resets any prior tracing done and prepared for a new trace at the given url
   * @param url The url for the page that is being traced
   */
  prepareForNextTrace(url: string) {
    this.loadingTrace = new LoadingTrace(url);
    this.failedFetches.clear();
  }

  fetch(cacheKey: string, loadingPromise: Promise<object>) {
    // eslint-disable-next-line prefer-rest-params
    const result = LoadingManager.prototype.fetch.apply(this, arguments);
    // note that this request is part of the current trace for later transfer to client
    // eslint-disable-next-line no-unused-expressions
    this.loadingTrace && this.loadingTrace.addKey(cacheKey);
    return result;
  }

  retrieveLoadResult(key: string): LoadResult {
    const result = LoadingManager.prototype.retrieveLoadResult.apply(
      this,
      // eslint-disable-next-line prefer-rest-params
      arguments,
    );
    // eslint-disable-next-line no-unused-expressions
    this.loadingTrace && this.loadingTrace.addKey(key);
    return result;
  }

  /**
   * Starts a loading cycle where it is expected to have loads added.
   */
  startCycle(): void {
    this.loadingTrace?.startCycle();
  }

  /**
   * Ends the loading cycle
   * @returns true if anything new was loaded in this cycle
   */
  endCycle(): boolean {
    if (!this.loadingTrace) {
      throw new Error('Cannot end a cycle without a loading trace set');
    }
    return this.loadingTrace?.endCycle();
  }

  /**
   *
   * @returns Url for the page that is currently being traced
   */
  getCurrentUrl(): string {
    return this.isClientSide
      ? window.location.href
      : this.loadingTrace?.url || '';
  }
}
