import { Component, PropsWithChildren } from 'react';
import { css } from '@emotion/react';

const styles = css({
  paddingTop: '70%', // force height of box
  position: 'relative',
  '& .cell': {
    position: 'absolute',
    '& .image': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  '& .cell-1': {
    width: '30%',
    height: '50%',
    left: 0,
    top: '25%',
  },
  '& .cell-2': {
    width: '50%',
    height: '100%',
    right: 0,
    top: 0,
  },
});

type Grid2CellBrokenProps = PropsWithChildren;

class Grid2CellBroken extends Component<Grid2CellBrokenProps> {
  static defaultProps = {
    children: [],
  };

  render() {
    const { children } = this.props;

    return (
      <div css={styles}>
        <div className="cell cell-1">{children[0]}</div>
        <div className="cell cell-2">{children[1]}</div>
      </div>
    );
  }
}

export default Grid2CellBroken;
