export enum displayTypes {
  dropdown = 'dropdown',
  text = 'text',
  boolean = 'boolean',
}

type Validation = {
  enum?: object;
  regexp?: string;
  errorText?: string;
};

export type ControlOptionDefinition = {
  displayType: displayTypes;
  validation?: Validation;
};
