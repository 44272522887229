/* eslint-disable no-param-reassign */
import Random from './random';

export default function getRows(items, cols = 1) {
  const array = [[]];
  let tabooList = [];
  const random = new Random(26);
  items.map((item) =>
    array[array.length - 1].length !== cols
      ? array[array.length - 1].push(item)
      : array.push([item]),
  );
  const margins = ['-80px', '-64px', '-48px', '48px', '64px', '80px'];
  const paddings = ['24px', '32px', '40px', '48px'];
  const mobilePaddings = ['0', '48px', '64px', '80px', '96px', '112px'];
  const mobileHeights = ['232px', '200px', '256px', '264px', '280px', '296px'];
  const tabletHeights = ['232px', '232px', '200px', '200px', '296px', '296px'];
  const desktopHeights = ['288px', '288px', '256px', '256px', '368px', '368px'];
  array.forEach((row, index) => {
    let margin = margins[Math.floor(random.getNextRandom() * margins.length)];
    while (tabooList.includes(margin)) {
      margin = paddings[Math.floor(random.getNextRandom() * paddings.length)];
    }
    tabooList.push(margin);
    tabooList = tabooList.length === margins.length ? [] : tabooList;
    const mobilePadding =
      (index + 1) % 3 === 0
        ? mobilePaddings[
            Math.floor(random.getNextRandom() * (mobilePaddings.length - 2)) + 2
          ]
        : mobilePaddings[
            Math.floor(random.getNextRandom() * mobilePaddings.length)
          ];
    row[0].padding =
      paddings[Math.floor(random.getNextRandom() * paddings.length)];
    row[0].margin = margin;
    row[0].tabletHeight = tabletHeights[margins.indexOf(row[0].margin)];
    row[0].desktopHeight = desktopHeights[margins.indexOf(row[0].margin)];
    row[0].mobilePadding = mobilePadding;
    row[0].mobileHeight =
      mobileHeights[mobilePaddings.indexOf(row[0].mobilePadding)];
    if (row[1] && cols === 2) {
      row[1].padding =
        paddings[Math.floor(random.getNextRandom() * paddings.length)];
      row[1].margin =
        margins[margins.length - 1 - margins.indexOf(row[0].margin)];
      row[1].tabletHeight = tabletHeights[margins.indexOf(row[1].margin)];
      row[1].desktopHeight = desktopHeights[margins.indexOf(row[1].margin)];
    }
  });
  return array;
}
