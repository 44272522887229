import { Component, FormEvent } from 'react';
import { css } from '@emotion/react';

import TrackedComponent from '../TrackedComponent';
import { BackgroundColorEnum } from '../../../constants/cms-constants';
import BodyText from '../atoms/BodyText';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import angledArrow from '../../../assets/angledArrow.png';

const styles = css({
  '& .message-form, .status-message': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '20px',
      maxWidth: '60%',
    },
  },
  '& .status-message': {
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '50%',
    },
    fontSize: '32px',
    maxWidth: '100%',
  },
  '& .body-text': {
    color: colorEnum.gray,
  },
  '& .message-form-description, .status-message': {
    color: colorEnum.gray,
    marginBottom: '20px',
  },
  '& .name-label, .email-label, .msg-label': {
    color: colorEnum.darkGrey,
    fontSize: '24px',
    '& p': {
      marginBottom: 0,
    },
  },
  '& .name-input, .email-input, .msg-input, .submit-input': {
    border: 0,
    outline: 0,
    borderBottom: '1px solid grey',
    color: colorEnum.brightGray,
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    marginTop: 0,
    marginBottom: '20px',
    width: '100%',
    fontSize: '18px',
  },
  '& .msg-input': {
    resize: 'none',
    overflow: 'hidden',
    minHeight: '160px',
    height: 'unset',
  },
  '& .submit-input': {
    border: 0,
    position: 'absolute',
    right: 0,
  },
  '& .submit-button': {
    [Breakpoints.Mobile.mq]: {
      marginRight: '40%',
    },
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    border: 0,
    outline: 0,
    position: 'absolute',
    right: 0,
    display: 'flex',
    '& .submit-arrow': {
      height: '20px',
      width: '25px',
      marginRight: '10px',
      marginTop: '13px',
    },
    '& .submit-text': {
      fontSize: '28px',
      fontWeight: '700',
      color: colorEnum.brightGray,
    },
  },
});

type MessageFormProps = {
  description?: InternalPropTypes.RichText;
  submitMessage?: InternalPropTypes.RichText;
  failedSubmitMessage?: InternalPropTypes.RichText;
  namePlaceholder?: string;
  emailPlaceholder?: string;
  messagePlaceholder?: string;
  submitButtonText?: InternalPropTypes.RichText;
  sendingMessage?: InternalPropTypes.RichText;
  submit?: (...args: any[]) => Promise<Response>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface IMessageFormState {
  messageFormName?: string; // eslint-disable-line react/no-unused-state
  email?: string; // eslint-disable-line react/no-unused-state
  message?: string; // eslint-disable-line react/no-unused-state
  showForm?: boolean;
  successfulSubmit?: boolean;
  submitActive?: boolean;
}

class MessageForm extends Component<MessageFormProps, IMessageFormState> {
  static defaultProps = {
    description: null,
    submitMessage: null,
    failedSubmitMessage: null,
    namePlaceholder: null,
    emailPlaceholder: null,
    messagePlaceholder: null,
    submitButtonText: null,
    sendingMessage: null,
    submit: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      messageFormName: '', // eslint-disable-line react/no-unused-state
      email: '', // eslint-disable-line react/no-unused-state
      message: '', // eslint-disable-line react/no-unused-state
      showForm: true,
      successfulSubmit: false,
      submitActive: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleSubmit(
    e: FormEvent<HTMLFormElement>,
    state: IMessageFormState,
    submit: typeof this.props.submit,
  ) {
    e.preventDefault();
    if (!state.submitActive) {
      this.setState({ showForm: false, submitActive: true });
      submit(state.messageFormName, state.email, state.message)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.setState({ successfulSubmit: response.ok });
        })
        .catch(() => {
          this.setState({ successfulSubmit: false });
        })
        .finally(() => {
          this.setState({ submitActive: false });
        });
    }
  }

  handleNameChange(e) {
    this.setState({ messageFormName: e.target.value }); // eslint-disable-line react/no-unused-state
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value }); // eslint-disable-line react/no-unused-state
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value }); // eslint-disable-line react/no-unused-state
  }

  handleClick() {
    this.setState({ showForm: true });
  }

  render() {
    const {
      description,
      submitMessage,
      failedSubmitMessage,
      namePlaceholder,
      emailPlaceholder,
      messagePlaceholder,
      submitButtonText,
      submit,
      sendingMessage,
    } = this.props;
    const { state } = this;

    return (
      <TrackedComponent logText="Information form seen" trackOncePrSession>
        <div css={styles}>
          {state.showForm && !state.submitActive && (
            <div className="message-form">
              <BodyText className="message-form-description">
                {description}
              </BodyText>
              <form
                id="message-form"
                autoComplete="off"
                onSubmit={(e) => this.handleSubmit(e, state, submit)}
              >
                <input
                  className="name-input"
                  type="text"
                  autoComplete="invalid-string"
                  required
                  placeholder={namePlaceholder}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onChange={(e) => this.handleNameChange(e)}
                />

                <input
                  className="email-input"
                  type="text"
                  pattern="[^@\s]+@[^@\s]+\.+[^@\s]+"
                  autoComplete="invalid-string"
                  required
                  placeholder={emailPlaceholder}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onChange={(e) => this.handleEmailChange(e)}
                />

                <textarea
                  className="msg-input"
                  autoComplete="invalid-string"
                  required
                  placeholder={messagePlaceholder}
                  onChange={(e) => this.handleMessageChange(e)}
                />

                <button
                  type="submit"
                  className="submit-button"
                  formAction="/action_page.php"
                >
                  <img
                    className="submit-arrow"
                    src={angledArrow}
                    alt="arrow"
                    style={{ filter: 'invert(1)' }}
                  />
                  <BodyText className="submit-text">
                    {submitButtonText}
                  </BodyText>
                </button>
              </form>
            </div>
          )}

          {state.submitActive && (
            <BodyText className="status-message">{sendingMessage}</BodyText>
          )}

          {!state.showForm && state.successfulSubmit && !state.submitActive && (
            <BodyText className="status-message">{submitMessage}</BodyText>
          )}

          {!state.showForm && !state.successfulSubmit && !state.submitActive && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div onClick={() => this.handleClick()}>
              <BodyText className="status-message">
                {failedSubmitMessage}
              </BodyText>
            </div>
          )}
        </div>
      </TrackedComponent>
    );
  }
}

export default MessageForm;
