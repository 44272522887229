import { Component } from 'react';

import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

const styles = {
  '& .body-text': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '24px',
    },
    [Breakpoints.Tablet.mq]: {
      width: '90%',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '80px',
    },
    [Breakpoints.DesktopUp.mq]: {
      width: '80%',
    },
  },
};

type CasesHeroProps = {
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
};

class CasesHero extends Component<CasesHeroProps> {
  static defaultProps = {
    header: null,
    lightHeader: null,
  };

  render() {
    const { header, lightHeader } = this.props;

    return (
      <div css={styles}>
        <Title seoWeight="h1" type={TitleTypes.CASES}>
          {header}
        </Title>
        <BodyText as="p" type={BodyText.types.QUOTEBLOCK}>
          {lightHeader}
        </BodyText>
      </div>
    );
  }
}

export default CasesHero;
