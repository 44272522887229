export default class Random {
  _seed: number;

  constructor(seed) {
    this._seed = seed;
  }

  getNextRandom() {
    const x = Math.sin((this._seed += 1)) * 10000;
    return x - Math.floor(x);
  }
}
