import {
  ControlOptionDefinition,
  displayTypes,
} from '../../types/ts/controlOptions';
import { backgroundColors } from '../../constants/background-color';
import { fontColors } from '../../constants/fontColors';

const hexColor: ControlOptionDefinition = {
  displayType: displayTypes.text,
  validation: {
    regexp: '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$',
    errorText: 'Must be a valid hex color code.',
  },
};

const boolean: ControlOptionDefinition = {
  displayType: displayTypes.boolean,
};

const backgroundColor: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: backgroundColors },
};

const fontColor: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: fontColors },
};

const fixedControlOptions = {
  hexColor,
  boolean,
  backgroundColor,
  fontColor,
};

export default fixedControlOptions;
