import { ImgixImage } from '../../../types/ts/contentful';
import ImageHelper from './imageHelper';
import { ImageFile } from '../../../constants/internal-types';

class ImgixImageHelper implements ImageHelper {
  private _image: ImgixImage;

  constructor(image: ImgixImage) {
    this._image = image;
  }

  // eslint-disable-next-line class-methods-use-this
  isAnimation() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  supportsImgix() {
    return true;
  }

  getImageUrl() {
    return this._image.src;
  }

  getAssetName() {
    return this._image.selectedSource.name;
  }

  // eslint-disable-next-line class-methods-use-this
  getImageSize() {
    return {};
  }

  // eslint-disable-next-line class-methods-use-this
  getImageSizeInBytes() {
    return undefined;
  }

  getRawImage(): ImgixImage {
    return this._image;
  }
}

export default ImgixImageHelper;
