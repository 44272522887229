import { Component, ComponentProps } from 'react';

// this component is used to wrap components that are only needed on client side
const TwoPassRender = (WrappedComponent) =>
  class Wrapper extends Component<
    ComponentProps<typeof WrappedComponent>,
    { mounted: boolean }
  > {
    constructor(props) {
      super(props);
      this.state = { mounted: false };
    }

    componentDidMount() {
      this.setState({ mounted: true });
    }

    render() {
      const { mounted } = this.state;
      // eslint-disable-next-line react/jsx-filename-extension
      return mounted ? <WrappedComponent {...this.props} /> : null;
    }
  };

export default TwoPassRender;
