import { Component } from 'react';
import { css } from '@emotion/react';
import BootstrapTable from 'react-bootstrap/Table';

import backgroundColors from '../../../constants/background-color';

const styles = css({
  '& .header_row': {
    fontWeight: 'bold',
    backgroundColor: backgroundColors.LIGHTGRAY,
  },
  '& .table': {
    marginBottom: '0px',
  },
});

type TableProps = {
  values?: object[];
};

class Table extends Component<TableProps> {
  static defaultProps = {
    values: [],
  };

  render() {
    const { values } = this.props;
    const cellHeaders = Object.keys(values?.[0]);

    return (
      <BootstrapTable
        css={styles}
        className="table"
        bordered
        responsive
        hover
        size="sm"
        width="100%"
      >
        <thead>
          <tr key="row 0">
            {cellHeaders.map((field) => (
              <td
                width={`${100 / cellHeaders.length}%`}
                className="header_row"
                key={field}
              >
                {field}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {values.map((row, i) => (
            <tr key={`row ${i + 1}`}>
              {Object.values(row).map((value, j) => (
                // eslint-disable-next-line react/no-array-index-key
                <td key={`cell [${i},${j}]`}> {value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>
    );
  }
}

export default Table;
