import * as CookieConstants from '../client-server-utils/Cookie';
import { CONSENTCATEGORIES, consentEnum } from '../constants/consentCategories';

export default class Consent {
  private version: string | Date;

  constructor(consentString?: string, version?: string) {
    if (!consentString) {
      this.version = version;
      this.setNecessaryCookieConsent();
    } else if (consentString === 'all') {
      this.version = version;
      this.setAllCookieConsent();
    } else if (consentString === 'necessary') {
      this.version = version;
      this.setNecessaryCookieConsent();
    } else {
      const parsedConsentCookie = JSON.parse(consentString);
      Object.assign(this, parsedConsentCookie);
    }
  }

  static initializeConsent() {
    let resolveConsentFunction = null;

    const consentPromise = new Promise<Consent>((resolve, reject) => {
      try {
        resolveConsentFunction = resolve;
      } catch (e) {
        reject(e);
      }
    });

    return { consentPromise, resolveConsentFunction };
  }

  public versionIsValid(rootPolicyVersion): boolean {
    return this.version === rootPolicyVersion;
  }

  private setNecessaryCookieConsent() {
    CONSENTCATEGORIES.forEach((item) => {
      this[item.key] = item.key === consentEnum.NECESSARY;
    });
  }

  private setAllCookieConsent() {
    CONSENTCATEGORIES.forEach((item) => {
      this[item.key] = true;
    });
  }

  public updateCookieConsent(key, value) {
    this[key] = value;
    return this;
  }

  public getCookieString() {
    return JSON.stringify(this);
  }
}
