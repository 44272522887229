import { Heuristics, HeuristicsResponseType } from './Heuristics';
import { STATUS_ERROR, STATUS_OKAY } from '../../constants/seoStatus';
import 'core-js/es/promise';
import { getPageUrlFromPageReference } from '../url';
import generateEditorLinkToEntry from '../contentful';
import { PagePresentationEnum } from '../../constants/cms-constants';
import { PageReference, Page, Root } from '../../constants/internal-types';

export default class PageTitleHeuristic extends Heuristics {
  // eslint-disable-next-line class-methods-use-this
  checkHeuristics(
    page: Page,
    root: Root,
  ): Promise<Array<HeuristicsResponseType>> {
    return new Promise((resolve) => {
      let result: Array<HeuristicsResponseType> = [];
      if (page && root) {
        const { alternatePages } = page;
        const allPages = root?.pages || [];
        const response: HeuristicsResponseType = {
          name: 'Page alternate versions',
          generalText: this.getGeneralText(),
        } as HeuristicsResponseType;
        const alternatePageInSameLanguage =
          this.checkAlternateIsSameLanguage(page);
        if (alternatePageInSameLanguage) {
          response.title = `"${page.name}" has the same language as its alternate page "${alternatePageInSameLanguage.name}"`;
          response.error =
            'An alternate page is in the same language as the current page.';
          response.status = STATUS_ERROR;
          response.path = this.getFixSameLanguageText(
            page,
            alternatePageInSameLanguage,
            allPages,
          );
        } else if (alternatePages && alternatePages.length === 0) {
          response.title = 'This page does not have an alternate page.';
          response.status = STATUS_OKAY;
          response.path = this.getNoAlternatePageText(page, allPages);
        } else {
          response.title = this.getHasAlternatePagesText(page);
          response.status = STATUS_OKAY;
        }
        result = [response];
      }
      resolve(result);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getGeneralText() {
    return (
      <div>
        An alternate page helps search engines to identify which languages the
        pages exists in. This information is used to deliver the best match
        based on the preferred language of the searcher. It is not strictly
        necessary, but if a page exists in multiple languages these pages should
        refer to each other. Best practises for a alternate page references are:
        <ul>
          <li> A page should have a reference to all versions of that page.</li>
          <li> If PageA refer to PageB then PageB should also refer PageA.</li>
          <li> Do not refer to a page of the same language.</li>
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  checkAlternateIsSameLanguage(page: Page) {
    return page?.alternatePages?.find(
      (altPage) => altPage?.language === page?.language,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getNoAlternatePageText(currentPage: Page, allPages: Array<Page>) {
    return (
      <div>
        To declare an alternate page in Contentful go to
        <a href={this._getContentfulLink(currentPage, allPages)}>
          {` ${currentPage?.name} `}
        </a>
        and add or change the page reference.
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getHasAlternatePagesText(currentPage: Page) {
    return (
      <div>
        This page has the alternate pages:
        <ul>
          {currentPage?.alternatePages &&
            currentPage.alternatePages.map((page) => (
              <li key={page?.name}>{page?.name}</li>
            ))}
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixMutualText(
    currentPage: Page,
    alternatePages: Array<Page>,
    allPages: Array<Page>,
  ) {
    return (
      <div>
        On the following pages add &quot;{currentPage?.name}&quot; as an
        alternate page:
        <ul>
          {alternatePages.map((altPage) => (
            <li key={altPage?.name}>
              <a href={this._getContentfulLink(altPage, allPages)}>
                {altPage?.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFixSameLanguageText(
    currentPage: Page,
    alternatePage: PageReference,
    allPages: Array<Page>,
  ) {
    return (
      <div>
        Go to
        <a href={this._getContentfulLink(currentPage, allPages)}>
          {` ${currentPage?.name} `}
        </a>
        and remove the page reference to &quot;{alternatePage?.name}&quot;.;
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _getContentfulLink(pageRef: Page, allPages: Array<Page>) {
    let entryId;
    if (pageRef?.contentfulEntryId) {
      entryId = pageRef.contentfulEntryId;
    } else {
      const page =
        pageRef &&
        allPages
          .filter(
            (page) =>
              page?.presentation !== PagePresentationEnum.MODAL &&
              pageRef.language === page?.language,
          )
          .find(
            (page) =>
              getPageUrlFromPageReference(pageRef) ===
              getPageUrlFromPageReference(page),
          );
      entryId = page?.contentfulEntryId;
    }
    return generateEditorLinkToEntry(this.appSettings, entryId);
  }
}
