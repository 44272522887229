/* eslint-disable max-classes-per-file */
import { Component, PropsWithChildren } from 'react';
import { css } from '@emotion/react';

const style = css({
  marginTop: '1em',
  '.heuristics-row': {
    display: 'flex',
  },
  '.heuristics-row + .heuristics-row': {
    marginTop: '5px',
  },
  '.heuristics-title-column': {
    minWidth: '100px',
    textAlign: 'right',
    marginRight: '50px',
    fontWeight: '200',
  },
  '.heuristics-content-column': {},
});

type HeuristicResponseRowProps = {
  genTitleContent?: (...args: any[]) => React.ReactNode;
  genColumnContent?: (...args: any[]) => React.ReactNode;
};

export class HeuristicResponseRow extends Component<HeuristicResponseRowProps> {
  static defaultProps = {
    genTitleContent: null,
    genColumnContent: null,
  };

  render() {
    const { genTitleContent, genColumnContent } = this.props;
    return (
      <div className="heuristics-row">
        <div className="heuristics-title-column">
          {genTitleContent ? genTitleContent() : null}
        </div>
        <div className="heuristics-content-column heavy mb-2">
          {genColumnContent ? genColumnContent() : null}
        </div>
      </div>
    );
  }
}

type HeuristicResponseTemplateProps = PropsWithChildren & {
  title?: React.ReactNode;
  errorMessage?: string;
  path?: React.ReactNode;
  description?: React.ReactNode;
};

class HeuristicResponseTemplate extends Component<HeuristicResponseTemplateProps> {
  static defaultProps = {
    title: '',
    errorMessage: '',
    path: '',
    description: null,
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { title, errorMessage, path, description, children } = this.props;

    return (
      <div css={style}>
        {title ? (
          <HeuristicResponseRow genColumnContent={() => <h5>{title}</h5>} />
        ) : null}
        {errorMessage ? (
          <HeuristicResponseRow
            genTitleContent={() => 'Error'}
            genColumnContent={() => errorMessage}
          />
        ) : null}
        {path ? (
          <HeuristicResponseRow
            genTitleContent={() => 'How to fix'}
            genColumnContent={() => path}
          />
        ) : null}
        {description ? (
          <HeuristicResponseRow
            genTitleContent={() => 'Description'}
            genColumnContent={() => description}
          />
        ) : null}
        {children}
      </div>
    );
  }
}

export default HeuristicResponseTemplate;
